import { makeStyles } from '@material-ui/core/styles'
import { Colors, drawerWidth } from '../../../assets/js/styles'
import zero_notification from '../../../assets/img/zero_notif.svg'
import { Typography } from '@material-ui/core'

const emptyCardStyles = makeStyles(theme => ({
    root: {
        background: Colors.background,
        margin: 15,
        
        // width: '45%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '70%'
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%'
        // }
    }
}))

//get illustration from undraw
const EmptyNotificationCard = () => {

    const classes = emptyCardStyles()

    return (
        <div className={classes.root}>
            <img src={zero_notification} width="250px" height="250px" alt="no notification" />
            {/* <Typography> Create and share forms to get Notifications</Typography> */}
        </div>
    )
}

export default EmptyNotificationCard 