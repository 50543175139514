import React, { useEffect, useState, useCallback, useRef } from 'react'

import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getResponse, getResponseForTemplate } from '../../redux/actions/responseActions'
import { getTemplate, setTemplate } from '../../redux/actions/templateAction'

import Spinner from '../../components/Spinner'
import Layout, { Header } from '../../components/Layout'
import { genericSceneStyles, Colors } from '../../../assets/js/styles'
import SearchBar from '../../components/SearchBar'

import { ResponseCard } from '../../components/Card'
import { Box, Grid, Typography } from '@material-ui/core'


import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import Profile from '../../models/Profile'
import AuthComponent from '../../components/AuthComponent'

const ViewResponse = ({ match, profile, templates, template, getTemplate, getResponseForTemplate, setTemplate, location }) => {

    const commonClasses = genericSceneStyles()
    const responses = useSelector(state => state.response.responses)
    const templateResponse = useSelector(state => state.response.templateResponse)
    const loading = useSelector(state => state.response.loading)

    const [invalidId, setInvalidId] = useState(false)

    const [search, setSearch] = useState({
        searchTerm: "",
        applied: false
    })

    const [templateResponseCopy, setTemplateResponseCopy] = useState({})

    const mounted = useRef(false)

    const InitTemplates = useCallback(async (id) => {
        if(!template || !templates.find(item => item.id === id)){
            await getTemplate(id)
            mounted.current = true
        }else{
            await setTemplate(templates.find(item => item.id === id))
            mounted.current = true
        }
    }, [template, getTemplate, templates, setTemplate]) 


    const onChangeSearchTerm = useCallback((e) => {
        setSearch(prev => ({ 
            ...prev,
            [e.target.name] : e.target.value
        }))
    })

    const onClearSearch = useCallback((e) => {
        e.preventDefault()
        setTemplateResponseCopy(templateResponse)
        setSearch(prev => ({
            searchTerm: "",
            applied: false
        }))
    }, [templateResponse])


    const onClickSearchIcon = useCallback((e) => {
        e.preventDefault()
        const { searchTerm } = search

        if (searchTerm.trim() !== ''){
            setTemplateResponseCopy(templateResponse.filter((item, index) => { 
                if(item.name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true
                }
                return false
            }))
            setSearch(prev => ({
                ...prev,
                applied: true
            }))
        }

    }, [search, templateResponse])

    const getResponses = useCallback(async () => {
        const templateId = match.params.templateId
        if (mounted.current === false){
            if (templateId){
                if(!responses || (responses && responses.length === 0)){
                    if (templateResponse.filter(item => item.templateId === templateId).length === 0 ){ //need to get new response as well
                        await getResponseForTemplate(templateId)
                        await InitTemplates(templateId)
                    }else{
                        await InitTemplates(templateId)
                    }
                }else{
                    await getResponseForTemplate(templateId, responses)
                    await InitTemplates(templateId)
                }
            }else{
                setInvalidId(true)
            }
        }
    }, [ ])
 
    const [activeRes, setActiveRes] = useState(false)
    const [resId, setResId] = useState(false)

    const checkQuery = useCallback(() => {
        const id = location.search.split("=")[1]
        setResId(id)
        if (responses){
            const response = responses.find((res) => res.id === id)
            if (response){
                setActiveRes(true)
            }
        }
    }, [responses, location])

    useEffect(() => {
        
        getResponses()
        checkQuery()

        // InitTemplates(templateId)

        return () => {

        }

    }, [getResponses, checkQuery])


    if (invalidId){
        return <div> OOOps!! Something went wrong</div>  //Design Error component
    }

    return (
        <AuthComponent>
        <Layout>
            {loading || !template ? (
                    <Spinner/>
                ) : (
                    <div className={commonClasses.root}>
                        <Header title="Feeback" avatarIcon=""/>
                        <div className={commonClasses.outerSection}>
                            <div className={commonClasses.innerSection}>
                                <div className={commonClasses.header}>
                                    <Box sx={{ display: 'flex'}}>
                                        <Typography style={{ marginRight: '5px' }}>
                                            <Link to="/feedback" style={{ color: Colors.lightDarkText }}>
                                                feedback
                                            </Link>
                                        </Typography>
                                        <Typography style={{ marginRight: '5px' }}>{' / '}</Typography>
                                        <Typography>responses</Typography>
                                    </Box>
                                    {/* <SearchBar
                                        // width='50vw'
                                        name='searchTerm' 
                                        search={search}
                                        dataName={'responses'} 
                                        onChange={onChangeSearchTerm}
                                        onClearSearch={onClearSearch}
                                        onClickSearchIcon={onClickSearchIcon} /> */}
                                </div>
                                <div className={commonClasses.body}>
                                    <Grid container>
                                        {
                                            (templateResponse && templateResponse.map((item, index) => (
                                                <Grid key={'item__'+index} xs={12} sm={6} item>
                                                    <ResponseCard 
                                                        showFromNotificaition={item.id === resId} 
                                                        response={item} 
                                                        template={ template && template }/>
                                                </Grid>
                                            )))
                                        }
                                    </Grid>
                                </div>
                            </div>
                        </div> 
                    </div> 
                )
            }
        </Layout>
        </AuthComponent>
    )
}

ViewResponse.propTypes = {
    profile: PropTypes.instanceOf(Profile),
    getTemplate: PropTypes.func,
    getResponseForTemplate: PropTypes.func,
    setTemplate: PropTypes.func,
    templates: PropTypes.array,
    template: PropTypes.object,
}

const mapStateToProps = state => ({
    profile: state.profile.data,
    templates: state.template.templates,
    template: state.template.template,
})

export default connect(mapStateToProps, { getTemplate, getResponseForTemplate, setTemplate })(ViewResponse)
