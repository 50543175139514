import React, { useEffect, useState, useCallback } from 'react'
import { Redirect, Link } from 'react-router-dom';

//redux
import { connect } from 'react-redux';

// Material UI
import { Box, Typography, Grid, Button, TextField, Checkbox, Paper, useMediaQuery, Container } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'

//utils
import clsx from 'clsx'
import PropTypes from 'prop-types';

// Helper functions
import { validateEmail } from '../../../utils/helpers'
import { register } from '../../../redux/actions/authAction'

// assets
import Logo from '../../../../assets/img/landing/logo.svg'
import { login_styles, Colors, login_card_radius, authIllustration, sharedAuthButon } from '../../../../assets/js/styles'
import emailIcon from '../../../../assets/img/email.png'
import personIcon from '../../../../assets/img/person.png'
import lockIcon from '../../../../assets/img/lock.png'
import worldIcon from '../../../../assets/img/world.png'
import { makeStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider'
import MTextField from '../../../components/MTextField'
import MButton from '../../../components/MButton'


const useStyles = makeStyles((theme) => ({
    root: {
        // background: "rgba(241, 244, 250, 1)",
        // height: "100vh",
        // // width: 'auto',
        // // padding: '50 0',
        // // alignSelf: 'center',
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        // [theme.breakpoints.down("md")]:{
        //     height: "inherit"
        // },
        // [theme.breakpoints.down("xs")]:{
        //     height: "inherit"
        // }

        background: "rgba(241, 244, 250, 1)",
        height: "100vh",
        // width: '80vw',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]:{
            height: "inherit"
        },
        [theme.breakpoints.down("xs")]:{
            height: "inherit"
        }
    },
    typo:{
        color: Colors.warning
    },
    gridContainer: {
        width: 'inherit',
        border: `1px solid transparent`,
        borderRadius: `${login_card_radius}`,
    },
    gridItem: {
        // background: Colors.secondary,
        // height: "80vh",
        // [theme.breakpoints.down("md")]:{
        //     margin: 'auto',
        //     // height: '100vh'
        // },
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        background: Colors.secondary,
        height: "75vh",
        [theme.breakpoints.down("md")]:{
            margin: 'auto',
            height: '100vh'
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    form:{
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3),
            width: '65ch',
            [theme.breakpoints.down('xs')]: {
              width: 'calc(100% - 20px)'
            }
          }
    },
    btn:{
        width: 'calc(100% - 50px)',
        border: '1px solid transparent',
        borderRadius: '17px',
        background: Colors.primary,
        color: Colors.white,
        textTransform: 'none'
    },
    left: {
        paddingLeft: '20px',
        paddingRight: '20px',
        border: `1px solid transparent`,
        background: 'rgba(250, 250, 251, 1)',
        borderRadius: `${login_card_radius} 0px 0px ${login_card_radius}`,
    },
    right: {
        border: `1px solid transparent`,
        borderRadius: `0px ${login_card_radius} ${login_card_radius} 0px `
    },
    rightInnerContainer:{
        display: 'inline-block',
        padding: '0 20px'
    },
    leftImage: {
        height: "350px",
        width: "inherit",
        backgroundImage: `url(${authIllustration})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        [theme.breakpoints.down("sm")]:{
            height: "150px",
            width: "inherit"
        },
        [theme.breakpoints.down("md")]:{
            height: "250px",
            width: "inherit"
        }
    },
    logo_and_button_div:{
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        margin: '0px 20px'
    },
    loginButon: {
        ...sharedAuthButon
    },
    createAccounButon: {
        ...sharedAuthButon,
        width: '150px',
        [theme.breakpoints.down("xs")]:{
            width: "100px"
        }
    },
    authlogo: {
        // height: '50px', 
        // padding: '10px',
    },
    divMobile: {
        width: '90vw'
    }
}))


const Register = ({isAuthenticated, loading, register, user, alert}) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
    const isLg = useMediaQuery(theme => theme.breakpoints.down('lg'))
    const isMd = useMediaQuery(theme => theme.breakpoints.down('md'))

    const { base } = login_styles;
    const classes = useStyles()
    
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        companyname: '',
        fullname: '',
        showPassword: false
    });
    
      const [errors, setErrors] = useState(null)
    
      const [showPassword, setShowPassword] = useState(false)
    
      const { email, password, companyname, fullname } = formData;
    
      const onChange = e => {
        setFormData({ ...formData, 
            [e.target.name]: e.target.value 
        });
        
        setErrors({ ...errors,
          [e.target.name]: null
        })
      }
    
      const handleClickShowPassword = () => {
        setShowPassword(showPassword => !showPassword)
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
      const onSubmit = async e => {
        e.preventDefault();
    
        if (email === '' && password === ''){
            setErrors({
                password: 'password field is empty',
                email: 'email field is empty'
            })
            return
        }

        if (fullname === ''){
            setErrors({ ...errors, fullname: 'fullname field is empty'})
            return
        }

        if (email === ''){
            setErrors({...errors, email: 'email field is empty'})
            return
        }else if(validateEmail(email) === false){
            setErrors({...errors,
                email: 'Please include a valid email'
            })
            return
        }
    
        if(password === '' || !password){
            setErrors({...errors,
                password: 'please input valid password'
            })
            return
        }

        //do firebase Login
    
        const formDataObject = {
            email: email,
            password: password,
            companyname: companyname,
            fullname: fullname
        }
    
        await register(formDataObject);
    
    };

    const [rememberMe, setRememberMe] = useState(true)

    const toggleRememberMe = useCallback((e) => {
        setRememberMe(e.target.checked)
    }, [])

    if (user) {
        return <Redirect to={{
            pathname:'/'
        }}/>
    }

    return isSmall ? (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.logo_and_button_div}> 
                <img src={Logo} className={classes.authlogo}  alt="LogoImage"/>
            </div>
            <div style={{ margin: '10px', marginTop: '20px' }}>
                <Typography variant="h4"> <b> Create your Free Account</b></Typography>
            </div>
            <div style={{ margin: '10px'}}>
                <Typography variant="body2"> 
                    Already have an account? {" "}
                    <Link style={{ textDecoration: 'none' }} to={`/login`}>
                        <span style={{ color: Colors.primary}}> 
                            Sign In
                        </span>
                    </Link>
                </Typography>
            </div>
            <form className={classes.form} onSubmit={e => onSubmit(e)}>
                <div className={classes.divMobile}>

                    <MTextField 
                        name ="fullname"
                        value={fullname}
                        label="Full Name"
                        errors = {errors} 
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="start">
                                <img src={personIcon} alt="person Icon"/>
                            </InputAdornment>
                            ),
                        }}/>
                </div>
                <div className={classes.divMobile}>
                    <MTextField 
                        name="email" 
                        value={email} 
                        label="Email" 
                        errors = {errors} 
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="start">
                                {/* <PersonIcon /> */}
                                <img src={emailIcon} alt="person Icon"/>
                            </InputAdornment>
                            ),
                        }}/>
                </div>
                <div className={classes.divMobile}>
                    <MTextField
                        name ="companyname"
                        value={companyname}
                        label="Company Name"
                        errors = {errors} 
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="start">
                                {/* <PersonIcon /> */}
                                <img src={worldIcon} alt="person Icon"/>
                            </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <div className={classes.divMobile}>
                    <MTextField 
                        name="password" 
                        value={password} 
                        label="Password" 
                        errors = {errors} 
                        type={showPassword ? "text" : "password"}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <img src={lockIcon} alt="person Icon"/>
                                </InputAdornment>
                            ),
                        }}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: Colors.lightDarkText}}>
                    <Checkbox color="primary" checked={rememberMe} onChange={toggleRememberMe}/>
                    <Typography> Remember me</Typography>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                    <Button variant="outlined" className={classes.btn} type='submit' value='Create an account' disableElevation>
                        Create an account 
                    </Button>
                </div>
                <Divider/>
                <Divider/>
            </form>
        </div>
    ) : (
        <Box className={classes.root}>

            <Grid container className={classes.gridContainer}>
                <Grid className={clsx(classes.gridItem, classes.left)} item xs>

                    {/* Left */}
                    <div className= {classes.leftContainer}>
                        <div className={classes.logo_and_button_div}> 
                            <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
                            <Button className={classes.loginButon}> 
                                <Link to={`/login`} className={classes.createAccounButon} style={{ textDecoration: 'none' }}> Login</Link>    
                            </Button> 
                        </div>
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {/* <img src={Logo} style={{ background: Colors.primary }} width="300" height="300" alt="LogoImage"/> */}
                            <div className={classes.leftImage}/>
                        </div>
                        <div style={{ textAlign: 'center', marginBottom: `${isLg ? '30': '0'}px`, marginLeft: 15, marginRight: 15}}>
                            <Typography variant={"h6" }> USE LISTIN</Typography>
                            <Typography variant={"body2"}>Collect customer feedback in-store, through your website and find out how to improve your product, service and customer experience</Typography>
                        </div>
                    </div>

                </Grid>
                <Grid elevation={2} className={clsx(classes.gridItem, classes.right)} item xs>
                    <Paper style={{width: '100%', height:"inherit" }} className={clsx(classes.gridItem, classes.right)}>

                    {/* Right */}
                    <div className={classes.rightInnerContainer}>
                        <Typography variant="h5" style={{ }}> Create your Free Account </Typography>
                        <div style={{  height: '80px', width: '100%' }}>
                            
                        </div>
                    <form className={classes.form} onSubmit={e => onSubmit(e)}>
                    <div>
                        <TextField
                            error={(errors && errors.fullname) && true}
                            helperText={(errors && errors.fullname) && errors.fullname}
                            id="outlined-textarea-1"
                            label="Full Name"
                            placeholder=""
                            type="text"
                            name ="fullname"
                            value={fullname}
                            onChange={e => onChange(e)}
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <img src={personIcon} alt="person Icon"/>
                                </InputAdornment>
                                ),
                            }}
                            />
                        </div>
                        <div className={classes.div}>
                        <TextField
                            error={(errors && errors.email) && true}
                            helperText={(errors && errors.email) && errors.email}
                            id="outlined-textarea-2"
                            label="Email Address"
                            placeholder=""
                            type="text"
                            name ="email"
                            value={email}
                            onChange={e => onChange(e)}
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    {/* <PersonIcon /> */}
                                    <img src={emailIcon} alt="person Icon"/>
                                </InputAdornment>
                                ),
                            }}
                            />
                        </div>
                        
                        <div>
                        <TextField
                            error={(errors && errors.companyname) && true}
                            helperText={(errors && errors.companyname) && errors.companyname}
                            id="outlined-textarea-3"
                            label="Company Name"
                            placeholder=""
                            type="text"
                            name ="companyname"
                            value={companyname}
                            onChange={e => onChange(e)}
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                <InputAdornment position="start">
                                    <img src={worldIcon} alt="person Icon"/>
                                </InputAdornment>
                                ),
                            }}
                            />
                        </div>

                        <div className={classes.div}>
                        <TextField
                            error={(errors && errors.password) && true}
                            helperText={(errors && errors.password) && errors.password}
                            id="outlined-textarea-4"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            placeholder=""
                            name="password"
                            value={password}
                            onChange={e => onChange(e)}
                            variant="standard"
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                <img src={lockIcon} alt="person Icon"/>
                                </InputAdornment>
                            ),

                            // endAdornment: (
                            //     <InputAdornment position="start">
                            //     <IconButton
                            //         aria-label="toggle password visibility"
                            //         onClick={handleClickShowPassword}
                            //         onMouseDown={handleMouseDownPassword}>
                            //         { showPassword ? <Visibility/> : <VisibilityOffIcon />}
                            //     </IconButton>
                            //     </InputAdornment>
                            // ),
                            }}
                        />
                        </div>

                      
                        <div>
                            <Checkbox color="primary"/>
                            <Typography style={{color: 'gray'}} variant="caption"> I agree to the terms of services</Typography>
                        </div>
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px'}}>
                            <Button variant="outlined" className={classes.btn} type='submit' value='Login' disableElevation>
                                Create an account 
                            </Button>
                        </div>

                    </form>
                    </div>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    ) 
}

Register.propTypes = {
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object,
    loading: PropTypes.bool,
    alert: PropTypes.array,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    loading: state.auth.loading,
    alert: state.alert,
});
  
export default connect(mapStateToProps, { register })(Register);
