/* eslint-disable import/no-anonymous-default-export */
import {
    PROFILE_SAVE_SUCCESS,
    PROFILE_GET_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_FAILURE,
    PROFILE_LOADING,
    INITIAL_PROFILE_CREATED
} from '../types'

// import { encrypt } from '../../utils/cryptoWrapper'

const initialState = {
    data: JSON.parse(localStorage.getItem('profile')),
    loading: false,
    error: null
}

export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                data: payload,
                loading: false,
                error: null
            }
        case PROFILE_GET_SUCCESS: 
            return {
                ...state,
                data: payload,
                loading: false,
                error: null
            }
        case PROFILE_LOADING:
            return {
                ...state,
                loading: true
            }
        case PROFILE_DELETE_SUCCESS:
            return {
                ...state, 
                data: null,
                loading: false,
                error: null
            }
        case PROFILE_FAILURE:
            return{
                ...state,
                data: null,
                loading: false,
                error: payload
            }
        case INITIAL_PROFILE_CREATED:
            return{
                ...state,
                data: payload,
                loading: false,
            }
        default:
            return state;
    }
}