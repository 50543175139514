/* eslint-disable import/no-anonymous-default-export */
import {
    GET_RESPONSES,
    GET_RESPONSE,
    GET_TEMPLATE_RESPONSE,
    RESPONSE_ERROR,
    RESPONSE_LOADING,
    SET_TEMPLATE_RESPONSE
} from '../types'

const initialState = {
    responses: [],
    templateResponse: [],
    response: null,
    error: null,
    loading: false
}

export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case GET_RESPONSES:
            return {
                ...state,
                loading: false,
                responses: payload
            }
        case GET_TEMPLATE_RESPONSE:
            return{
                ...state,
                loading: false,
                templateResponse: payload
            }
        case GET_RESPONSE:
            return {
                ...state,
                loading: false,
                response: payload
            }
        case RESPONSE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case RESPONSE_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}