class Template{
    constructor(id,isActive, link, name, type, profileId, themeId, tag, isDraft, lastShare, questions, welcomeText, goodbyeText, dateCreated, dateUpdated) {
        this.id = id
        this.isActive = isActive ? isActive : false
        this.link = link;
        this.name = name;
        this.type = type
        this.profileId = profileId
        this.themeId = themeId
        this.tag = tag
        this.isDraft = isDraft
        this.lastShare = lastShare
        this.questions = questions
        this.welcomeText = welcomeText;
        this.goodbyeText = goodbyeText;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }

    toFirestore(update = false) {

        const returnObject = update ? {
            link : this.link,
            isActive: this.isActive,
            name : this.name,
            type : this.type,
            profileId : this.profileId,
            themeId : this.themeId,
            tag : this.tag,
            goodbyeText: this.goodbyeText,
            welcomeText: this.welcomeText,
            isDraft : this.isDraft,
            questions : this.questions,
            lastShare : this.lastShare,
            dateUpdated : this.dateUpdated,
        } : {
            link : this.link,
            name : this.name,
            isActive: this.isActive,
            type : this.type,
            profileId : this.profileId,
            themeId : this.themeId,
            goodbyeText: this.goodbyeText,
            welcomeText: this.welcomeText,
            tag : this.tag,
            isDraft : this.isDraft,
            questions: this.questions,
            lastShare : this.lastShare,
            dateCreated : this.dateCreated,
            dateUpdated : this.dateUpdated,
        };

        return returnObject
    }

    static fromFirestore(snapshot){
        const data = snapshot.data();
        return new Template(snapshot.id, data.isActive, data.link, data.name, data.type, data.profileId, data.themeId, 
                           data.tag, data.isDraft, data.lastShare, data.questions, data.welcomeText, data.goodbyeText,
                            data.dateCreated, data.dateUpdated);
    }
}

export default Template;