import constants  from "../constants"

const validateTemplateForm = (obj) => {
    let returnObj = {
        isValid: false,
    }

    const keys = Object.keys(obj)
    const values = Object.values(obj)

    const count = values.length === keys.length ? values.length : 0

    if (count === 0){
        return {
            isValid: false,
            error: "form is empty"
        }
    }
    
    for (var i = 0; i < count; i++){
        if (typeof(values[i]) === "object"){
            if (Object.keys(values[i]).length === 0){
                returnObj = {
                    isValid: false,
                    [keys[i]]: "Please add at least one question to proceed"
                }
                return returnObj //not validated
            }else{

                //validate other parts of question
                const questionKeys = Object.keys(values[i])

                let index = 0
                for (let question of Object.values(values[i])){
                   if (question.answerType === constants.answerTypes.multiChoice){
                        if (question.answers.length < 2){ //minimum of two answers for multiChoice questions, maybe remote config
                            returnObj = {
                                isValid: false,
                                [questionKeys[index]]: {
                                    answers: "Please add at least two answers"
                                }
                            }
                            return returnObj
                        }else{
                            for (let ans of question.answers){
                                if (ans.value === ""){
                                    returnObj = {
                                        isValid: false,
                                        [questionKeys[index]]: {
                                            [ans.name]: "Please include a valid answer"
                                        }
                                    }
                                    return returnObj
                                }
                            }
                        }
                    } else if (question.answerType !== constants.answerTypes.multiChoice){

                        //remove multi choice for non-multichoice answer type (e.g text, rank, ratingSmiley, ratingStar)
                        question.answers = []

                    } else if (question.answerType === ""){
                       returnObj = {
                            isValid: false,
                            [questionKeys[index]]: {
                                answerType: "Please specify a valid answer type"
                            }
                       }
                       return returnObj
                   }

                   if (question.text === ""){
                       returnObj = {
                            isValid: false,
                            [questionKeys[index]]: {
                                text: "Please add a valid question"
                            }
                       }
                       return returnObj
                   }
                   index++;
                }
            }
        }

        if (typeof(values[i]) === "string"){
            if (values[i] === ''){
                returnObj = {
                    isValid: false,
                    [keys[i]]: `${keys[i]} cannot be empty`
                }
                return returnObj //not validated
            }
        }
    }
    return {
        isValid: true,
        templateForm: obj
    }
}

export default validateTemplateForm