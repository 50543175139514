import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core/'

import { Colors } from '../../../../assets/js/styles'

const Active='Active'
const Closed='Closed'
const All='All'

const useStyles = makeStyles(theme => ({
    root:{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${Colors.background}`,
        borderRadius: '5px',
        // margin: '20px',
        padding: '10px',
        // boxShadow: `1px 1px ${Colors.background}`
    },
    typo: {
        color: 'gray', 
        cursor: 'pointer',
        marginRight: '20px',
        [theme.breakpoints.down('xs')]: {
            marginRight: '10px',
        }
    },
    selected: {
        color: Colors.black
    },
    endFilters: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const FilterFeedback = ({ type, status, setStatusFilter }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant='body2'> <b>{type}</b></Typography>

            <div className={classes.endFilters}>
                <div style={{ display: 'flex' }}>
                    <Typography className={classes.typo} variant='body2'> Status: {' '} </Typography>

                    <Typography 
                        variant='body2'
                        className={clsx(classes.typo, {
                            [classes.selected]:  status.trim().toLowerCase() === All.trim().toLowerCase()
                        })}
                        onClick={(e) => setStatusFilter(e)}>
                        All {''}
                    </Typography>

                    <Typography 
                        variant='body2'
                        className={clsx(classes.typo, {
                            [classes.selected]:  status.trim().toLowerCase() === Active.trim().toLowerCase()
                        })}
                        onClick={(e) => setStatusFilter(e)}>
                        Active {''}
                    </Typography>

                    <Typography  
                        variant='body2'
                        className={clsx(classes.typo, {
                            [classes.selected]:  status.trim().toLowerCase() === Closed.trim().toLowerCase()
                        })}
                        onClick={(e) => setStatusFilter(e)}>
                        Closed {''}
                    </Typography>

                </div>
                {/* <div>
                    types
                </div> */}
            </div>
        </div>
    )
}

FilterFeedback.propTypes = {
    type : PropTypes.string,
    status: PropTypes.string,
    setStatusFilter: PropTypes.func

}
export default FilterFeedback
