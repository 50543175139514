import React, { useCallback, useState, memo, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Popper, Fade, Container, Tooltip } from '@material-ui/core'
import { Colors } from '../../../../assets/js/styles'

import { useSelector } from 'react-redux'

import MButton from '../../../components/MButton'

//Icon
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Typography } from '@material-ui/core'
import useClickOutside from '../../../hooks/useClickOutside'

import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles(theme => ({
    root: {
        border: '1px double lightgray',
        height: '110px',
        width: 'inherit',
        padding: '15px',
        // margin: '10px',
        borderRadius: '12px',
        display: 'grid'
    }, 
    base:{
        margin: '5px'
    },
    rootHover: {
        background: 'darkgray',
        border: '1px double lightgray',
        height: '110px',
        padding: '15px',
        // margin: '10px',
        display: 'grid',
        borderRadius: '12px',
    },
    answer: {
        // margin: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        height: '20px',
        width: '70%',
        marginBottom: '10px',
    }, 
    answerchild: {
        border: '1px solid black',
        borderRadius: '12px',
        height: '20px',
        marginRight: '5px',
        width: '70%',
    },
    question: {
        borderRadius: '5px',
        width: '80%',
        height: '10px',
        background: 'black',
        // marginTop: '0px',
        marginBottom: '10px',
    },
    btn: {
        // margin: '10px',
        borderRadius: '12px',
        height: '20px',
        width: '70px',
        background: Colors.primary
    },
    buttonEdit:{
        background: 'red',
        position: 'absolute',
        top: '30px',
        right: '30px',
        // 
    }
}));

const ThemeCard = ({ theme, editTheme, deleteTheme, showActive, setActiveTheme }) => {
    const classes = useStyles()

    const themes = useSelector(state => state.theme.themes)
    // const dispatch = useDispatch()

    const [showOptions, setShowOptions] = useState(false)
    const [miniMenu, setMiniMenu] = useState(false)

    const triggerMiniMenuDisplay = useCallback((event) => {
        // console.log(event.currentTarget)
        setAnchorEl(event.currentTarget);
        setMiniMenu(prev => !prev)
    }, [])

    const triggerHoverOptions = useCallback(() => {
        setShowOptions(prev => !prev)
    }, [])

    const { colors } = theme 

    // const [showDeleteOption, setShowDeleteOption] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const triggerDeleteTheme = useCallback((e) => {
        deleteTheme(theme.id, themes, theme)
    },[theme, deleteTheme, themes])
    
    const popover = useRef()

    const closePicker = useCallback(() => setMiniMenu(false), []);
  
    useClickOutside(popover, closePicker);

    return  (
        <div className={classes.base} >
            <div 
                onMouseEnter={triggerHoverOptions} 
                onMouseLeave={triggerHoverOptions} 
                className={ showOptions ? classes.rootHover : classes.root} 
                style={{ background: !showOptions && colors.Background,
                          border: showActive && 'thick double lightgreen' }} >
                    { showOptions && 
                        <div style={{ 
                                gridArea: '1 / 1',  
                                display: 'inline-block',
                                width: '100%',
                            }}>
                                <div
                                    // onClick={triggerMiniMenuDisplay}
                                    style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                       { (theme && theme.owner !== 'default') && 
                                        <Tooltip title={"Delete theme"}>
                                                <IconButton 
                                                    style={{ width: '20px', height: '20px', backgroundColor: 'white'}} 
                                                    onClick={(e) => triggerDeleteTheme(e)}>
                                                        <HighlightOffIcon style={{ color: 'red'}} /> 
                                                </IconButton>
                                            </Tooltip>
                                        }
                                </div>
                                {/* <Popper className={classes.popper} open={miniMenu} ref={popover} anchorEl={anchorEl} placement="bottom" transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Container>
                                                <Typography> Delete </Typography>
                                            </Container>
                                        </Fade>
                                    )}
                                </Popper> */}
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                    { showActive ? (
                                         (theme.owner !== 'default') &&
                                                <MButton  width={'70px'} height={'20px'} text={"Edit"} onClick={() => editTheme(theme)}/> 
                                            ):(
                                                <MButton  width={'70px'} height={'20px'} text={"Apply"} onClick={() => setActiveTheme(theme)}/>            
                                            )
                                    }         
                                </div>       
                        </div> 
                    }
                    <div style={{ gridArea: '1 / 1', }}>
                        <div className={classes.question} style={{ background: colors.Questions}}>  </div>
                        <div className={classes.answer} >
                            <div  className={classes.answerchild} style={{ border: `1px solid ${colors.Answers}` }} >  </div>
                            <div className={classes.answerchild} style={{ border: `1px solid ${colors.Answers}` }}>  </div>
                        </div>
                        <div className={classes.btn} style={{ background: colors.Buttons}}>  </div>
                    </div>
            </div>
            { theme && ( theme.name && <Typography variant="caption"> {theme.name}</Typography>) }
        </div>
    )
}

export default ThemeCard
