import React, { useEffect, useState, useCallback } from 'react'
import { Link, Redirect } from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles'

//redux
import { connect } from 'react-redux';

// Material UI
import { Box, Typography, Grid, Button, TextField, Checkbox, Paper, Divider } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useMediaQuery } from '@material-ui/core'

//utils
import clsx from 'clsx'
import PropTypes from 'prop-types';

// Helper functions
import { validateEmail } from '../../../utils/helpers'
import { login, loginWithGoogle, forgotPassword } from '../../../redux/actions/authAction'

// assets
import Logo from '../../../../assets/img/landing/logo.svg'
import { login_styles, Colors, login_card_radius, authIllustration, sharedAuthButon } from '../../../../assets/js/styles'
import emailIcon from '../../../../assets/img/email.png'
import lockIcon from '../../../../assets/img/lock.png' 

import MDialog from '../../../components/MDialog'
import MTextField from '../../../components/MTextField'
import MButton from '../../../components/MButton'
import Toast from '../../../components/Toast'

import useSwitchState from '../../../hooks/useSwitchState'
// import Logo from '../../../../assets/img/landing/logo.svg'


const useStyles = makeStyles((theme) => ({
    root: {
        background: "rgba(241, 244, 250, 1)",
        height: "100vh",
        // width: '80vw',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("md")]:{
            height: "inherit"
        },
        [theme.breakpoints.down("xs")]:{
            height: "inherit"
        }
    },
    typo:{
        color: Colors.warning
    },
    gridContainer: {
        width: 'inherit',
        border: `1px solid transparent`,
        borderRadius: `${login_card_radius}`,
    },
    gridItem: {
        background: Colors.secondary,
        height: "75vh",
        [theme.breakpoints.down("md")]:{
            margin: 'auto',
            height: '100vh'
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    form:{
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            marginBottom: theme.spacing(3),
            width: '65ch',
            [theme.breakpoints.down('xs')]: {
              width: 'calc(100% - 20px)'
            }
          }
    },
    btn:{
        width: 'calc(100% - 50px)',
        border: '1px solid transparent',
        borderRadius: '17px',
        background: Colors.primary,
        color: Colors.white,
        textTransform: 'none'
    },
    mDialog: {
        width: '500px',
        padding: 20,
        [theme.breakpoints.down('xs')]: {
            width: 'inherit'
          }
    },
    left: {
        paddingLeft: '20px',
        paddingRight: '20px',
        border: `1px solid transparent`,
        background: 'rgba(250, 250, 251, 1)',
        borderRadius: `${login_card_radius} 0px 0px ${login_card_radius}`,
    },
    right: {
        border: `1px solid transparent`,
        borderRadius: `0px ${login_card_radius} ${login_card_radius} 0px `
    },
    rightInnerContainer:{
        display: 'inline-block',
        padding: '0 20px'
    },
    leftImage: {
        height: "350px",
        width: "inherit",
        backgroundImage: `url(${authIllustration})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        [theme.breakpoints.down("sm")]:{
            height: "150px",
            width: "inherit"
        },
        [theme.breakpoints.down("md")]:{
            height: "250px",
            width: "inherit"
        }
        
    },
    logo_and_button_div:{
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        margin: '0px 20px'
    },
    loginButon: {
        ...sharedAuthButon
    },
    createAccounButon: {
        ...sharedAuthButon,
        width: '150px',
        [theme.breakpoints.down("xs")]:{
            width: "100px"
        }
    },
    authlogo: {
        // height: '50px', 
        // padding: '10px',
    },
    typoCursor: {
        cursor: "pointer",
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    divMobile: {
        width: '90vw'
    }
}))

const Icon = (props) => (
    <div {...props}>
        <svg height="100%" viewBox="0 0 20 20" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z" fill="#4285F4"></path><path d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z" fill="#34A853"></path><path d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z" fill="#FBBC05"></path><path d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z" fill="#EA4335"></path></svg>
    </div>
)


const Login = ({isAuthenticated, loading, login, error, loginWithGoogle, forgotPassword, alert}) => {

    // const { base } = login_styles;
    const classes = useStyles()

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
    const isLg = useMediaQuery(theme => theme.breakpoints.down('lg'))

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        showPassword: false
    });
    
      const [errors, setErrors] = useState(null)
    
      const [showPassword, setShowPassword] = useState(false)
    
      const { email, password } = formData;
    
      const onChange = e => {
        setFormData({ ...formData, 
            [e.target.name]: e.target.value 
        });
        
        setErrors({ ...errors,
          [e.target.name]: null
        });
      }
    
      const onSubmit = async e => {
        e.preventDefault();
    
        if (email === '' && password === ''){
            setErrors({
                password: 'password field is empty',
                email: 'email field is empty'
            })
            return
        }

        if (email === ''){
            setErrors({...errors, email: 'email field is empty'})
            return
        }else if(validateEmail(email) === false){
            setErrors({...errors,
                email: 'Please include a valid email'
            })
            return
        }
    
        if(password === '' || !password){
            setErrors({...errors,
                password: 'please input valid password'
            })
            return
        }

        //do firebase Login
    
        const formDataObject = {
            email: email,
            password: password,
        }
    
        await login(formDataObject, rememberMe);
          //history.push('/dashboard')
    };

    const [rememberMe, setRememberMe] = useState(true)

    const toggleRememberMe = useCallback((e) => {
        setRememberMe(e.target.checked)
    }, [])

    const LoginWithGoogle = useCallback(async () => {
        
        await loginWithGoogle()

    }, [loginWithGoogle])

    // const LoginWithFacebook = () => {
        
    // }

    const [pwdPopUp, { on: showPwdPopUp, off: hidePwdPopUp }] = useSwitchState(false)
    const [emailPwdReset, setEmailPwdReset] =  useState('')
    const [emailPwdResetError, setEmailPwdResetError] =  useState(null)

    const onEmailPwdChange = useCallback((e) => {
        setEmailPwdReset(e.target.value)
        setEmailPwdResetError(null)
    }, [])

    const sendPwdEmail = useCallback(async () => {

        if (validateEmail(emailPwdReset) === false){
            setEmailPwdResetError({
                email_pwd_reset :"Please enter a valid email address"
            })
            return
        }

        if ( emailPwdReset === ''){
            setEmailPwdResetError({
                email_pwd_reset :"Please enter a valid email address"
            })
            return
        }

        await forgotPassword(emailPwdReset)
    }, [emailPwdReset, forgotPassword])

    if (isAuthenticated) {
        return <Redirect to={{
            pathname:'/'
        }}/>
    }

    return isSmall ? (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
            <div className={classes.logo_and_button_div}> 
                <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
            </div>
            <div style={{ margin: '10px', marginTop: '20px' }}>
                <Typography variant="h4"> <b>Log into your account</b></Typography>
            </div>
            <div style={{ margin: '10px'}}>
                <Typography variant="body2"> 
                    Dont have an account? {" "}
                    <Link style={{ textDecoration: 'none' }} to={`/register`}>
                        <span style={{ color: Colors.primary}}> 
                            Sign Up
                        </span>
                    </Link>
                </Typography>
            </div>
            <form className={classes.form} onSubmit={e => onSubmit(e)}>
                <div className={classes.divMobile}>
                    <MTextField 
                        name="email" 
                        value={email} 
                        label="Email" 
                        errors = {errors} 
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="start">
                                {/* <PersonIcon /> */}
                                <img src={emailIcon} alt="person Icon"/>
                            </InputAdornment>
                            ),
                        }}/>
                </div>
                <div className={classes.divMobile}>
                    <MTextField 
                        name="password" 
                        value={password} 
                        label="Password" 
                        errors = {errors} 
                        type={showPassword ? "text" : "password"}
                        onChange={onChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <img src={lockIcon} alt="person Icon"/>
                                </InputAdornment>
                            ),
                        }}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', color: Colors.lightDarkText}}>
                    <Checkbox color="primary" checked={rememberMe} onChange={toggleRememberMe}/>
                    <Typography> Remember me</Typography>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                    <Button variant="outlined" className={classes.btn} type='submit' value='Login' disableElevation>
                        Login 
                    </Button>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', color: Colors.primary, marginBottom: '25px'}}>
                    <Typography 
                        className={classes.typoCursor}
                        onClick={showPwdPopUp}
                        style={{color: 'lightblue'}} 
                        variant="body1"> 
                            <b>Forgot password?</b>
                    </Typography>
                </div>

                {   
                    ( 
                        (error && error.code) 
                    ) 
                    && 
                    <Toast open={((error && error.code) ? true : false)} type='failure' message={"Invalid Credentials"}/> 
                }

                <MDialog 
                    bgColor={Colors.background}
                    // className={classes.mDialog} 
                    open={pwdPopUp} 
                    handleClose={hidePwdPopUp}
                    content={
                        <div className={classes.mDialog}>
                            <MTextField 
                                errors={emailPwdResetError}
                                type="email"
                                style={{ width: '100%'}}
                                label="Email"
                                onChange={onEmailPwdChange}
                                name="email_pwd_reset" 
                                placeholder={"Please enter registered email address"}
                                value={emailPwdReset}/>
                            <div style={{ width: 'inherit', height : '50px'}}/>
                            <MButton text="Send email" onClick={sendPwdEmail} />
                        </div>
                    } 
                />
                {   ( 
                        (alert && alert.length > 0) 
                    ) 
                    && 
                    <Toast open={(alert && alert.length > 0)} type='success' message={alert[0].msg}/> 
                }

                <Divider/>
                <Divider/>
            </form>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                <Button
                startIcon={  
                    <Icon style={{ display: 'flex',  width: '20px', height: '20px'}}/>
                }
                style={{ background: 'rgb(252, 90, 90)'}} variant="outlined" className={classes.btn} onClick={LoginWithGoogle} disableElevation>
                    Continue with Google 
                </Button>
            </div>
        </div>
    ) : (
        <Box className={classes.root}>
            <Grid container className={classes.gridContainer}>

                <Grid className={clsx(classes.gridItem, classes.left)} item xs>
                    {/* Left */}
                    <div className= {classes.leftContainer}>
                        <div className={classes.logo_and_button_div}> 
                            <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
                            <Button className={classes.createAccounButon}> 
                                <Link to={`/register`} className={classes.createAccounButon} style={{ textDecoration: 'none' }}> Create Account </Link>
                            </Button> 
                        </div>
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {/* <img src={Logo} style={{ background: Colors.primary }} width="300" height="300" alt="LogoImage"/> */}
                            <div className={classes.leftImage}/>
                        </div>
                        <div style={{ textAlign: 'center', marginBottom: `${isLg ? '30': '0'}px`, marginLeft: 15, marginRight: 15}}>
                            <Typography variant={ "h6" }> USE LISTIN</Typography>
                            <Typography variant={ "body2" }>Collect customer feedback in-store, through your website and find out how to improve your product, service and customer experience</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid elevation={2} className={clsx(classes.gridItem, classes.right)} item xs>
                    <Paper style={{width: '100%', height:"inherit" }} className={clsx(classes.gridItem, classes.right)}>
                    {/* Right */}
                    <div className={classes.rightInnerContainer}>
                        <Typography variant="h5" style={{ }}> Log In to Your Account </Typography>
                        <div style={{  height: '80px', width: '100%' }}>
                        </div>
                        <form className={classes.form} onSubmit={e => onSubmit(e)}>
                            <div className={classes.div}>
                            <TextField
                              autoComplete="off"
                                error={(errors && errors.email) && true}
                                helperText={(errors && errors.email) && errors.email}
                                id="outlined-textarea-2"
                                label="Email"
                                placeholder=""
                                type="text"
                                name ="email"
                                value={email}
                                onChange={onChange}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                    <InputAdornment position="start">
                                        {/* <PersonIcon /> */}
                                        <img src={emailIcon} alt="person Icon"/>
                                    </InputAdornment>
                                    ),
                                }}
                                />
                            </div>
                            
                            <div className={classes.div}>
                            <TextField
                              autoComplete="off"
                                error={(errors && errors.password) && true}
                                helperText={(errors && errors.password) && errors.password}
                                id="outlined-textarea-4"
                                label="Password"
                                type={showPassword ? "text" : "password"}
                                placeholder=""
                                name="password"
                                value={password}
                                onChange={onChange}
                                variant="standard"
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <img src={lockIcon} alt="person Icon"/>
                                    </InputAdornment>
                                ),

                                // endAdornment: (
                                //     <InputAdornment position="start">
                                //     <IconButton
                                //         aria-label="toggle password visibility"
                                //         onClick={handleClickShowPassword}
                                //         onMouseDown={handleMouseDownPassword}>
                                //         { showPassword ? <Visibility/> : <VisibilityOffIcon />}
                                //     </IconButton>
                                //     </InputAdornment>
                                // ),
                                }}
                            />
                            </div>
                        
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{ display: 'flex', alignItems: 'center', color: Colors.lightDarkText}}>
                                    <Checkbox color="primary" checked={rememberMe} onChange={toggleRememberMe}/>
                                    <Typography> Remember me</Typography>
                                </div>
                                <Typography 
                                    className={classes.typoCursor}
                                    onClick={showPwdPopUp}
                                    style={{color: 'lightblue'}} 
                                    variant="body1"> 
                                        Forgot password
                                </Typography>

                                <MDialog 
                                    bgColor={Colors.background}
                                    // className={classes.mDialog} 
                                    open={pwdPopUp} 
                                    handleClose={hidePwdPopUp}
                                    content={
                                        <div className={classes.mDialog}>
                                            <MTextField 
                                                errors={emailPwdResetError}
                                                type="email"
                                                style={{ width: '100%'}}
                                                label="Email"
                                                onChange={(e) => onEmailPwdChange(e)}
                                                name="email_pwd_reset" 
                                                placeholder={"Please enter registered email address"}
                                                value={emailPwdReset}/>
                                            <div style={{ width: 'inherit', height : '50px'}}/>
                                            <MButton text="Send email" onClick={sendPwdEmail} />
                                        </div>
                                    } 
                                />
                               
                             
                            </div>
                            <div style={{width: '100%', display: 'block', marginTop: '15px'}}>
                                <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                                    <Button variant="outlined" className={classes.btn} type='submit' value='Login' disableElevation>
                                        Login 
                                    </Button>
                                </div>
                                <Divider/>
                                <Divider/>
                            </div>
                        </form>
                        {   
                            ( 
                                (error && error.code) 
                            ) 
                            && 
                            <Toast open={(error && error.code)} type='failure' message={"Invalid Credentials"}/> 
                        }
                        
                        <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                            <Button
                            startIcon={  
                                <Icon style={{ display: 'flex',  width: '20px', height: '20px'}}/>
                            }
                            style={{ background: 'rgb(252, 90, 90)'}} variant="outlined" className={classes.btn} onClick={LoginWithGoogle} disableElevation>
                                Continue with Google 
                            </Button>
                        </div>
                        {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                            <Button style={{ background: 'rgb(0, 98, 255)'}} variant="outlined" className={classes.btn} onClick={e => LoginWithFacebook(e)} disableElevation>
                                Continue with Facebook
                            </Button>
                        </div> */}
                    </div>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    ) 
}

Login.propTypes = {
    forgotPassword: PropTypes.func.isRequired,
    loginWithGoogle:  PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    user: PropTypes.object,
    loading: PropTypes.bool,
    alert: PropTypes.array,
    error: PropTypes.object,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    loading: state.auth.loading,
    alert: state.alert,
    error: state.auth.error
});
  
export default connect(mapStateToProps, { login, loginWithGoogle, forgotPassword })(Login);
