import { FirebaseServiceFactory } from '../../utils/FirebaseService'
import {
    REQUEST_DASHBOARD_DATA,
    RECEIVE_DASHBOARD_DATA,
    DASHBOARD_LOADING,
    DASHBOARD_ERROR
} from '../types'

import { toggleLoading } from '../reduxUtils'


export const getDashBoardData = () => async dispatch => {
    try{
        
        toggleLoading(true, DASHBOARD_LOADING)
        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){

            //Implemented Get DashBoardData

            toggleLoading(true, DASHBOARD_LOADING)
        }

    }catch(e){
        toggleLoading(false, DASHBOARD_LOADING)
    }
}