import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { useLocation } from 'react-router-dom'

import axios from 'axios'

//MUI Components
import { Typography, useMediaQuery, ButtonGroup, TextField, Button, Box, Avatar, Grid } from '@material-ui/core'

//utils
import { 
    invertColor,
    decrypt, isBW, 
    uuidv4, getKeyByValue, 
    shadeColor, 
    sortQuestions, addSpace, 
    validateEmail } from '../../utils/helpers';
import constants, { gravatarKeys, DEFAULT_BRAND_VALUE } from '../../constants'
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

// Components
import { SmileyContainer, MultiChoiceContainer, smileyIcons } from '../../components/ResponderComponents'
import Spinner from '../../components/Spinner';
import MButton from '../../components/MButton'

// assets
import Logo from '../../../assets/img/landing/logo.svg'
import { responderStyles } from '../../../assets/js/styles'

// icons
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Response from '../../models/Response'

import gravatar from 'gravatar'
import { isDesktop, isBrowser, isMobile,browserName, osName, osVersion } from 'react-device-detect'
import Toast from '../../components/Toast'

import { saveNotification } from '../../redux/actions/notificationAction'


const Rating = lazy(() => import('@material-ui/lab/Rating'));
const noTransfrom = { // for material ui buttons
    textTransform: 'none'
}

const ResponderForm = ({ match }) => {

    const dispatch = useDispatch()

    const location = useLocation()
    const { answerTypes } = constants

    const classes = responderStyles()

    const isXSDown = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const user = useSelector(state => state.auth.user)
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

    const [template, setTemplate] = useState(null)
    const [theme, setTheme] = useState(null)

    const [responderForm, setResponderForm] = useState(new Response())

    const [company, setCompany] = useState('')
    const [profileId, setProfileId] = useState('')

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState({ message: null, show: false})

    const [anonUser, setAnonUser] = useState(null)

    const [credentials, setCredentials] = useState({
        name:'',
        email:''
    })

    const [showFinish, setShowFinish] = useState(false)
    const [, setSaveSuccess] = useState(false)
    const [startTime, setStartTime] = useState(null)

    const [infoAlert, setInfoAlert] = useState({
        message: '',
        show: false
    })

    const [options, setOptions] = useState(null)

    const [currentQuestion, setCurrentQuestion ] = useState({
        index: 0,
        key: template ? Object.keys(template?.questions)[0] : null,
        value: template ? Object.values(template?.questions)[0] : null,
    })  

     //store browser and version ' store os and version

    const [started, setStarted] = useState((template && template.welcomeText.show === true) ? false : true)


    const [mAnswers, setManswers] = useState({
        //add more answers as keys
    })

    // const getOption = useCallback((key) => {
    //     if (theme && theme.options){
    //         const opts = theme.options
    //         const map = {
    //             AskEmail : opts[0],
    //             AskName: opts[1],
    //             IndicateMandatoryQ: opts[2],
    //             ShowQuestion: opts[3],
    //             Background: opts[4],
    //             ListInBrand: opts[5]
    //         }
    //         return map[key]
    //     }
    //     return null;
    // }, [theme])
    

    const getTemplateForResponder =  useCallback(async () => {
        try{
            let instance = FirebaseServiceFactory.getInstance()

            if (!user && !isAuthenticated){
                let anonymousUser = await instance.loginAnonymous()
                setAnonUser(anonymousUser)

                // console.log(anonymousUser)
            }

            const { pathname } = location 
            const hashFromPath = pathname.split('/').slice(3) 
            // returns hash if its the last element else returns array of hash properties,

            if (hashFromPath.length === 0){
                //return "error"
                setError({ message: 'Invalid Url', show: true })
            }

            let verifiedHash = ''

            if (hashFromPath.length === 1){
                verifiedHash = hashFromPath[0] 

            }else if (hashFromPath.length > 1){

                let index = 0
                for (let val of hashFromPath){
                    let dataToAppend = val
                    if (index !== hashFromPath.length - 1){
                        dataToAppend = val += '/'
                    }
                    verifiedHash += dataToAppend
                    index++
                }
            } 

            const { params } = match

            const mCompany = params.company
            setCompany(addSpace(mCompany))

            const hash = params.templateHash

            const decryptedToken = decrypt(hash === verifiedHash ? hash : verifiedHash)

            // const mUid = decryptedToken.split(',')[0]
            // setUid(mUid)

            const templateName = decryptedToken.split(',')[1]
            const profileId = decryptedToken.split(',')[2]

            setProfileId(profileId)
            
            let mTemplates = await instance.getMany(fsCollection.TEMPLATE, { profileId, name: templateName })
            // console.log(mTemplates[0])

            const mTemplate = mTemplates[0]
            setTemplate(mTemplate)

            if (mTemplate && mTemplate.isActive === true){

                // console.log("UNSORTED:___", mTemplate.questions)
                
                const firstQuestion = sortQuestions(Object.values(mTemplate?.questions))[0]

                // console.log( "SORTED:___", sortQuestions(Object.values(mTemplate?.questions)))

                setCurrentQuestion({
                    ...currentQuestion,
                    index: firstQuestion.index,
                    key: getKeyByValue(mTemplate.questions, firstQuestion),
                    value: firstQuestion,
                })

                const mTheme = await instance.getOneById(fsCollection.THEME, mTemplates[0].themeId)

                // const [ AskEmail, AskName, IndicateMandatoryQ, ShowQuestion, Background, ListInBrand ] = mTheme.options;
                // console.log(AskEmail)
                // console.log(ShowQuestion)
                // console.log(ListInBrand)
                
                // console.log(mTemplate)
                setTheme(mTheme)

                setStarted(mTemplate.welcomeText.show === true ? false : true)

                setOptions({
                    AskEmail : mTheme.options.find(item => item.name === "AskEmail") ?? null,
                    AskName: mTheme.options.find(item => item.name === "AskName") ?? null,
                    IndicateMandatoryQ:  mTheme.options.find(item => item.name === "IndicateMandatoryQ") ?? null,
                    ShowQuestion:  mTheme.options.find(item => item.name === "ShowQuestion") ?? null,
                    Background:  mTheme.options.find(item => item.name === "Background") ?? null,
                    ListInBrand:  mTheme.options.find(item => item.name === "ListInBrand") ?? null,
                });

                setLoading(false)
            } else {

                //theme is inactive
                //Show Error Page 

                setError({ message: 'This is not an active campaign', show: true })
                setLoading(false)
            }
        
        }catch(e){
            throw e
        }
    }, [match, isAuthenticated, user, currentQuestion, location, setLoading, setTheme, setCurrentQuestion])

    // console.log(options)
    const [bitmoji, setBitmoji] = useState('')
    
    const saveResponse = useCallback(async(finished) => {

        try {

            const apiRoute = process.env.REACT_APP_CLOUD_FUNCTION_ROUTE
            const apiVersion = "/api/v1" 

            let newResponse
            const instance = FirebaseServiceFactory.getInstance()
            const timestamp = instance.serverTimeStamp

            const endTime = Date.now()

            var elapsedTime = endTime - startTime; // milliseconds elapsed since start
        
            const timeSpent = Math.floor(elapsedTime / 1000) //seconds

            const data = await axios.get(process.env.REACT_APP_FREE_IP_API)

            let locationObject

            if (data){
                const { data : ipData } = data
                locationObject = {
                    country: data ? ipData.ip.country : '',
                    city: data ? ipData.ip.city : '',
                    latitude: data ? ipData.ip.latitude : 0,
                    longitude: data ? ipData.ip.longitude : 0
                }
            }else{
                const altData = await axios.get(process.env.REACT_APP_FREE_IP_ALT_API)
                locationObject = {
                    country: altData ? altData.country_name : '',
                    city: altData ? altData.city : '',
                    latitude: altData ? altData.latitude : 0,
                    longitude: altData ? altData.longitude : 0
                }
            }

            let mBitmoji = ''
            if (bitmoji === ''){
                mBitmoji = gravatar.url(uuidv4(), {s: '100', d: gravatarKeys[Math.floor(Math.random() * gravatarKeys.length)]})
                setBitmoji(mBitmoji)
            }
            // console.log(isDesktop, isBrowser, isMobile, browserName, browserVersion, "___", osName, osVersion)
            
            const devicetype = (isDesktop && isBrowser) ? 'Desktop' : isMobile ? 'Mobile' : ''
            const ostype = [osName, osVersion].join(' ')
            const browserType = [browserName, osVersion].join(' ')

            const { name, email } = credentials
            
            let response = new Response("", bitmoji !== '' ? bitmoji : mBitmoji, name, email, locationObject, devicetype, ostype, browserType, timeSpent, 
                    startTime, endTime, finished, profileId,
                    company, template.id, mAnswers, timestamp, timestamp)
            
            //perform sentiment here to
            let wordsForSentiment = []

            // const wordsForSentiment = [...Object.values(responderForm.answers)]
            for(let answer of Object.values(responderForm.answers)){
                if (answer.reply !== ''){
                    wordsForSentiment.push({
                        key: getKeyByValue(responderForm.answers, answer),
                        word: answer.reply
                    })
                }
            }

            if (wordsForSentiment.length > 0){

                const config = {
                    headers: {
                        'Authorization': 'Bearer ' + anonUser.user.accessToken
                    }
                }

                const apiResponse = await axios.post(`${apiRoute}${apiVersion}/sentiment`, { words: wordsForSentiment }, config)

                let sentiments = apiResponse.data.sentiment
    
                let newAnswersWithSentiments = { }

                for(let sentiment of sentiments){
                    let answerKey = sentiment.answer.key
                    if (Object.keys(responderForm.answers).includes(answerKey)){
                        
                        newAnswersWithSentiments[answerKey] = {
                            ...mAnswers[answerKey],
                            sentiment: {
                                average: sentiment.average,
                                vote: sentiment.vote,
                                score: sentiment.score,
                                numWords: sentiment.numWords,
                                numHits: sentiment.numHits,
                            }
                        }
                    }
                }

                const getNewAnswers = () => {
                    for (let answerkey of Object.keys(newAnswersWithSentiments)){
                        if (Object.keys(response.answers).includes(answerkey)){
                            response.answers[answerkey] = newAnswersWithSentiments[answerkey]
                        }
                    }
                }
                
                getNewAnswers()
            }

            newResponse = await instance.saveOne(fsCollection.RESPONSE, response.toFirestore())

            await dispatch(saveNotification(newResponse, template, profileId))

            setSaveSuccess((newResponse !== undefined && newResponse !== null))
        }catch(e){
        }
    }, [company, responderForm, startTime, template, profileId, credentials, bitmoji, dispatch, anonUser, mAnswers ])
    
    const [btnHidden, setBtnHidden] = useState(false)

    const handleNext = useCallback(async () => {
        const { questions } = template

        const count = Object.values(questions).length

        const lastIndex = sortQuestions(Object.values(questions))[count - 1].index
        
        if (options && options.IndicateMandatoryQ?.value === true){

            const existingAnswer  = Object.values(mAnswers).filter(item => item.questionKey === currentQuestion.key)[0]

            if (existingAnswer){

                if (existingAnswer.reply.trim() === ''){
                    setInfoAlert({ message: "Please provide a valid response, Empty Text is not allowed", show: true})
                    setTimeout(() => {
                        setInfoAlert({ message: "", show: false})
                    }, 5000)
                    return
                }

                if (currentQuestion.index < lastIndex){
                    setShowFinish(false)
        
                    //check permissions, if question is mandatory or all survey is mandatory
                    const nextIndex = currentQuestion.index + 1
        
                    const nextValue = sortQuestions(Object.values(questions))[nextIndex]

                    setCurrentQuestion({
                        index: nextIndex,
                        key: getKeyByValue(questions, nextValue),
                        value: nextValue,
                    })
                }else if (currentQuestion.index === lastIndex){
                    
                    //check if finsihed 
                    //chcek if everything is empty
                    //check based on answered questions... mandatory or non mandatory surveys
                    if ( (options.AskEmail && options.AskEmail?.value === true) || (options.AskName  && options.AskName?.value === true)){

                        //check permissions, if question is mandatory or all survey is mandatory
                        const nextIndex = currentQuestion.index + 1

                        setCurrentQuestion({
                            index: nextIndex,
                            key: "AskForInfo",
                            value: null,
                        })

                        setBtnHidden(true)

                    }else{
                        saveResponse(true) //pass finished state
                        setShowFinish(true)
                    }

                }else{

                    if((options.AskName && options.AskName?.value === true)  &&
                        (options.AskEmail && options.AskEmail?.value === true) &&
                            credentials.name?.trim() === '' &&
                             credentials.email?.trim() === '' ) {
                        setInfoAlert({ message: "Please Input a valid credentials", show: true})
                        setTimeout(() => {
                            setInfoAlert({ message: "", show: false})
                        }, 5000)
                        return false;
                    }
    
                    if((options.AskName && options.AskName?.value === true) &&
                        credentials.name.trim() === '' ){
                        setInfoAlert({ message: "Please Input a valid name", show: true})
                        setTimeout(() => {
                            setInfoAlert({ message: "", show: false})
                        }, 5000)
                        return
                    }
    
                    if((options.AskEmail && options.AskEmail?.value === true) &&
                        (validateEmail(credentials.email.trim())  === false || credentials.email.trim() === '') ){
                        setInfoAlert({ message: "Please Input a valid email", show: true})
                        setTimeout(() => {
                            setInfoAlert({ message: "", show: false})
                        }, 5000)
                        return
                    }
                    saveResponse(true)
                    setShowFinish(true)

                }

            }else{
                //no answer... Cannot proceed further
                setInfoAlert({ message: "Questions are mandatory, Please provide an answer", show: true })

                setTimeout(() => {
                    setInfoAlert({ message: "", show: false})
                }, 5000)
            }
        }else{

            if (currentQuestion.index < lastIndex){
                setShowFinish(false)
    
                //check permissions, if question is mandatory or all survey is mandatory
                const nextIndex = currentQuestion.index + 1
    
                const nextValue = sortQuestions(Object.values(questions))[nextIndex]
                setCurrentQuestion({
                    index: nextIndex,
                    key: getKeyByValue(questions, nextValue),
                    value: nextValue,
                })
            }else if (currentQuestion.index === lastIndex){
                
                //check if finsihed 
                //chcek if everything is empty
                //check based on answered questions... mandatory or non mandatory surveys
                if ( (options.AskEmail && options.AskEmail?.value === true) || (options.AskName  && options.AskName.value === true)){

                    //check permissions, if question is mandatory or all survey is mandatory
                    const nextIndex = currentQuestion.index + 1

                    setCurrentQuestion({
                        index: nextIndex,
                        key: "AskForInfo",
                        value: null,
                    })

                    setBtnHidden(true)
                }else{
                    saveResponse(true) //pass finished state
                    setShowFinish(true)
                }
            }else{
                if((options.AskName && options.AskName?.value === true) &&
                    (options.AskEmail && options.AskEmail?.value === true) &&
                      credentials.name.trim() === '' &&
                       credentials.email.trim() === '' ) {
                    setInfoAlert({ message: "Please Input a valid credentials", show: true})
                    setTimeout(() => {
                        setInfoAlert({ message: "", show: false})
                    }, 5000)
                    return false;
                }

                if((options.AskName && options.AskName?.value === true) &&
                    credentials.name.trim() === '' ){
                    setInfoAlert({ message: "Please Input a valid name", show: true})
                    setTimeout(() => {
                        setInfoAlert({ message: "", show: false})
                    }, 5000)
                    return
                }

                if((options.AskEmail && options.AskEmail?.value === true) &&
                    (validateEmail(credentials.email.trim())  === false ||  credentials.email.trim() === '')){
                    setInfoAlert({ message: "Please Input a valid email", show: true})
                    setTimeout(() => {
                        setInfoAlert({ message: "", show: false})
                    }, 5000)
                    return
                }

                saveResponse(true)
                setShowFinish(true)
            }
        }
        
    }, [currentQuestion, template, saveResponse, options, mAnswers, credentials])

    const handlePrev = useCallback(()=> {

        const { questions } = template

        const firstIndex = sortQuestions(Object.values(questions))[0].index;

        if (currentQuestion.index > firstIndex){

            const prevIndex = currentQuestion.index - 1
            const prevValue = sortQuestions(Object.values(questions))[prevIndex];

            setCurrentQuestion({
                index: prevIndex,
                key: getKeyByValue(questions, prevValue),
                value: prevValue,
            })
        }
    }, [currentQuestion, template])

    useEffect(() => {
        
        const getTemp = async () =>{
            try{
                await getTemplateForResponder()
            }catch(e){
                setError({ message: e, show: true})
            }
        }

        if (!startTime || startTime === null){
            setStartTime(Date.now())
        }

        if (template === null || !template){
            getTemp()  
        }
        
    }, [])


    const handleClickAnswer = useCallback((e) => {

        const existing = Object.values(mAnswers).filter(item => item.questionKey === currentQuestion.key)[0]

        const replyText = e.target.innerText.trim() ?? e.target.textContent.trim()

        if(existing){
            mAnswers[getKeyByValue(mAnswers, existing)] = {
                ...mAnswers[getKeyByValue(mAnswers, existing)],
                reply : replyText,
            }
        }else{
            mAnswers[uuidv4()] = {
                questionKey : currentQuestion.key,
                questionText : currentQuestion.value.text,
                reply : replyText,
            }
        }
        
        setManswers({
            ...mAnswers
        })

        setResponderForm({
            ...responderForm,
            answers: mAnswers
        })

        handleNext()

    }, [responderForm, mAnswers, currentQuestion, handleNext])

    const  handleText = useCallback((e) => {
        //Use enter to handle next for text input
        //Show user type enter when finished answering, or use the next button below.
        const existing = Object.values(mAnswers).filter(item => item.questionKey === currentQuestion.key)[0]

        if(existing){
            mAnswers[getKeyByValue(mAnswers, existing)] = {
                ...mAnswers[getKeyByValue(mAnswers, existing)],
                reply : e.target.value,
            }
        }else{
            mAnswers[uuidv4()] = {
                questionKey : currentQuestion.key,
                questionText : currentQuestion.value.text,
                reply : e.target.value,
            }
        }
        
        setManswers({
            ...mAnswers
        })

        setResponderForm({
            ...responderForm,
            answers: mAnswers
        })

        //TODO:- Press shift 
        if ((e.code === "Enter" || e.code === "NumpadEnter") && e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            handleNext()
        }

    }, [currentQuestion, mAnswers, responderForm, handleNext]);

    const  handleRating = useCallback((e) => {
        const existing = Object.values(mAnswers).filter(item => item.questionKey === currentQuestion.key)[0]

        if(existing){
            mAnswers[getKeyByValue(mAnswers, existing)] = {
                ...mAnswers[getKeyByValue(mAnswers, existing)],
                reply : e.target.value,
            }
        }else{
            mAnswers[uuidv4()] = {
                questionKey : currentQuestion.key,
                questionText : currentQuestion.value.text,
                reply : e.target.value,
            }
        }
        
        setManswers({
            ...mAnswers
        })

        setResponderForm({
            ...responderForm,
            answers: mAnswers
        })
        handleNext()
    }, [currentQuestion, mAnswers, responderForm, handleNext])

    const onSelectAnswer = useCallback((e) => {

        const type = currentQuestion.value.answerType

        const handlers = {
            rank: handleClickAnswer,
            multiChoice: handleClickAnswer,
            text: handleText,
            ratingStar: handleRating,
            ratingSmiley: handleRating,
        }

        const actionToPerform = handlers[type]

        if (actionToPerform) {
            actionToPerform(e)
        }

    }, [handleClickAnswer, handleRating, handleText, currentQuestion])

    const onSetCredentials = useCallback((e) => {
        setCredentials(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }, [])

    const AskForDetailsComponent = useCallback(() => {
        const askforEmail = options.AskEmail
        const askForName = options.AskName

        if(bitmoji === ''){
            const mBitmoji = gravatar.url(uuidv4(), {s: '100', size: '150', d: gravatarKeys[Math.floor(Math.random() * gravatarKeys.length)]})
            setBitmoji(mBitmoji)
        }
        
        const returnVal = (
            <Box className={classes.detailsDiv} style={{ background: theme.colors.Background }}>
                {/* <Typography style={{ color: theme.colors.Questions }} variant={ isXSDown ? 'h5' : 'h4'}>
                   Hi 👋🏽, For this feedback we've chosen this image randomly to be your avatar
                </Typography>
                 */}
                <Avatar className={classes.avatar}
                        alt="Remy Sharp"
                        src={bitmoji}
                        style={{ width: 150, height: 150 }}/>

                <Typography style={{ color: theme.colors.Questions }} variant={ isXSDown ? 'body1' : 'body2'}>
                    Hi 👋🏽, We'd love to know who's giving us insighful feedbacks. Stay in touch with with <b>{company}</b> for more..
                </Typography>
                { (askForName && askForName.value === true) && <TextField
                    InputProps={{
                        className : classes.email_name_field,
                        style:{
                            color: theme.colors.Answers,
                        },
                        disableUnderline: true
                    }}
                    label={<Typography variant="body1" style={{ color: theme.colors.Answers}}> Name </Typography>}
                    fullWidth
                    name="name"
                    onChange={(e) => onSetCredentials(e)}
                    value={ credentials.name ?? '' }
                    placeholder="Full Name"
                    multiline />
                }

                { (askforEmail && askforEmail.value === true) && <TextField
                    InputProps={{
                        className : classes.email_name_field,
                        style:{
                            color: theme.colors.Answers,
                        },
                        disableUnderline: true
                    }}
                    fullWidth
                    type="email"
                    label={<Typography variant="body1" style={{ color: theme.colors.Answers }}> Email </Typography>}
                    name="email"
                    onChange={(e) => onSetCredentials(e)}
                    value={ credentials.email ?? ''}
                    placeholder="email address"
                    multiline />
                }

                {/* { ((askforEmail && askforEmail.value) || (askForName && askForName.value)) 
                    && <button>
                         Save 
                        </button> } */}
            </Box>
        )
        return returnVal
    }, [credentials, onSetCredentials, options, theme, bitmoji, classes, company, isXSDown])

    const getAnswerDisplay = useCallback(() => {
        let returnVal = null;

        const { answerType, answers } = currentQuestion.value;

        const { multiChoice, text, rank, ratingSmiley, ratingStar} = answerTypes;

        const answerForQuestion = Object.values(mAnswers).filter(item => item.questionKey === currentQuestion.key)[0]
  
        const inv = (hex) => '#' + hex.match(/[a-f0-9]{2}/ig).map(e => (255 - parseInt(e, 16) | 0).toString(16).replace(/^([a-f0-9])$/, '0$1')).join('')

        const highLighRank = shadeColor(inv(theme.colors.Answers), 50)

        switch (answerType) {
            case multiChoice: 
                returnVal = (
                    <div className={classes.answerRoot}>
                        <Grid container spacing={1} style={{ width:'inherit' }}>
                            {answers.map((item, index ) => (
                                <Grid key={ "multiChoice"+index} item xs={6} md={4}>
                                    <MultiChoiceContainer 
                                        isSelected={(answerForQuestion && answerForQuestion.reply) && item.value === answerForQuestion.reply}
                                        key={'index_'+ index} 
                                        value={item.value} 
                                        onClick={onSelectAnswer} 
                                        color={theme.colors.Answers} /> 
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                )
                break;
            case text: 
                
                returnVal = (
                    <div className={classes.answerRoot}>
                         <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                InputProps={{
                                    className : classes.input,
                                    style: {
                                        color: theme.colors.Answers
                                    },
                                    disableUnderline: true
                                }}
                                fullWidth
                                onKeyDown={(e) => onSelectAnswer(e)}
                                onChange={(e) => onSelectAnswer(e)}
                                value={ (answerForQuestion && answerForQuestion.reply)  ? answerForQuestion.reply : '' }
                                // className={classes.input}
                                id="standard-textarea"
                                // label="Multiline Placeholder"
                                placeholder="Type answer here"
                                multiline
                            />
                        </div>
                    </div>
                )
                break;
            case rank: 
                returnVal = (
                    <div className={classes.answerRoot}> 
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <ButtonGroup size="small" orientation={ isXSDown ? "vertical" : "horizontal" } style={{ backgroundColor: theme.colors.Answers }} aria-label="outlined primary button group">
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "1") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>1</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "2") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>2</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "3") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>3</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "4") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>4</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "5") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>5</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "6") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>6</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "7") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>7</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "8") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>8</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "9") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>9</Button>
                                <Button style={{ background: (answerForQuestion && answerForQuestion.reply === "10") ? highLighRank : 'inherit' }} onClick={(e) => onSelectAnswer(e)}>10</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                )
                break;
            case ratingSmiley: 
                returnVal = (
                    <div className={classes.answerRoot}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Suspense fallback={<Spinner/>}>
                                <Rating
                                    style={{
                                        color: theme.colors.Answers
                                    }}
                                    classes={{ 
                                        iconEmpty: classes.whitegray
                                    }}
                                    onChange={(e) => handleRating(e)}
                                    value={(answerForQuestion && answerForQuestion.reply)  ? parseInt(answerForQuestion.reply, 10) : 2 }
                                    name="customized-icons"
                                    defaultValue={2}
                                    getLabelText={(value) => smileyIcons[value].label}
                                    IconContainerComponent={SmileyContainer}
                                />
                            </Suspense>
                        </div>
                    </div>)
                break;
            case ratingStar: 
                returnVal = ( 
                    <div className={classes.answerRoot}> 
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Suspense fallback={<Spinner/>}>
                                <Rating
                                    size="large"
                                    style={{
                                        color: theme.colors.Answers
                                    }}
                                    classes={{ 
                                        iconEmpty: classes.whitegray
                                    }}
                                    onChange={(e) => handleRating(e)}
                                    name="customized-empty"
                                    value={(answerForQuestion && answerForQuestion.reply)  ? parseInt(answerForQuestion.reply, 10) : 2 }
                                    // defaultValue={2}
                                    // precision={0.5}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                />
                            </Suspense>
                        </div>
                    </div>)
                break;
            default: break;
        }

        return returnVal;

    }, [answerTypes, currentQuestion, classes, isXSDown, theme, handleRating,mAnswers, onSelectAnswer])

    const getStyles = useCallback(() => {
        const returnVal = options && options?.Background?.url 
        ? {
            backgroundImage: `url(${options.Background.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        } : {
            backgroundColor: theme ? theme.colors.Background : 'gray'
        }
        return returnVal
    }, [options, theme])


    //start Questions slideShow after Intro text
    const beginQuestions = useCallback(() => {
        setStarted(true)
    }, [])

    if (error && error.show){
        return (
            <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h6'>
                    {error.message}
                </Typography>
            </div>
        )
    }

    return (!loading  && template) ?
        (
            <div className={classes.root} style={getStyles()}>
                <div style={{ marginRight: '30px', paddingTop: '30px'}} className={classes.closeIconDiv} > {/* style={{ backgroundColor: theme.colors.Background  }} */}
                    <Typography variant='h6'>{company}</Typography>
                </div>
                <div className={classes.questionArea} >  {/* style={{ backgroundColor: theme.colors.Background  }} */}

                    {(started === false)  
                    ?   (
                            <div>
                                <Typography style={{ color: theme.colors.Questions }} variant={ isXSDown ? 'h4' : 'h3'}>
                                     {template.welcomeText.text}
                                </Typography>
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px', marginTop: '50px'}}>
                                    <MButton 
                                        text="Start" 
                                        type="button" 
                                        onClick={e => beginQuestions(e)} 
                                        name= "Start" 
                                        width="100px"
                                        bg={theme.colors.Buttons}
                                        marginX="10px" />
                                </div>
                            </div>
                        ) : (
                            <>
                                { (infoAlert && infoAlert.show) && <Toast open={infoAlert.show} type="info" message={infoAlert.message} /> }


                                {(showFinish) ? 
                                    (
                                        <div>
                                            {(template?.goodbyeText?.show) && (<Typography style={{ color: theme.colors.Questions }} variant={ isXSDown ? 'h4' : 'h3'}>
                                                {template.goodbyeText.text}
                                            </Typography>)}
                                        </div>
                                    ) : (
                                        currentQuestion.key === "AskForInfo" 
                                        ? (
                                            AskForDetailsComponent()
                                         ) : (
                                            <div>
                                                <Typography style={{ color: theme ? theme.colors.Questions : 'white' }} variant={ isXSDown ? 'h4' : 'h3'}>
                                                    {
                                                        (options && options.ShowQuestion?.value === true)
                                                        ? (
                                                            <>
                                                                {currentQuestion.value.index + 1} {' - '} {currentQuestion.value.text} {(options && options.IndicateMandatoryQ?.value === true) && '*' }
                                                            </>
                                                        ):(
                                                            <>
                                                                {currentQuestion.value.text} {(options.IndicateMandatoryQ?.value === true) && '*' }
                                                            </>
                                                        )
                                                    }
                                                
                                                </Typography>
                                                {getAnswerDisplay(currentQuestion.answerType) }
                                            </div>
                                        )
                                    )
                                }
                            </>
                        )
                    }
                </div>
               
                <div className={classes.bottom} >   {/* style={{ backgroundColor: theme.colors.Background  }} */}
                    { (options && options.ListInBrand?.value === DEFAULT_BRAND_VALUE) &&
                        <div style={{ height: 'inherit'}}>
                            <img src={Logo} className={classes.logo} alt="LogoImage"/>
                        </div>
                    }
                    { started === true && <ButtonGroup style={{ display: showFinish ? 'none': 'inherit' }} className={classes.btnGroup} color="primary" aria-label="outlined primary button group">
                        <Button className={classes.btn} 
                                variant="contained" 
                                disabled={currentQuestion.index === 0}
                                style={{ ...noTransfrom, 
                                    display: btnHidden ? 'none' : 'inherit',
                                    backgroundColor: theme.colors.Buttons,
                                    color: invertColor(theme.colors.Buttons, isBW(theme.colors.Buttons)) 
                                }}  
                                onClick={() => handlePrev()}>
                                    Prev
                        </Button>
                        <Button className={classes.btn} 
                                variant="contained" 
                                // disabled={currentQuestion.index === Object.keys(template.questions).length - 1}
                                style={{ ...noTransfrom, 
                                    // display: btnHidden ? 'none' : 'inherit',
                                    backgroundColor: theme.colors.Buttons, 
                                    color: invertColor(theme.colors.Buttons, isBW(theme.colors.Buttons)) 
                                }} 
                                onClick={() => handleNext()}>
                                    {currentQuestion.index >= Object.keys(template.questions).length-1 ? "Finish" : "Next"}
                        </Button>
                    </ButtonGroup>}
                </div>
            </div>
        ):(
            <div className={classes.spinnerDiv}>
                <Spinner/>
            </div>
        )
}

ResponderForm.propTypes ={
    match: PropTypes.object
}

export default ResponderForm;