import { initializeApp, getApp } from "firebase/app";
import { getAuth, 
      onAuthStateChanged, 
      createUserWithEmailAndPassword, 
      signInWithEmailAndPassword, 
      signInAnonymously, signOut, 
      GoogleAuthProvider, 
      signInWithPopup, 
      sendPasswordResetEmail,
      confirmPasswordReset } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage"
import { onSnapshot } from 'firebase/firestore'

import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";


const firebaseConfig = {
  apiKey:  process.env.REACT_APP_FIRE_APIKEY, 
  authDomain: process.env.REACT_APP_FIRE_AUTHDOMAIN, 
  projectId: process.env.REACT_APP_FIRE_PROJECTID, 
  storageBucket: process.env.REACT_APP_FIRE_STORAGEBUCKET, 
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGINSENDERID, 
  appId: process.env.REACT_APP_FIRE_APPID,
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENTID 
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

//******* RemoteConfig **/
export const remoteConfig = getRemoteConfig(app)
export const getMyRemoteConfig = (key) => getValue(remoteConfig, key)
export const onFetchAndActivateConfig = async() => {
  
  let returnVal = null
  try{

    await fetchAndActivate(remoteConfig)
    console.log("activated")

  }catch(e){
    console.log(e)
  }
  return returnVal
}
//*******End RemoteConfig */

export const auth = getAuth(app)

export const firestore = getFirestore(app)

export const storage = (path) =>  ref(getStorage(), path)
export const saveToStorage = async(ref, file) => await uploadBytes(ref, file)
export const deleteFromStorage = async(ref) => await deleteObject(ref)

export const downloadUrl = async (ref) => await getDownloadURL(ref)

export const fireRegisterWithEmailAndPwd = async (email, password) => await createUserWithEmailAndPassword(auth, email, password)

export const fireLoginWithEmailAndPwd = async (email, password) => await signInWithEmailAndPassword(auth, email, password)


export const fireResetPassword = async(email, authCodeSetting=null) => await sendPasswordResetEmail(auth, email, authCodeSetting)
export const fireConfirmResetPassword = async(oobCode, newPwd) => await confirmPasswordReset(auth, oobCode, newPwd)


export const fireLoginAnonymous = async () => await signInAnonymously(auth)

export const fireLoginGoogle = async () => await signInWithPopup(auth, new GoogleAuthProvider())

export const fireLoginOut = async () => await signOut(auth)

// detect Auth statusText
export const onAuthChanged = () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user !== null){
        resolve(user);
      }else{
        reject(null)
      }
    });
  });
}

export const createSnapShotListener = (query) => {
  return new Promise((resolve, reject) => {

    const unsubscribe = onSnapshot(query, (snap) => {
      let snapData = []

      snap.forEach((doc) => {
        snapData.push(doc)
      })
      
      // for (let change of snap.docChanges()){
      //   // snapData.push(change.doc)
      //   // if (change.type === "added"){
      //     // console.table(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Change;", change)
      //     // console.log(">>>>>>>> :ID:", change.doc)
      //     // console.table("New Notification: ", change.doc.data());
      //   // }
      // }
      
      resolve([unsubscribe, snapData])
    }, (error) => {
      reject(error)
    })
  })
}

// onAuthStateChanged(auth, (user) => {
//   if (user !== null){
//     console.log(user)
//     console.log(auth)
//   }else{
//     console.log(user + "user is nulll")
//   }
// })

export default app