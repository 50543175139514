import { lazy } from 'react'
import PropTypes from 'prop-types'

// Smiley Rating Icons
const SentimentDissatisfiedIcon = lazy(() => import('@material-ui/icons/SentimentDissatisfied'));
const SentimentSatisfiedIcon = lazy(() => import('@material-ui/icons/SentimentSatisfied'));
const SentimentSatisfiedAltIcon = lazy(() => import('@material-ui/icons/SentimentSatisfiedAltOutlined'));
const SentimentVerySatisfiedIcon = lazy(() => import('@material-ui/icons/SentimentVerySatisfied'));
const SentimentVeryDissatisfiedIcon = lazy(() => import('@material-ui/icons/SentimentVeryDissatisfied'))

const smileyIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon />,
      label: 'Very Satisfied',
    },
};

const SmileyContainer = ({ value, ...other }) => {
    return <span {...other}>{smileyIcons[value].icon}</span>;
}

SmileyContainer.propTypes = {
    value: PropTypes.number.isRequired,
};

export {
    smileyIcons
}

export default SmileyContainer