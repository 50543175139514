/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_TEMPLATE,
    GET_TEMPLATE,
    GET_TEMPLATES,
    DELETE_TEMPLATE,
    EDIT_TEMPLATE,
    TEMPLATE_LOADING,
    TEMPLATE_ERROR,
    SET_EDIT_TEMPLATE
} from '../types'

// import { encrypt } from '../../utils/cryptoWrapper'

const initialState = {
    templates: [],
    // responses: [],
    loading: false,
    template: {},
    error: null
}


export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case ADD_TEMPLATE:
            return {
                ...state,
                loading: false,
                // templates: ,
                template: payload.template,
                templates: payload.templates,
                error: null
            }
        case GET_TEMPLATE: 
            return {
                ...state,
                loading: false,
                template: payload,
                error: null
            }
        case GET_TEMPLATES:
            return {
                ...state,
                loading: false,
                templates: payload,
                error: null
            }
        case DELETE_TEMPLATE:
            return{
                ...state,
                loading: false,
                // template: state.template.id === payload ? null : state.template,
                template: payload.template,
                templates: payload.templates,
                error: null
                
            }
        case SET_EDIT_TEMPLATE: 
            return {
                ...state,
                template: payload,
                error: null
            }
        case EDIT_TEMPLATE:
            return{
                ...state,
                loading: false,
                template: payload.template,
                templates: payload.templates,
                error: null

            }
        case TEMPLATE_LOADING:
                return{
                    ...state,
                    loading: true,
                    error: null
                }
        case TEMPLATE_ERROR:
            return{
                ...state,
                loading: false,
                error: payload
            }
        default:
            return state;
    }
}