import {
    GET_RESPONSES,
    GET_RESPONSE,
    RESPONSE_ERROR,
    RESPONSE_LOADING,
    GET_TEMPLATE_RESPONSE,
    SET_TEMPLATE_RESPONSE
} from '../types'

import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

import { setAlert } from '../actions/alertActions'
import Response from '../../models/Response'

export const getResponses = (profileId) => async dispatch => {

    try{
        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: RESPONSE_LOADING
            })
    
            let newPrams = {
                profileId: profileId
            }
    
            const responses = await instance.getMany(fsCollection.RESPONSE, newPrams, { })
    
            dispatch({
                type: GET_RESPONSES,
                payload: responses
            })
        }else{
            throw new Error("Invalid User")
        }
       
    }catch(e){
        dispatch({
            type: RESPONSE_ERROR,
            payload: e
        })
    }
}

export const getResponse = (id, responses=[]) => async dispatch => {
    try{
        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: RESPONSE_LOADING
            })
    
            if (responses.length === 0 || responses.filter((item) => item.id === id).length === 0){
        
                const response = await instance.getOneById(fsCollection.RESPONSE, id)
    
                dispatch({
                    type: GET_RESPONSE,
                    payload: response
                })
    
            }else{
                dispatch({
                    type: GET_RESPONSE,
                    payload: responses.filter((item) => item.id === id)[0]
                })
            }
        }else{
            throw new Error("Invalid User")
        }

    }catch(e){
        dispatch({
            type: RESPONSE_ERROR,
            payload: e
        })
    }
}

// export const setResponseForTemplate = (templateResponses) => dispatch => {
//     try{
//         dispatch({
//             type: RESPONSE_LOADING
//         })

//         dispatch({
//             type: SET_TEMPLATE_RESPONSE,
//             payload: templateResponses
//         })

//     }catch(e){
//         console.log(e)
//         dispatch({
//             type: RESPONSE_ERROR,
//             payload: e
//         })
//     }
// }

export const getResponseForTemplate = (templateId, responses=[]) => async dispatch => {

    try{

        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: RESPONSE_LOADING
            })

            if (responses.length === 0 || responses.filter((item) => item.templateId === templateId).length === 0){
        
                const responses = await instance.getMany(fsCollection.RESPONSE, { templateId: templateId })

                dispatch({
                    type: GET_TEMPLATE_RESPONSE,
                    payload: responses
                })
            }else{
                dispatch({
                    type: GET_TEMPLATE_RESPONSE,
                    payload: responses.filter((item) => item.templateId === templateId)
                })
            }
        }else{
            throw new Error("Invalid User")
        }
       
    }catch(e){
        dispatch({
            type: RESPONSE_ERROR,
            payload: e
        })
    }
}