
//Auth Constants
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOG_OUT = 'LOG_OUT';
export const CLEAR_USER = 'CLEAR_USER'; 
export const AUTH_LOADING = 'AUTH_LOADING'; 
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR'
export const USER_GUEST = "USER_GUEST"

//Dashboard
export const REQUEST_DASHBOARD_DATA = 'REQUEST_DASHBOARD_DATA';
export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';

//Global Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";


export const PASSWORD_RESET_EMAIL_SENT = "PASSWORD_RESET_EMAIL_SENT"
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR"
export const PASSWORD_RESET_EMAIL_CONFIRM = "PASSWORD_RESET_EMAIL_CONFIRM"


export const PROFILE_SAVE_SUCCESS = "PROFILE_SAVE_SUCCESS";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_DELETE_SUCCESS = "PROFILE_DELETE_SUCCESS"
export const PROFILE_FAILURE = "PROFILE_FAILURE"
export const INITIAL_PROFILE_CREATED = "INITIAL_PROFILE_CREATED"
export const PROFILE_LOADING = "PROFILE_LOADING"

export const INDEX_CHANGED = "INDEX_CHANGED"
export const SUB_MENU_OPEN = "SUB_MENU_OPEN"
export const SUBMENU_INDEX_CHANGED = "SUBMENU_INDEX_CHANGED"
export const GET_INITIAL_MENU = "GET_INITIAL_MENU"

export const ADD_TEMPLATE = "ADD_TEMPLATE"
export const TEMPLATE_ERROR = "TEMPLATE_ERROR"
export const GET_TEMPLATE = "GET_TEMPLATE"
// export const GET_TEMPLATE_ERROR = "GET_TEMPLATE_ERROR"
export const GET_TEMPLATES = "GET_TEMPLATES"
// export const GET_TEMPLATES_ERROR = "GET_TEMPLATES_ERROR"
export const DELETE_TEMPLATE = "DELETE_TEMPLATE"
export const EDIT_TEMPLATE = "EDIT_TEMPLATE"
export const TEMPLATE_LOADING = "TEMPLATE_LOADING"
export const SET_EDIT_TEMPLATE = "SET_EDIT_TEMPLATE"

export const GET_RESPONSE = "GET_RESPONSE"
export const GET_RESPONSES = "GET_RESPONSES"
export const RESPONSE_ERROR = "RESPONSE_ERROR"
export const RESPONSE_LOADING = 'RESPONSE_LOADING'
export const GET_TEMPLATE_RESPONSE = 'GET_TEMPLATE_RESPONSE'
export const SET_TEMPLATE_RESPONSE = 'SET_TEMPLATE_RESPONSE'

export const ADD_THEME = "ADD_THEME"
export const THEME_ERROR = "THEME_ERROR"
export const GET_THEME = "GET_THEME"
export const GET_THEMES = "GET_THEMES"
export const EDIT_THEME = "EDIT_THEME"
export const THEME_LOADING = "THEME_LOADING"
export const SET_ACTIVE_THEME = "SET_ACTIVE_THEME"

export const DELETE_THEME = "DELETE_THEME"

//In-App notificationIcon
export const UPDATE_NOTIFICATION_SEEN = "UPDATE_NOTIFICATION_SEEN"
export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION"
export const GET_NOTIFICATION = "GET_NOTIFICATION"

export const UPDATE_NOTIFICATION_SEEN_ERROR = "UPDATE_NOTIFICATION_SEEN_ERROR"
export const SAVE_NOTIFICATION_ERROR = "SAVE_NOTIFICATION_ERROR"
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR"
export const NOTIFICATION_LOADING = "NOTIFICATION_LOADING"