import { Typography, Box,Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useCallback, useRef, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import FileCopyIcon from '@material-ui/icons/FileCopy';

import QRCode from 'qrcode.react';

import PropTypes from 'prop-types'

import { Colors } from '../../../../assets/js/styles'

import constants from '../../../constants'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Template from '../../../models/Template'

import { buildShareLink } from '../../../utils/helpers'
import Toast from '../../../components/Toast';

import { toggleTemplateLink } from '../../../redux/actions/templateAction'
import Spinner from '../../../components/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        background: Colors.background, 
        // padding: '10px',
        // width: '100vw',
        // margin: 
        [theme.breakpoints.down('xs')]: {
            width: 'inherit'
        }
    },
    box: {
        marginLeft: '2px',
        marginRight: '2px',
        marginTop: '40px',
        marginBottom: '40px',
        width: '100px',
        height: '100px',
        border: '1px solid lightgray',
        borderRadius: '4px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', 
        '&:hover': {
            background: 'lightgray',
            cursor: 'pointer'
        }
    },
    showBox: {
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '40px',
        marginBottom: '40px',
    }, 
    img: {
        width: '70px',
        height: '70px',
        paddingBottom: '5px'
    },
    shareOptsDiv: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    link:{
        display: 'flex',
        background: 'gray',
        color:'#ffffff',
        justifyContent: 'space-between',
        alignItems: 'center',
        // margin: '10px 15px',
        padding: '8px 8px',
        borderRadius: '10px',
        // width: '100%', 
        // minWidth: '95%',
    }, 
    closeIcon: {
        background: '#ffffff',
        borderRadius: '5px',
        margin: '5px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    copyIcon: { 
        marginLeft : '20px', 
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))

const ShareContent = ({ title, template, handleShare, showQR, setShowQR, showLink, setShowLink, source }) => {

    const classes = useStyles()

    const profile = useSelector(state => state.profile.data)

    const [showCopied, setShowCopied] = useState(false)
    const [showError, setShowError] = useState(false)

    const [shareOptions, setShareOptions] = useState(null)

    const mounted = useRef(false)

    useEffect(() => {
        if(mounted.current === false){
            if (title){
                let options = []
                if (source === "templateCard"){
                    options = [...constants.shareConstants]
                }else{
                    for (let item of constants.shareConstants){
                        if (item.text.startsWith("Create")){
                            item.text = 'View url'
                        }
                        options.push(item)
                    }
                }
                setShareOptions(options)
            }
            mounted.current = true
        }
    }, [title, source])

    const generateShareLink = useCallback(() => {

        // console.log("Before:-", link)

        // if (link.trim() === "" && isActive === false ){
        //     // // generateLink and pass to save/Update fucntion
        //     // //save and update that field on template
        //     // //generate Link hash 
        //     // //Pass link hash to fucntion below
        //     // const returnLink = buildShareLink(template.link, profile.companyName)
        // }else{
        return buildShareLink(template.link, profile.companyName)
        // setShareLink(returnLink)
        // }
    }, [template, profile])

    const copyLink = useCallback(() => {
        try{
            navigator.clipboard.writeText(generateShareLink())
            setShowCopied(true)
    
            const timeout = 5000 // 5seconds
            setTimeout(() => {
                setShowCopied(false)
            }, timeout);
        }catch(e){
            setShowError(e)
        }
       
    }, [generateShareLink])

    useEffect(() => {
        generateShareLink()
    }, [generateShareLink])

    return (
        <div className={classes.root}>
            <Typography variant = "body1">  {title ? title : "Create a new share from below"} </Typography>
            <div className={classes.shareOptsDiv}>
              
                { showCopied && 
                        <Toast open={showCopied} 
                            type='success' 
                            message={'copied to clipboard'} 
                            position={{ vertical: 'bottom', horizontal: 'left' }}/>}

                { (showLink) && (
                    (template.link.trim() !== '') ? 
                        <div className={classes.showBox}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Tooltip title="Go back">
                                    <ArrowBackIcon className={classes.closeIcon} onClick={() => setShowLink(false)}/>
                                </ Tooltip>
                            </div>
                            <div className={classes.link}>
                                <Typography style={{ fontSize: '11px' }} variant="caption" onClick={() => copyLink()}>{generateShareLink()}</Typography>
                                <FileCopyIcon onClick={() => copyLink()} className={classes.copyIcon}/>
                            </div> 
                        </div> : <Spinner/>)
                }

                { (showQR) && (
                    (template.link.trim() !== '') ?
                        <div className={classes.showBox}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Tooltip title="Go back">
                                    <ArrowBackIcon className={classes.closeIcon} onClick={() => setShowQR(false)}/>
                                </ Tooltip>
                            </div>
                            <QRCode value={generateShareLink()}/>
                        </div> : <Spinner/>)
                }

                { (!showLink && !showQR && shareOptions) && shareOptions.map((item, index) => (
                    <Box key={index} className={classes.box} onClick={(e) => handleShare(e, item.text)}>
                        <img className={classes.img} src={item.image} alt="share Icons"/>
                        <Typography variant="body2" style={{ margin: 'auto'}}> {item.text} </Typography>
                    </Box>
                ))}
               
            </div>
            <Typography variant = "caption">  PS: Dont forget to check <i>theme settings</i> before sharing </Typography>
        </div>
    )
}

ShareContent.propTypes = {
    template: PropTypes.object.isRequired,
    handleShare: PropTypes.func.isRequired,
    showQR: PropTypes.bool.isRequired,
    showLink: PropTypes.bool.isRequired,
    setShowQR: PropTypes.func.isRequired,
    setShowLink: PropTypes.func.isRequired,
}

export default ShareContent