import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react'

import { useHistory } from "react-router-dom"

import { useSelector, useDispatch } from 'react-redux'

import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import Layout, { Header } from '../../components/Layout'
import { genericSceneStyles, Colors } from '../../../assets/js/styles'
import { Typography, useMediaQuery } from '@material-ui/core' 
import Spinner from '../../components/Spinner'

import { getTemplates } from '../../redux/actions/templateAction'
import { FeedbackCard } from '../../components/Card'
import SearchBar from '../../components/SearchBar'
import FilterFeedback from './components/FilterFeedback'

import { getResponses } from '../../redux/actions/responseActions'
import AuthComponent from '../../components/AuthComponent'

const Active='Active'
const Closed='Closed'
const All='All'

const Feedback = () => {

    const commonClasses = genericSceneStyles()

    const templates = useSelector(state => state.template.templates)
    const loading = useSelector(state => state.template.loading)
    const profile = useSelector(state => state.profile.data)

    const responses = useSelector(state => state.response.responses)

    const [templatesCopy, setTemplatesCopy] = useState(templates)
    // const [statusFilter, setStatusFilter] = useState(Closed)

    const dispatch = useDispatch()

    const isSMDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const [search, setSearch] = useState({
        searchTerm: "",
        applied: false,
        statusFilter: All,
    })

    const onChange = useCallback((e) => {
        setSearch({
            ...search,
            [e.target.name]: e.target.value
        })
    }, [search])

    const onClickSearchIcon = useCallback((e) => {
        e.preventDefault()
        const { searchTerm } = search

        if (searchTerm.trim() !== ''){
            setTemplatesCopy(templates.filter((item, index) => { 
                if(item.name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true
                }
                // for(let val of item.tag){
                //     if (val.toLowerCase().includes(searchTerm.toLowerCase()) ){
                //         return true
                //     }
                // }
                return false
            }))
            setSearch({
                ...search,
                applied: true
            })
        }
        
    }, [search, templates])


    const onClearSearch = useCallback((e) => {
        e.preventDefault()
        setTemplatesCopy(templates)
        setSearch({
            ...search,
            applied: false,
            statusFilter: All
        })
    }, [templates, search])

    const setFilterStatus = useCallback((e) => {

        const filterText = e.target.innerText.trim() ?? e.target.textContent.trim()

        const filterBy = (value) => {
            if (value === All){
                setTemplatesCopy(templates)
            }

            if (value === Active){
                setTemplatesCopy(templates.filter((item) => item.isActive === true))
            }

            if (value === Closed){
                setTemplatesCopy(templates.filter((item) => item.isActive === false))
            }
        }

        if (search.statusFilter !== filterText){
            setSearch({
                ...search,
                statusFilter: filterText
            })

            filterBy(filterText)
        }
    }, [search, templates])

    useEffect(() => {
       
        if (!templates){
            dispatch(getTemplates())
        }

        // setTimeout(() => dispatch(getResponses(profile?.id)), 2000)

        dispatch(getResponses(profile?.id))
        return () => {

        }
    }, [dispatch, templates, profile])

    const getFilterAndSearchDisplay = useCallback(() => {
        var returnVal = ''
        switch(search.statusFilter.trim().toLowerCase()){
            case All.trim().toLowerCase():
                returnVal = search.applied ? (
                        templatesCopy.map((item, index) => (
                            <FeedbackCard 
                                responses={responses.filter(res => res.templateId === item.id).length} 
                                key={'feedb_'+index} 
                                template={item}
                                isDefault={item.profileId === 'default'}/>
                        ))
                    ):(
                        templates.map((item, index) => (
                            <FeedbackCard 
                                responses={responses.filter(res => res.templateId === item.id).length} 
                                key={'feedb_'+index} 
                                template={item} 
                                isDefault={item.profileId === 'default'}/>
                        ))
                    )          
                break;
            case Active.trim().toLowerCase():
            case Closed.trim().toLowerCase():
                returnVal = 
                    templatesCopy.map((item, index) => (
                        <FeedbackCard 
                            key={'feedb_'+index} 
                            template={item}
                            isDefault={item.profileId === 'default'}
                            responses={responses.filter(res => res.templateId === item.id).length} 
                            />
                    ))
                break;
            default: break;
        }
        return returnVal
    }, [search, templatesCopy, templates, responses])

    return (
        <AuthComponent>
        <Layout>
           {loading ? (
                    <Spinner/>
                ) : (
                    <div className={commonClasses.root} style={{ color: Colors.lightDarkText }}>
                        <Header title="Feeback" avatarIcon=""/>
                        <div className={commonClasses.outerSection}>
                            <div className={commonClasses.innerSection}>
                                <div className={commonClasses.header}>
                                    {!isSMDown && <Typography variant="h6"> <b>{ 'View Feebacks' }</b></Typography>}
                                    <SearchBar 
                                        name={"searchTerm"} 
                                        search={search} 
                                        dataName={'feedbacks'}
                                        onChange={onChange}
                                        onClearSearch={onClearSearch}
                                        onClickSearchIcon={onClickSearchIcon} />
                                </div>
                                <div className={commonClasses.body}>
                                    <FilterFeedback type={'Survey'} status={search.statusFilter} setStatusFilter={setFilterStatus}/>
                                    {
                                        (search && search.statusFilter) ? 
                                                
                                            getFilterAndSearchDisplay()

                                              
                                        : 
                                            (search && search.applied) ? (
                                                templatesCopy.map((item, index) => (
                                                    <FeedbackCard 
                                                        responses={responses.filter(res => res.templateId === item.id).length} 
                                                        key={'feedb_'+index} 
                                                        isDefault={item.profileId === 'default'}
                                                        template={item}/>
                                                ))
                                            ) : (
                                                templates.map((item, index) => (
                                                    <FeedbackCard 
                                                        responses={responses.filter(res => res.templateId === item.id).length}
                                                        key={'feedb_'+index} 
                                                        template={item} 
                                                        isDefault={item.profileId === 'default'}/>
                                                ))
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </Layout>
        </AuthComponent>
    )
}

Feedback.propTypes = {

}



export default Feedback;