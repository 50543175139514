import React from 'react'
import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';

import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { Colors } from '../../../assets/js/styles'


const useStyles = makeStyles(theme => ({
    root:{ 
        minHeight: '200px',
        minWidth: '200px',
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        border: '1px solid lightgray',
        margin: "20px",
        borderRadius: '20px',
        padding: '15px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '150px',
            minWidth: '150px',
        },
        '&:hover': {
            cursor: 'pointer',
            background: 'lightgray'
        }
    },
    icon: { 
        minWidth: '80px', 
        minHeight: '80px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '80px', 
            minHeight: '80px',
        }
    },
    link: {
        textDecoration: 'none',
        color: Colors.black
    }
}))


const EmptyTemplateCard = () => {

    const classes = useStyles()

    //TODO:- onClick={() => setActiveMenu} when clicking the link, set Menu active to be current route

    return (
        <Link className={classes.link}  to={'/createform'}>
            <div className={classes.root} >
                <AddIcon className={classes.icon}/>
                <Typography variant='body1'>
                    Create Template
                </Typography>
            </div>
        </Link>
    )
}


export default EmptyTemplateCard