import React from 'react'

import { useDispatch } from 'react-redux'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { navBar, Colors, drawerWidth } from '../../../assets/js/styles'
import { Button } from '@material-ui/core';

import { useHistory } from 'react-router-dom'


import { logout } from "../../redux/actions/authAction"

import constants, { SideBarData } from '../../constants'

import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Logo from '../../../assets/img/landing/logo.svg'

import clsx from 'clsx'

import { makeStyles} from '@material-ui/core/styles'

import { Link } from 'react-router-dom'

const customStyles = makeStyles(theme => ({
    upgradebtn:{
        background: Colors.primary,
        borderRadius: '10px',
        // width: '50%',
        // elevation: '0',
        color: Colors.white,
        '&:hover': {
            color: Colors.primary
        }
    },
    accordionRoot: {
        background: Colors.background
    },
    drawerPaper: {
        width: drawerWidth,
        border:"none"
    },
    FooterDiv:{
        padding: '18px'
    },
    bottomText: {
        margin: '0 15px',
        marginBottom: '18px',
        color: 'rgb(162, 184, 202)'
    },
}))

const MenuContent = (props) => {
    const classes = navBar();

    const classStyles = customStyles()

    const dispatch = useDispatch()

    const { setMenuIndex, currentIndex, subMenu, setSubMenuIndex } = props

    const setMenu = (index) => {
        dispatch(setMenuIndex(index))
        dispatch(setSubMenuIndex(null, false))
    }

    const openChange = (event, open) => {
        dispatch(setSubMenuIndex(null, open))
    }

    const setSubMenu = (index) => {
        dispatch(setSubMenuIndex(index, subMenu.open))
        dispatch(setMenuIndex(null))
    }

    const { push } = useHistory()

    const triggerLogOut = () => {
        dispatch(logout())
        // push(constants.Routes.Login)
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    return (
        <div className={classes.drawerContent}>
            
            <div className={classes.logo_and_button_div}> 
                <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
            </div>

            <div className={classes.menuList}>
                <List style={{ background: Colors.background }}>
                    {SideBarData.map((item, index) => (
                        item.children ? 
                            <div key={item.title + '___' +index}>
                                <Accordion 
                                    elevation={0}
                                    className={classStyles.accordionRoot}
                                    expanded={ subMenu && subMenu.open} 
                                    onChange={(event, open) => openChange(event, open)}>
                                    <AccordionSummary
                                        
                                        expandIcon={<ExpandMoreIcon style={{ color: Colors.textColor}} />}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                        >
                                            {/* <div classes={classes.accordionSumma}> */}
                                                <ListItemIcon style={{ color: Colors.textColor}}>{item.icon}</ListItemIcon>
                                                <div className={classes.accordionSumma}>
                                                    <ListItemText primary={item.title} />
                                                </div>
                                            {/* </div> */}
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {item.children.map((child, childIndex) => (
                                            <div 
                                                className={childIndex === parseInt(subMenu.index, 10) ? clsx(classes.nav_submenu_item, classes.nav_menu_item_selected) : classes.nav_submenu_item}
                                                key={childIndex}
                                                onClick={() => setSubMenu(childIndex)}>
                                                    <Link className={classes.Link}  to={child.path}>
                                                        <ListItem>
                                                            <ListItemText primary={child.title} />
                                                        </ListItem>
                                                        {/* <Typography> {child.title}</Typography> */}
                                                    </Link>
                                            </div>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        :
                            <div 
                                className={index === currentIndex ? clsx(classes.nav_menu_item, classes.nav_menu_item_selected) : classes.nav_menu_item}
                                onClick={() => setMenu(index)} 
                                key={index}>
                                        <Link className={classes.Link } to={item.path}>
                                        <ListItem>
                                            <ListItemIcon >{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItem>
                                    </Link>
                            </div>
                    ))}

                </List>
            </div>
        
            <div className={classes.upgradebtnDiv}>
                <Button className={classes.upgradebtn}> Upgrade </Button>
            </div>

            <div className={classStyles.FooterDiv}> 
                <Typography className={classStyles.bottomText} variant="body2">Integration</Typography>
                <Typography className={classStyles.bottomText} variant="body2">Help</Typography>
                <Typography 
                    onClick={(e) => triggerLogOut(e)}
                    className={clsx(classStyles.bottomText, classes.pointer)} variant="body2">
                        Log Out
                </Typography>
            </div>
        </div>
    )
}

export default MenuContent
