import './App.css';
import { useEffect, useRef, useState } from 'react'

// Material UI
import { ThemeProvider, responsiveFontSizes  } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core';

//Redux - State Mgmt
import { Provider } from 'react-redux';
import  store  from './store';
import { FirebaseServiceFactory } from './app/utils/FirebaseService'


// React router
import { BrowserRouter, Route, Switch } from 'react-router-dom';

//App Components

// App scenes
import LandingPage from './app/scenes/LandingPage'
import PricingPage from './app/scenes/PricingPage'
import KilledView from './app/scenes/KilledView'
import Home from './app/scenes/Home'
import Profile  from './app/scenes/Profile'
import Feedback from './app/scenes/Feedback'
import CreateNewForm from './app/scenes/CreateNewForm'
import Template from './app/scenes/Template'
import Login from './app/scenes/Auth/Login'
import Register from './app/scenes/Auth/Register'
import ResponderForm from './app/scenes/ResponderForm'
import ViewResponse from './app/scenes/ViewResponse';
import EditForm from './app/scenes/EditForm'
import NotFound from './app/scenes/NotFound'
import WaitList from './app/scenes/WaitList'

// Defaults
import { Colors } from './assets/js/styles'


const theme = createTheme({
  palette:{
      primary: {
          main: Colors.primary,
      },
      secondary: {
          main: Colors.secondary,
      },
      background:{
          default: Colors.background
      }
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  typography: {
     "fontFamily": "\"Nunito\", \"Kalam\",\"Dancing Script\",\"Pacifico\", \"Oswald\",\"GreatVibes\", \"Roboto\", \"Helvetica\", \"Arial\", cursive",
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
  },
})

const responsiveTheme = responsiveFontSizes(theme)

function App() {

  const mounted = useRef(false)

  const [showKilled, setShowKilled] = useState(false)

  useEffect(() => {

    //  store.dispatch(loadUser()).catch()

    const instance = FirebaseServiceFactory.getInstance()
    const kill = instance.getRemoteConfig(instance.configKeys.killSwitch)

    if (kill._value === 'true'){
      setShowKilled(true)
    }

  }, [])

  if (showKilled === true){
    return <KilledView/>
  }

  return (
    <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider  theme={responsiveTheme}>
            <CssBaseline/> 
            {/* <NavBar/> */}
            <Switch>
                  {/* Private routes */}
                  <Route exact path='/' render={(props) => <Home {...props}/>}/> 
                  <Route path='/profile' render={(props) => <Profile {...props}/>}/>

                  <Route exact path='/feedback' render={(props) => <Feedback {...props}/>}/>
                  <Route path='/createform' render={(props) =>  <CreateNewForm {...props}/>}/>
                  <Route exact path='/edit-template/:id' render={(props) => <EditForm {...props}/> }/>
                  <Route path='/template' render={(props) =><Template {...props}/> }/>
                  <Route path='/feedback/:templateId' render={(props) =>  <ViewResponse {...props}/>}/>


                  { /*  Public Routes*/}
                  <Route exact path="/landing" render={(props) => <LandingPage {...props}/> }/> 
                  <Route exact path="/pricing" render={(props) => <PricingPage {...props}/> }/> 
                  <Route exact path='/login' render={(props) => <Login {...props}/>}/>
                  <Route path="/join-listin-beta" render={(props) => <WaitList {...props}/>}/>
                  <Route path= "/u/:company/:templateHash" render={(props) => <ResponderForm {...props}/>}/>
                  <Route path='/register' render={(props) => <Register {...props}/>}/>
                  <Route render={(props) => <NotFound {...props}/>} />

            </Switch>
          </ThemeProvider>
        </BrowserRouter>
    </Provider>
  );
}

export default App;
