class MTheme {
    
    constructor(id, owner, name, colors, options, isDefault=false, isPublic=false, dateCreated, dateUpdated) {
        this.id = id
        this.owner = owner 
        this.name = name;
        this.colors = colors; 
        this.options = options;
        this.isDefault = isDefault;
        this.isPublic = isPublic;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }

    toFirestore() {
        return {
            owner : this.owner,
            name : this.name,
            colors : this.colors,
            options : this.options,
            isDefault : this.isDefault,
            isPublic : this.isPublic,
            dateCreated : this.dateCreated,
            dateUpdated : this.dateUpdated,
        };
    }

    static fromFirestore(snapshot){
        const data = snapshot.data();
        return new MTheme(snapshot.id, data.owner, data.name, data.colors, data.options, data.isDefault, data.isPublic, data.dateCreated, data.dateUpdated);
    }

}

export default MTheme;