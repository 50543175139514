import {
    INDEX_CHANGED, 
    SUB_MENU_OPEN,
    SUBMENU_INDEX_CHANGED,
    GET_INITIAL_MENU
} from '../types';

//import { uuid } from 'uuid';

// export const getInitialMenu = () => dispatch=>{
//     dispatch({
//         type: GET_INITIAL_MENU,
//         payload: null
//     })
// }

export const setMenuIndex = (index) => dispatch => {
    if (!isNaN(index)){
        dispatch({
            type: INDEX_CHANGED,
            payload: { currentIndex: index }
        });
    }else{
        dispatch({
            type: INDEX_CHANGED,
            payload: { currentIndex: null }
        });
    }
};

export const setSubMenuIndex = (index, open) => dispatch => {
    if (open && !isNaN(index)){
        dispatch({
            type: SUBMENU_INDEX_CHANGED,
            payload: { index: index, open: true }
        });
    }else if (open){
        dispatch({
            type: SUBMENU_INDEX_CHANGED,
            payload: { index: null, open: true }
        });
    }else{
        dispatch({
            type: SUBMENU_INDEX_CHANGED,
            payload: { index: null, open: false }
        });
    }
};