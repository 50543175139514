import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx'
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '3px solid lightgray',
        background: 'white',
        borderRadius: '10px',
        margin: '10px',
        '&:hover': {
            background: 'lightgray',
            cursor: 'pointer'
        },
    },
    selected: {
        background: 'lightgray',
    },
    oneWordWidth: {
        minWidth: '100px',
        minHeight: '100px',
        [theme.breakpoints.down('xs')]: {
            minHeight: '80px',
            minWidth: '80px'
        },
    },
    multiWordWidth:{
        maxWidth: '200px',
        maxHeight: '200px',
    },
    typo:{
        padding: '5px',
        lineBreak: 'auto'
    }
}))


const MultiChoiceContainer = ({ value, isSelected, color, onClick, style=null }) => {

    const classes = useStyles()

    const setStyles = useCallback(() => {
        let returnVal = style ? {
            ...style,
            border: `3px solid ${color}`,
        } : {
            border: `3px solid ${color}`,
        }
        return returnVal
    }, [color, style])

    return (
        <div 
            onClick={(e) => onClick(e)}
            style={setStyles()}
            className={clsx(classes.root, {
                [classes.selected]: isSelected,
                // [classes.oneWordWidth]: value.split(' ').length === 1,
                // [classes.multiWordWidth]: value.split(' ').length > 1,
                [classes.multiWordWidth]: true,
            })}>
                <Typography className={classes.typo} variant="body2">
                    {value}
                </Typography>
        </div>
    )
}

export default MultiChoiceContainer