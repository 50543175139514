class BetaUser{
    constructor({ id = "", email, firstname, lastname, companyname, phonenumber }) {
        this.id = id;
        this.email = email;
        this.firstname = firstname
        this.lastname = lastname
        this.companyname = companyname
        this.phonenumber = phonenumber
    }

    toFirestore() {
        return {
            email: this.email,
            firstname : this.firstname,
            lastname : this.lastname, 
            companyname : this.companyname, 
            phonenumber : this.phonenumber, 
        }
    }

    static fromFirestore(snapshot) {
        const data = snapshot.data();
        return new BetaUser({ 
            id: snapshot.id, 
            email: data.email, 
            firstname: data.firstname, 
            lastname: data.lastname,
            companyname: data.companyname,
            phonenumber: data.phonenumber,
        });
    }
}

export default BetaUser;

