import React, { useState, useRef, useCallback } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';

import { 
     Container,
     Popper, Fade, Divider
} from '@material-ui/core'

import useClickOutside from '../../hooks/useClickOutside'
import { HexColorPicker } from "react-colorful";


const useStyles = makeStyles(theme => ({
    root:{
        // position: 'relative',
        marginBottom: '15px'
    },
    pillContainer: {
        display: 'flex', 
        justifyContent: 'space-around',
        width: '100%'
    },
    avatarDiv: {
        display: 'flex', 
        justifyContent: 'center',
    },
    avatar: {
       
    },
    popper:{
        zIndex: 1000000000
    }
}))

const MColorPicker = ({ colorHex, label, onChange }) => {
    const classes = useStyles()
    const [showPicker, setShowPicker] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();

    const handlePopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setShowPicker((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const popover = useRef()

    const closePicker = useCallback(() => setShowPicker(false), []);
  
    useClickOutside(popover, closePicker);

    return (
        <div className={classes.root}>
            <Popper style={{ display: "inherit" }} className={classes.popper} open={showPicker} ref={popover} anchorEl={anchorEl} placement={placement} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <HexColorPicker color={colorHex} onChange={(e) => onChange(e, label)}/>
                    </Fade>
                )}
            </Popper>
            <div  className={classes.avatarDiv}>
                <Avatar 
                    onClick={handlePopper("top")}
                    style={{
                        background: colorHex,
                        outline: '1px solid',
                        boxShadow: '2px 2px',
                        cursor: 'pointer'
                    }}>
                       {''}
                </Avatar>
            </div>
             <Typography> {label} </Typography>
        </div>
    )
}

export default MColorPicker