import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useCallback, useRef, lazy,  Suspense, useEffect  } from 'react'
import { useHistory } from 'react-router-dom'

//@material-ui
import { IconButton, Typography, Popper, Fade, Container, Divider } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert';

//custom utils & components
import useClickOutside from '../../hooks/useClickOutside'
import MButton from '../MButton'

import PropTypes from 'prop-types'

//constants
import constants from '../../constants'

//styles & assets
import { cardStyles, Colors } from '../../../assets/js/styles'
import authImage from "../../../assets/img/authImage.png"

import useSwitchState from '../../hooks/useSwitchState'
import Spinner from '../../components/Spinner'
import Template from '../../models/Template';

import { deleteTemplate, setEditTemplate, toggleTemplateLink } from '../../redux/actions/templateAction'
import { getUserThemes, setActiveTheme } from '../../redux/actions/themeActions'

import MDialog from '../MDialog';
import MTheme from '../../models/MTheme';

import { makeStyles } from '@material-ui/core/styles'

// components
const ShareContent = lazy(() => import('../../scenes/Template/components/ShareContent'))
// const MDialog = lazy(() => import('../MDialog'))


const QR_Code='QR code'
const Create_URL='Create a url'

const useStyles = makeStyles(theme => ({
    popperList :{
        padding: '10px',
        margin: '5px 0px',
         width: '100%', 
         display: 'flex',
        '&:hover': {
            cursor: 'pointer',
            background: 'lightgreen',
        }
    },
}))

const TemplateCard = ({ options, template, user, templates, themes }) => {
    const classes = cardStyles()
    const classStyles = useStyles()

    const { push } = useHistory()
    const [shareDialogOpen, { on: showDialog, off: hideDialog }] = useSwitchState(false)
    const [defaultTemplateActions, setDefaultTemplateActions ] = useState(options)
    const [showQR, setShowQR] = useState(false)
    const [showLink, setShowLink] = useState(false)

    // const templates = useSelector(state => state.template.templates)
    // const themes = useSelector(state => state.theme.themes)

    // const user = useSelector(state => state.auth.user)
    const dispatch = useDispatch()

    // console.log(defaultTemplateActions)

    const prepareTheme = useCallback(async () => {
        if(themes.length > 0){
            dispatch(setActiveTheme(themes.filter(item => item.id === template.themeId)[0]))
        }else{
            dispatch(getUserThemes(user.uid, template.themeId))
        }
    }, [dispatch, template, themes, user])

    const performAction = useCallback(async (e,  templateId) => {

        const actionText = e.target.innerText.trim() ?? e.target.textContent.trim()

        // console.log(Object.values(defaultTemplateActions))
        // console.log(Object.keys(defaultTemplateActions))

        switch(actionText) {
            case defaultTemplateActions.delete.text:

                dispatch(deleteTemplate(template.id, templates, template)) // Delete template
                break;

            case defaultTemplateActions.edit.text:
                //set template global state
                dispatch(setEditTemplate(template))

                //set themes global state
                await prepareTheme()
                push(`${constants.Routes.EditTemplate}/${templateId}`)
                break;

            // case defaultTemplateActions.viewresults.text:
            //     // Link to View
            //     break;

            case defaultTemplateActions.share.text:
                showDialog() 
                break; 

            default: 
                if (e.target.innerText.trim() === "Close"){
                    dispatch(toggleTemplateLink(template, false, templates))
                }
                break;
        }

    }, [defaultTemplateActions, showDialog, dispatch, templates, template, prepareTheme, push])


    const triggerShowLink = useCallback((id) => {
        if (template.link.trim() === "" && template.isActive === false){
            dispatch(toggleTemplateLink(template, true, templates))
            setShowLink(true)
            setShowQR(false)
        }else{
            setShowLink(true)
            setShowQR(false)
        }
    }, [dispatch, templates, template ])


    const triggerShowQR = useCallback((id) => {
        if (template.link.trim() === "" && template.isActive === false){
            dispatch(toggleTemplateLink(template, true, templates))
            setShowQR(true)
            setShowLink(false)
        }else{
            setShowQR(true)
            setShowLink(false)
        }
       
    }, [dispatch, templates, template ])


    const handleShare = useCallback((e, textContent) => {
        switch(textContent) {
            case Create_URL: 
                triggerShowLink()
                break;
            case QR_Code: 
                triggerShowQR()
                break;
            default: break;
        }
    }, [triggerShowLink, triggerShowQR])


    const [showOptions, setShowOptions] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [placement, setPlacement] = useState();


    const handlePopper = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setShowOptions((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const popover = useRef()

    const closePicker = useCallback(() => setShowOptions(false), []);
  
    useClickOutside(popover, closePicker);

    const getFriendlyText =  useCallback((key, isActive) => {
        let value = defaultTemplateActions[key].text

        if (typeof(value) === "function"){
            value = value(isActive) //pass active status here for template
        }

        return value
    }, [defaultTemplateActions])

    const triggerAction = useCallback((e) => {

        const { id } = template


        performAction(e, id)
        setShowOptions(false)

    }, [performAction, template])

    return (
        <div className={classes.root}>
            <div className={classes.imgsection}>
                <img className={classes.stubImage} src={authImage} alt="card img"/>
                <div className={classes.custom_shape_divider}>
                    <svg className={classes.svg} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className={classes.shapeFill}></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className={classes.shapeFill}></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className={classes.shapeFill}></path>
                    </svg>
                </div>
            </div>
            <div className={classes.textsection}>
                <div className={classes.tagsArea}>
                {template.tag.map((item, index) => (
                    <div key={index} className={classes.tag}> 
                            <Typography  variant="caption"><b>{item}</b></Typography>
                    </div>
                ))}
                </div>
                <Typography variant="h6"><b>{template.name}</b></Typography>
            </div>
            <div className={classes.buttonsection}>
                <MButton text="Use template"  width="140px" marginX="10px" />
                <IconButton onClick={(event) => handlePopper("top")(event)}>
                    <MoreVertIcon />
                </IconButton>

                <Popper className={classes.popper} open={showOptions} ref={popover} anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <div className={classes.popperListDiv}>
                                {defaultTemplateActions && Object.keys(defaultTemplateActions).map((key, index) => {
                                    return (getFriendlyText(key, template.isActive) !== '') && (
                                        <div key={"index"+ index+ "____5678"}>
                                            <Container 
                                                key={index}
                                                className={classStyles.popperList}
                                                onClick={e => triggerAction(e)}>
                                                    {defaultTemplateActions[key].icon}
                                                    <Typography style={{ marginLeft: '10px'}} variant="caption">{getFriendlyText(key, template.isActive)}</Typography>
                                            </Container>
                                            <Divider/>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </Fade>
                    )}
                </Popper>
                
                <MDialog 
                    bgColor={Colors.background}
                    className={classes.mDialog} 
                    open={shareDialogOpen} 
                    handleClose={hideDialog}
                    content={
                        <Suspense fallback={<Spinner/>}>
                            <ShareContent 
                                source={"templateCard"}
                                title={"Create a new share from below"}
                                template={template} 
                                handleShare={handleShare} 
                                showQR={showQR} 
                                setShowQR={setShowQR}
                                setShowLink={setShowLink}
                                showLink={showLink}/>
                        </Suspense>} 
                />
            </div>
        </div>
    )
}

TemplateCard.propTypes = {
    template: PropTypes.instanceOf(Template).isRequired,
    user: PropTypes.object, 
    templates: PropTypes.arrayOf(PropTypes.instanceOf(Template)).isRequired,
    themes: PropTypes.arrayOf(PropTypes.instanceOf(MTheme)).isRequired,
}


export default TemplateCard