import React, { useState, useEffect, useCallback } from 'react'
import { Link , useHistory} from 'react-router-dom'

import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import constants from '../../constants'

import { Colors } from '../../../assets/js/styles'
import Template from '../../models/Template'
import { Typography, useMediaQuery } from '@material-ui/core'
import MButton, { SplitButton } from '../MButton'

import authImage from '../../../assets/img/authImage.png'

const useStyles = makeStyles(theme => ({
    root:{ 
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        border: `1px solid ${Colors.background}`,
        borderRadius: '5px',
        margin: '20px',
        padding: '20px',
        boxShadow: `1px 1px ${Colors.background}`,
        [theme.breakpoints.down('sm')]:{
            display: 'block',
        }
    },
    promoText:{
        backgroundColor: 'pink',
        padding: '5px',
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]:{
            marginBottom: '10px',
        }
    },
    firstDiv: {
        display: 'block',
    },
    imgArea: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]:{
            marginBottom: '20px',
            alignItems: 'flex-end',
        }
    },
    stubImage: {
        marginRight: '10px',
        width: '80px',
        height: '80px',
        borderRadius: '8px',
        border: `0.5px solid ${Colors.background}`,
        boxShadow: `1px 1px ${Colors.background}`
    },
    tag: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: 'rgba(0, 149, 64, 1)',
        background: 'rgba(245,248,237, 1)',
        border: '1px solid transparent',
        borderRadius: '8px',
        // padding: '0px',
        minWidth: '30px',
        height: '20px',
        marginLeft: '5px'
    },
    titleDiv: {
        display: 'block',
    }

}))

const MiniDiv = ({ label, text }) => {

    return (
        <div style={{ display: 'block'}}>
            <Typography variant="body1"><b>{text}</b></Typography>
            <Typography variant="body2">{label}</Typography>
        </div>
    )
}

const FeedbackCard = ({ template, imageAvatar, responses, isDefault }) => {
    const classes = useStyles()

    // const isXSUp = useMediaQuery(theme => theme.breakpoints.up('sm'))
    const isXSDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const { push } = useHistory()

    const showActions = useCallback( (e) => {
        push(`${constants.Routes.Feedback}/${template.id}`)
    }, [template, push])

    return (
        <div className={classes.root}>
            <div className={classes.firstDiv}>
                <div className={classes.imgArea}>
                    <img className={classes.stubImage} src={imageAvatar ? imageAvatar : authImage} alt="card img"/>
                    <div className={classes.titleDiv}>
                        <Typography variant='h6'> {template.name} </Typography>
                        <div style={{ display: 'flex', justifyContent: 'flex-start',alignItems: 'center',}}>
                            <Typography variant='caption'>
                                 Last Modified on {' '}
                                 {new Date(template.dateUpdated.seconds*1000).toDateString()} 
                            </Typography>
                            {isDefault && <Typography className={classes.tag} variant='caption'>default</Typography> }
                        </div>
                    </div>
                </div>
                <div className={classes.promoText}> Complete your {template.type} using themes, design and more.. </div>
            </div>

            {
                isXSDown &&  
                <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: '10px'}}>
                    <MiniDiv text={Object.keys(template.questions).length} label="Questions"/>
                    <MiniDiv text={responses ? responses : '--'} label="Responses"/>
                </div>
            }
            {!isXSDown && <MiniDiv text={Object.keys(template.questions).length} label="Questions"/>}
            
            {!isXSDown && <MiniDiv text={responses ? responses : '--'} label="Responses"/>}
            
            <div>
                {/* <SplitButton /> */}
                <MButton text="View" endIcon={''} buttonStyle='outline' onClick={(e) => showActions(e)} />
            </div>
        </div>
    )
}

FeedbackCard.propTypes ={
    template: PropTypes.instanceOf(Template)
}

export default FeedbackCard
