class Profile{
    constructor(id, name, email, phone, companyName, companySector, companySize, city, country, dateCreated, dateUpdated, url, plan) {
        this.id = id
        this.url = url ? url : ''
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.companyName = companyName;
        this.companySector = companySector;
        this.companySize = companySize;
        this.city = city;
        this.country = country;
        this.plan = plan;
        this.dateCreated = dateCreated;
        this.dateUpdated = dateUpdated;
    }

    toFirestore() {
        return {
            name : this.name,
            email : this.email,
            url: this.url,
            phone : this.phone,
            companyName : this.companyName,
            companySector : this.companySector,
            companySize : this.companySize,
            city : this.city,
            plan: this.plan,
            country : this.country,
            dateCreated: this.dateCreated,
            dateUpdated: this.dateUpdated,
        };
    }

    toFirestoreUpdate() {
        return {
            name : this.name,
            email : this.email,
            url: this.url,
            phone : this.phone,
            companyName : this.companyName,
            companySector : this.companySector,
            companySize : this.companySize,
            city : this.city,
            plan: this.plan,
            country : this.country,
            dateUpdated: this.dateUpdated,
        };
    }

    static fromFirestore(snapshot){
        const data = snapshot.data();
        return new Profile(snapshot.id, data.name, data.email, data.phone, data.companyName, 
                           data.companySector, data.companySize, data.city, data.country, 
                           data.dateCreated, data.dateUpdated, data.url, data.plan);
    }
}

export default Profile;