import {
   ADD_TEMPLATE,
   GET_TEMPLATE,
   GET_TEMPLATES,
   DELETE_TEMPLATE,
   EDIT_TEMPLATE,
   TEMPLATE_LOADING,
   TEMPLATE_ERROR,
   SET_EDIT_TEMPLATE
} from '../types';

//firebase
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

import { setAlert } from '../actions/alertActions'

import Template from '../../models/Template'

import { encrypt, generateExternalLink } from '../../utils/helpers'
import { TemplateCreatedMsg, TemplateDraftCreated, TemplateUpdatedMsg } from '../../constants/constants';
import { toggleLoading } from '../reduxUtils';


const defaultTemplate = "default"


export const getTemplate = (id) => async dispatch => {
    
    try{

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth ){
            if(id) {
                dispatch({
                    type: TEMPLATE_LOADING,
                })
        
                let mTemplate = await instance.getOneById(fsCollection.TEMPLATE, id)
        
                dispatch({
                    type: GET_TEMPLATE,
                    payload: mTemplate
                })
            }else{
                dispatch({
                    type: TEMPLATE_ERROR,
                    payload: { error: "Invalid Template - 404"}
                })
            }
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })
        }

    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR,
            payload: e
        })
    }
}

export const setTemplate =(template) => async dispatch => {
    
    try{

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            if(template) {
                dispatch({
                    type: TEMPLATE_LOADING,
                })
                
                dispatch({
                    type: GET_TEMPLATE,
                    payload: template
                })
            }else{
                dispatch({
                    type: TEMPLATE_ERROR,
                    payload: { error: "Invalid Template - 404"}
                })
            }
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })
        }

    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR,
            payload:  e
        })
    }
}

//filter for orderBy index and other search queries like tags...
export const getTemplates = (profile, filter) => async dispatch => {
    
    try{

        dispatch({
            type: TEMPLATE_LOADING,
        })

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH

        let mTemplates

        // const newPrams = {
        //     profileId : defaultTemplate,
        // }
        // const defaultTemps = await instance.getMany(fsCollection.TEMPLATE, newPrams, filter)

        if (auth.currentUser && profile){ //handle auth check in React Component 

            const params = {
                profileId : profile.id,
            }

            mTemplates = await instance.getMany(fsCollection.TEMPLATE, params, filter)

            dispatch({
                type: GET_TEMPLATES,
                payload: mTemplates //mTemplates.concat(defaultTemps)
            })
        }else{
            const currentUser = await instance.getCurrentUser()
            if (currentUser){
                const mProfile = await instance.getCurrentProfile(currentUser.email)

                const params = {
                    profileId : mProfile.id,
                }
    
                mTemplates = await instance.getMany(fsCollection.TEMPLATE, params, filter)

                dispatch({
                    type: GET_TEMPLATES,
                    payload: mTemplates//mTemplates.concat(defaultTemps)
                })
            }else{
                dispatch({
                    type: TEMPLATE_ERROR,
                    payload: { error: "Unauthorized - 401"}
                })
            }
        }

    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR,
            payload: e
        })
    }
}



export const saveTemplate = (form, profile, themeId, uid, templates, isActive) => async dispatch => {

    try{

        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: TEMPLATE_LOADING,
            })
            
            let newTemp
            const timestamp = instance.serverTimeStamp
    
            let { name, type, link, questions, tags, isDraft, welcomeText, goodbyeText } = form
    
            link = isActive === true ? generateExternalLink(name, uid, profile.id) : ''
    
            const newTemplate = new Template("", isActive, link, name, type, profile.id, themeId , tags, 
                                            isDraft, isActive ? timestamp : '', questions, welcomeText, goodbyeText,  timestamp, timestamp)
    
            newTemp = await instance.saveOne(fsCollection.TEMPLATE, newTemplate.toFirestore())
        
            dispatch({
                type: ADD_TEMPLATE, 
                payload: {
                    templates: [...templates, newTemp] ,
                    template: newTemp
                }
            })

            if (isActive === true){
                dispatch(setAlert(TemplateCreatedMsg, "info"))
            }else{
                dispatch(setAlert(TemplateDraftCreated, "info"))
            }
    
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })
        }

    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR, 
            payload: e
        })
    }
    // setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};

export const setEditTemplate = (template) => dispatch => {
    try{
        dispatch({
            type: SET_EDIT_TEMPLATE,
            payload: template
        })
    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR, 
            payload: e
        })
    }
}

export const toggleTemplateLink = (template, isActive, templates) => async dispatch => {

    try{

        toggleLoading(true, TEMPLATE_LOADING)

        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            let updateResult
            const instance = FirebaseServiceFactory.getInstance()
            const timestamp = instance.serverTimeStamp

            let mTemplates  = templates.filter(item => item.id !== template.id)
    
            let { name, id, type, themeId, tag, questions, 
                welcomeText, goodbyeText, profileId, lastShare, dateCreated  } = template
            
            const linkhash = isActive === true ? generateExternalLink(name, id, profileId) : '';
            
            //generateExternalLink(name, id, profileId) : ''
    
            const updateTemplate = new Template(id, isActive, linkhash, name, type, profileId, themeId , tag, 
                                                false, isActive ? timestamp : lastShare, questions, 
                                                welcomeText, goodbyeText, dateCreated, timestamp)
    
            updateResult = await instance.updateById(fsCollection.TEMPLATE, template.id, updateTemplate.toFirestore())
        
            if (updateResult === true){
                toggleLoading(false, TEMPLATE_LOADING)

                dispatch({
                    type: EDIT_TEMPLATE, 
                    payload: {
                        templates: [...mTemplates, updateTemplate] ,
                        template: updateTemplate
                    }
                })
                dispatch(setAlert("Template Link Active", "info"))

            }else{
                throw new Error("Template Update Failed")
            }
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })
        }

    }catch(e){
        toggleLoading(false, TEMPLATE_LOADING)
        dispatch({
            type: TEMPLATE_ERROR, 
            payload: e
        })
    }
}

export const editTemplate = (template, form, profile, themeId, uid, templates, isActive) => async dispatch => {
    try{

        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: TEMPLATE_LOADING,
            })
    
            let mTemplates  = templates.filter(item => item.id !== template.id)
    
            let updateResult
            const instance = FirebaseServiceFactory.getInstance()
            const timestamp = instance.serverTimeStamp
    
            let { name, type, link, questions, tags, isDraft, welcomeText, goodbyeText } = form
    
            link = isActive ? generateExternalLink(name, uid, profile.id) : ''
    
            const updateTemplate = new Template(template.id, isActive, link, name, type, profile.id, themeId , tags, 
                                                isDraft, isActive ? timestamp : '', questions, 
                                                welcomeText, goodbyeText, template.dateCreated, timestamp)
    
            updateResult = await instance.updateById(fsCollection.TEMPLATE, template.id, updateTemplate.toFirestore())
        
            if (updateResult === true){
                dispatch({
                    type: EDIT_TEMPLATE, 
                    payload: {
                        templates: [...mTemplates, updateTemplate] ,
                        template: updateTemplate
                    }
                })
    
                dispatch(setAlert(TemplateUpdatedMsg, "info"))
            }
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })
        }
       
    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR, 
            payload: e
        })
    }
}


export const deleteTemplate = (id, templates, template) => async dispatch => {
    try{

        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            const success = await instance.deleteOne(fsCollection.TEMPLATE, id)

            if (success) {
    
                dispatch({ 
                    type: DELETE_TEMPLATE, 
                    payload: {
                        templates: templates.filter(item => item.id !== id),
                        template: template.id === id ? null : template
                    }
                })
            }
        }else{
            dispatch({
                type: TEMPLATE_ERROR,
                payload: { error: "Unauthorized - 401"}
            })  
        }

    }catch(e){
        dispatch({
            type: TEMPLATE_ERROR,
            payload: e
        })  
    }
}