import Navbar from "./Navbar"
import ImgPlatform from '../../../assets/img/landing/img-platform.svg'
import HeaderImg from '../../../assets/img/landing/header-img.png'

import { useHistory } from 'react-router-dom'
import constants from "../../constants"

import { useCallback, useState, forwardRef } from 'react'

import MDialog from '../../components/MDialog'
import Slide from '@material-ui/core/Slide';

import { Colors } from '../../../assets/js/styles'
import { profileStyles } from '../../../assets/js/styles' 



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});


const HeaderStyle2 = () => {

    const classes = profileStyles()

    const { push } = useHistory()

    const [showSignInOption, setShowSignInOption] = useState(false)

    const tryForFree = useCallback(() => {

        // setShowSignInOption(true)
        push(constants.Routes.Register)

        //authenticate anonymous User
        //login anonymous user.
        //Navigate to create template page and guide to create.
        //show preview button for user.
        //show QR code or link and direc user to visit link

    }, [push])


    const handleOption = useCallback((e, option) => {

        console.log(option)
    }, [])


    const onClose = () => setShowSignInOption(false)

    return (
            <header className='min-h-screen bg-[#16222D] grid grid-rows-[auto_1fr] text-white px-[8%]'>
                <Navbar />

                <div className="content py-10 flex flex-col-reverse md:flex-row gap-3">
                    <div className="details text-center md:text-left flex-1 flex flex-col gap-5 items-center md:items-start">
                        <h1 className='md:text-2xl lg:text-4xl'>
                            Listin empowers modern businesses with AI-Driven insights, feedback and experience management solutions for better customer experience, product and brand reputation
                        </h1>

                        <p className='text-md lg:text-lg mt-3'>
                            Refine customer experience (CX), get feedback, listen and grow your organization. We help you build better customer experience, products and brand reputation.
                        </p>

                        <div className='flex justify-around w-auto sm:w-full'>
                            <button onClick={() => push(constants.Routes.JoinListBeta)} className='bg-[#29C785] p-3 text-white hover:bg-[#29c785] hover:shadow-lg hover:shadow-[#2f654e] rounded-xl px-5 mt-3 transition-all duration-500 active:scale-90'>Join Listin Beta</button>
                            <button onClick={tryForFree} className='bg-[#29C785] p-3 text-white hover:bg-[#29c785] hover:shadow-lg hover:shadow-[#2f654e] rounded-xl px-5 mt-3 transition-all duration-500 active:scale-90'>Try for free</button>
                        </div>
                            
                        <MDialog
                            fullScreen={false}
                            bgColor={Colors.background}
                            className={classes.mDialog}
                            open={showSignInOption}
                            handleClose={onClose}
                            TransitionComponent={Transition}
                            content={
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', background: Colors.background }}>
                                        Dialog showing
                                    </div>
                                </>
                            }
                        />
                    </div>

                    <figure className="flex-1 relative">
                        <img src={ ImgPlatform } alt="" />
                        <img className="absolute top-0 w-[71%] left-[50%] translate-x-[-50%]" src={ HeaderImg } alt="" />
                    </figure>
                </div>
            </header>
        )
}

export default HeaderStyle2