import BulletIcon from "./BulletIcon"

const HowItWorks = () => {
  return (
      <section id="how-it-works" className='bg-[#E5E5E5] p-10 px-[8%]'>
        <h1 className='text-4xl font-bold border-l-8 p-2 py-0 border-[#29C785] text-[#27292E]'>How It Works</h1>
        
        <div className="card-list grid grid-cols-[_repeat(auto-fit,_minmax(300px,_1fr))] gap-10 py-10">
            {/* Card 1 */}
            <div className="card h-[465px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1]">
                <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">1</div>
                <h3 className="font-bold">Gather Feedback</h3>

                <ul className="flex flex-col gap-5 mt-2">
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Create a feedback form</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Send feedback requests to customers and clients easily</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Install feedback systems in offices, websites and mobile apps easily</span>
                    </li>
                </ul>

            </div>

            {/* Card 2 */}
            <div className="card h-[465px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1]">
            <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">2</div>
                <h3 className="font-bold">Listen</h3>

                <ul className="flex flex-col gap-5 mt-2">
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Get insights on feedback received</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Capture improved intelligence and data to understand your customers and business.</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Discover customers and industry market expectations</span>
                    </li>
                </ul>
                
            </div>

            {/* Card 3 */}
            <div className="card h-[465px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1]">
            <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">3</div>
                <h3 className="font-bold">Gather Feedback</h3>

                <ul className="flex flex-col gap-5 mt-2">
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Make decisive decisions across your organization.</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Respond and interact with customers directly.</span>
                    </li>
                    <li className='flex items-start gap-3'>
                        <BulletIcon />
                        <span>Capture data on customers behavior with your product and service.</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>
  )
}

export default HowItWorks