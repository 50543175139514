
import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, drawerWidth } from '../../../assets/js/styles'
import imga from '../../../assets/img/Logo.png'
import { useCallback } from 'react'

import moment from 'moment'

import { useDispatch } from 'react-redux'

import { removeNotification } from '../../redux/actions/notificationAction'


import { useHistory } from 'react-router-dom'
import constants from '../../constants'

const cardStyles = makeStyles(theme => ({
    root: {
        background: Colors.background,
        padding: '10px 10px',
        cursor: 'pointer'
    },
    div: {
        background: "white",
        borderRadius: '10px',

        borderBottom: `1px solid ${Colors.primary}`, //flip border when notification is seen

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 30px'
    },
    content: {
        // height: 'inherit',
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // backgroundColor: 'green'
    },
    img: {
        borderRadius: '30px',
        marginRight: '15px'
    },
    topArea: {
        display: 'flex',
        justifyContent: 'space-between', 
        alignItems: 'center',
        // margin: '5px', 
    }
}))



const NotificationCard = ({ notification, removeAnchorEl }) => {
   
    const classes = cardStyles()

    const dispatch = useDispatch()

    const { push } = useHistory();  

    const navigateToResponse = useCallback( async () => {

        const routeToNavigate = constants.Routes.Feedback + `/${notification.templateId}?active=${notification.responseId}`;

        removeAnchorEl()

        await dispatch(removeNotification(notification.id))
        
        push(routeToNavigate)
        //navigate to viewresponse

    }, [notification, push, removeAnchorEl, dispatch])

    return (
        <div className={classes.root} onClick={navigateToResponse}>
            <div className={classes.div}>
                <img className={classes.img} src={notification.bitmoji} width="60px" height="60px" alt="bitmoji-icon"/>
                <div className={classes.content}>
                    <div className={classes.topArea}>
                        <Typography variant="caption" style={{ color: Colors.menuIconColor}}>
                            {notification.email}
                        </Typography>
                        <Typography style={{ fontWeight: 'bold' }} variant="caption">
                            {moment(notification.dateCreated.toDate()).fromNow()}
                        </Typography>
                    </div>
                   
                    <Typography style={{ color: Colors.lightDarkText}} variant="body2">
                        {notification.content}
                    </Typography>
                </div>
            </div>
            {/* <Divider/> */}
        </div>
    )
}


export default NotificationCard

