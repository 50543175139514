class Notification{
    constructor({ id, seen, bitmoji, email, templateId, responseId, content, profileId, dateCreated }) {
        this.id = id

        this.seen = seen
        this.responseId = responseId
        this.templateId = templateId

        this.bitmoji = bitmoji
        this.content = content
        this.email = email ? email : "Anonymous" 
        
        this.profileId = profileId
        this.dateCreated = dateCreated
    }

    toFirestore() {
        return {
            seen : this.seen,
            responseId : this.responseId,
            templateId : this.templateId,

            bitmoji: this.bitmoji,
            content: this.content,
            email: this.email,

            profileId : this.profileId,
            dateCreated :  this.dateCreated
        };
    }

    static fromFirestore(snapshot){
        const data = snapshot.data();
        return new Notification({   
            id: snapshot.id, 

            seen: data.seen, 
            responseId: data.responseId,
            templateId: data.templateId,
            
            bitmoji: data.bitmoji, 
            content: data.content,
            email: data.email,

            profileId: data.profileId,
            dateCreated: data.dateCreated  
        });
    }
}

export default Notification;