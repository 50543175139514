import { Link } from 'react-router-dom'
import LogoImg from '../../../assets/img/landing/logo.svg'
import { Redirect, useHistory } from 'react-router-dom'
import constants from '../../constants'

const Navbar = ({ handleScroll }) => {

  const {push} = useHistory()

  return (
    <nav className='flex items-center p-3 gap-10'>
        <a href="/landing" className="logo mr-auto">
            <img src={LogoImg}  alt="" />
        </a>

        <Link to={constants.Routes.Pricing} id='cta' className='border border-[#29C785] md:border-0 p-2 text-white hover:bg-[#29c785] rounded-xl px-5 transition-all duration-500 active:scale-90'>
          Pricing
        </Link>

        <Link to={constants.Routes.JoinListBeta} id='cta' className='border border-[#29C785] p-2 text-white hover:bg-[#29c785] rounded-xl px-5 transition-all duration-500 active:scale-90 hidden md:inline-block'>Join Listin Beta</Link>
        
        {/* <button onClick={handleScroll} id='joinbeta' className='border border-[#29C785] p-2 text-white hover:bg-[#29c785] rounded-xl px-5 transition-all duration-500 active:scale-90 hidden md:inline-block'>
          Join Listin Beta
        </button> */}
    </nav>
  )
}

export default Navbar