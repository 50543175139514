import React from 'react'
import { Typography } from '@material-ui/core'
import  { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column',width: '100vw', height: '100vh' }}>
        <div>
            <Typography variant="h6"> Page Not Found</Typography>
        </div>
        <div>
            <Link to={"/landing"}>Home</Link>
        </div>
    </div>
  )
}

export default NotFound