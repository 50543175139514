import { Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import React, { useState, useCallback, Suspense, lazy, useEffect } from 'react'
import { useHistory } from "react-router";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// import { useDispatch } from 'react-redux'

//utils
import { uuidv4, isEmpty } from '../../utils/helpers'
import validateTemplateForm from '../../utils/validateTemplateForm'
import { genericSceneStyles } from '../../../assets/js/styles'

//constants
import constants, { TemplateCreatedMsg, defaultQuestions } from '../../constants/constants'

//Form and Theme Components
import CreateFormSuccessDialog from '../CreateNewForm/components/CreateFormSuccessDialog';
import AdvancedOptions from '../CreateNewForm/components/AdvancedOptions';
import QuestionArea from '../CreateNewForm/components/QuestionArea'
import CustomizeTheme from '../CreateNewForm/themeComponents/CustomizeTheme';
import SelectTheme from '../CreateNewForm/themeComponents/SelectTheme';
import PreviewForm from '../CreateNewForm/components/PreviewForm';


import { Grid, IconButton, Tooltip, Checkbox } from '@material-ui/core'

//generic components
import Layout, { Header } from '../../components/Layout'
import MTextField from '../../components/MTextField'
import MButton from '../../components/MButton'
import MTab from '../../components/Tab';
import TagsInput from '../../components/TagsInput'
import Toast from '../../components/Toast'

//Icons
import * as IoIcons from "react-icons/io5"
import VisibilityIcon from '@material-ui/icons/Visibility';

import TextField from '@material-ui/core/TextField';

import MTheme from '../../models/MTheme'
import User from '../../models/User';
import Profile from '../../models/Profile';
import Template from '../../models/Template';

import { editTemplate } from '../../redux/actions/templateAction'
import { getUserThemes, setActiveTheme } from '../../redux/actions/themeActions'

import GreetingTextField from '../../components/GreetingTextField'

import AuthComponent from '../../components/AuthComponent'

const ButtonText = {
    share: "share",
    save: "save"
}


const EditForm = ({ match, user, profile, themes, templates, template, alert, loading, error, isAuthenticated, activeTheme, setActiveTheme, getUserThemes, editTemplate }) => {


    const commonClasses = genericSceneStyles()

    const history = useHistory()
    const [questions, setQuestions] = useState(template ? template.questions : {})
    const [successPopUp, setSuccessPopUp] = useState(true)
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [formType, setFormType] = useState({
        review: true,
        survey: false
    });

    const [form, setForm] = useState({
        name : template ? template.name : '',
        type : template ? template.type : constants.formType.survey,
        tags: template ? template.tag : ["all"],
        link : template ? template.link : '',
        questions : template ? template.questions : { }
    })

    const [welcomeText, setWelcomeText] = useState(template ? template.welcomeText : {
        show: true,
        text: ''
    })

    const [goodbyeText, setGoodByeText] = useState(template ? template.goodbyeText :{
        show: true,
        text: ''
    })

    const getThemes = useCallback(async() => {
        if(user){
            getUserThemes(user.uid)
        }
    }, [user, getUserThemes])


    const setUpView = useCallback(() =>{
        if (template.id === match.params.id){
            if(themes.length > 0){
                // console.log("Length > 0>>>>>>>",themes)
                // console.log("Length > 0>>>>>>>",template)
                setActiveTheme(themes.filter(item => item.id === template.themeId)[0])
            }
        }else{
            // console.log("no templates, need to fetch>>>>>>>>")
            history.goBack()
            //TODO:---  Handle Error - setError and Display to User with back button, >>> history.gotAbck()
        }
    }, [match, template, themes, setActiveTheme, history])


    /**
     * For Testing Purpose.... Remove
     */
    // const checkSuccessDialog = useCallback(() =>{
    //     if (alert && alert.length > 0){
    //         if (alert[0].msg === TemplateCreatedMsg){
    //             setSuccessPopUp(true)
    //         }
    //     }
    // }, [])
    /**
     * End 
     */

    useEffect(() => {
        setUpView()
    }, [])

 
    const [errors, setErrors] = useState(null)

    const selectedTags = (tags) => {
        setForm({
            ...form,
            tags: tags
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        const content = e.target.textContent.toLowerCase() || e.target.innerText.toLowerCase()

        let isDraft = content === ButtonText.save ? true : false
        
        const isActive = content === ButtonText.save 
                            ? false 
                            : content === ButtonText.share 
                                ? true 
                                : false
 
        const { name, type, link, tags, questions} = form
    
        const { isValid, templateForm } = validateTemplateForm({ name, type, questions})
        
        if (isValid === true) {
            const obj = { 
                link, 
                isDraft,
                tags,
                name: templateForm.name, 
                type: templateForm.type,
                questions: templateForm.questions,
                welcomeText: welcomeText,
                goodbyeText: goodbyeText
            }

            const mTheme = activeTheme ? activeTheme : themes.filter(item => item.isDefault === true)[0]
    
            await editTemplate(template, obj, profile, mTheme.id, user.uid, templates, isActive) //fix isActive value
    
            //SET INTERNAL STATE FOR POP UP COMPONENT

            // history.push(`/template/${template.id}`)
            // history.push(`/template`)

        }else{


            //show errors on ui

            return
        }
    }

    const toggleAdvancedOptions = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
             return;
        }
        setShowAdvancedOptions(prevState => !prevState)
    }

    const toggleFormChecked = useCallback((e) => {

        const { survey, review } = constants.formType
        let obj = {}
        let formType = ''

        if (e.target.name === survey){
            obj[review] = false
            obj[survey] = e.target.checked
            formType = survey
        }else{
            obj[review] = e.target.checked
            obj[survey] = false
            formType = review
        }

        setForm({
            ...form, 
            type: formType
        })

        setFormType(obj)
    }, [setForm, form])

    const onChange =  useCallback(async (e, questionKey) => {
        
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setErrors({ ...errors,
          [e.target.name]: null
        })

    }, [errors, form, setForm])


    const onChangeQuestion = useCallback((e) => {

        // let highesIndex = 0
        // for (let val of Object.values(questions)){
        //     highesIndex = highesIndex > val.index ? highesIndex : val.index
        // }
        // index: highesIndex + 1

        setQuestions({
            ...questions,
            [e.target.name]: { 
                ...questions[e.target.name],
                text: e.target.value,
            }
        })

        setForm({
            ...form,
            questions: questions
        })

        setErrors({ ...errors,
            [e.target.name]: null
        })

    }, [setQuestions, setErrors, questions, errors, form, setForm])


    const onChangeAnswer = useCallback((e, questionKey) => {

        let newAnswers = []

        for (let ab of questions[questionKey].answers){
            if (ab.name === e.target.name){
                ab.value = e.target.value
            }
            newAnswers.push(ab)
        }

        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: newAnswers
            }
        })

        setForm({
            ...form,
            questions: questions
        })

        setErrors({ ...errors,
            [e.target.name]: null
        })

    }, [setQuestions, setErrors, errors, questions, form, setForm])


    const addQuestion = useCallback(() => {
        let key = uuidv4()

        let highesIndex = 0
        for (let val of Object.values(questions)){
            highesIndex = highesIndex > val.index ? highesIndex : val.index
        }

        questions[key] = {
            index: highesIndex + 1,
            text: "Type Question Here",
            answerType: constants.answerTypes.text,
            answers: []
        }

        setQuestions({
            ...questions,
        })

        
        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])
    // console.log(questions)

    const removeQuestion = useCallback((key) => {

        const indexToRemove = questions[key].index

        const indexArr = []
        for (let val of Object.values(questions)){
            indexArr.push(val.index)
        }
        const cleanIndexes = indexArr.map((val) => val === indexToRemove ? -1 : val).filter(index => index !== -1)

        //removes the key from question object
        let newQuestions = Object.fromEntries(Object.entries(questions).filter((val, i) => !val.includes(key)))
        
        for (let index of cleanIndexes.sort((a, b) => a - b)){
            for (let quest of Object.values(newQuestions).sort((a, b) => a - b)) {
                if(quest.index > indexToRemove){
                    if(quest.index === index){
                        quest.index = index - 1
                        break;
                    }
                }
            }
        }
        
        setQuestions({
            ...newQuestions
        })

        setForm({
            ...form,
            questions: newQuestions
        })
    }, [questions, setQuestions, form, setForm])


    const setAnswerTypeForQuestion = useCallback((answerType, questionKey) => {
        
        questions[questionKey] = {
            ...questions[questionKey],
            answerType: answerType
        }

        setQuestions({
            ...questions,
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])


    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    const addAnswer = useCallback((questionKey) => {

        let answersList = questions[questionKey].answers
        const answerIndex = questions[questionKey].answers.length
        const answerKey = uuidv4() //answerIndex === 0 ? `answer_${0}` : `answer_${answerIndex}` //uuid
        
        answersList.push({
            name: answerKey,
            value: ""
        })

        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: answersList
            }
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])


    const removeAnswer = useCallback((questionKey, answerName) => {
        let answersList = questions[questionKey].answers
        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: answersList.filter((item) => item.name !== answerName)
            }
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [setQuestions, questions, form, setForm])

    const handleSuccessModalClose = () => {
        setSuccessPopUp(false)
    }

    const closeDrawer = useCallback((close) => {
        setShowAdvancedOptions(false)
    }, [])

    const [showPreview, setShowPreview] = useState(false)

    const previewResponderForm = () => {
        setShowPreview(true)
    } 

    const closeResponderForm = () => {
        setShowPreview(false)
    }

    const onShowDisplay = useCallback((e) => {
        if (e.target.name === 'welcome'){
            setWelcomeText(prev => ({
                show: (prev && prev.show) && !prev.show, 
                text: (prev && prev.text)
            }))
        }

        if (e.target.name === 'goodbye'){
            setGoodByeText(prev => ({
                show: (prev && prev.show) && !prev.show, 
                text: (prev && prev.text)
            }))
        }
    }, [])

    const onChangeGreetingText = useCallback((e) => {
        if (e.target.name === 'welcome'){
            setWelcomeText(prev => ({
                    show: prev.show, 
                    text: [e.target.value] 
                })
            )
        }

        if (e.target.name === 'goodbye'){
            setGoodByeText(prev => ({
                    show: prev.show, 
                    text: [e.target.value] 
                })
            )
        }
    }, [])

    //MTAB - to handle index change
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
  
    const handleTabChangeIndex = (index) => {
        setTabValue(index);
    };

    const createNewTheme = useCallback(() => {
        setTabValue(0);
        setActiveTheme(null)
    }, [setActiveTheme])

    const editTheme = useCallback((them) => {
        setTabValue(0);
        setActiveTheme(them)
    }, [setActiveTheme])

    const [urlForPreview, setUrlForPreview] = useState('')

    const notifyBgImage = useCallback((url) => {
       setUrlForPreview(url)
    }, [])

    const [childData, ] = useState([ 
        { 
            label: "Customize", 
            child: <CustomizeTheme onCancel={closeDrawer} onSetBgForActiveTheme={notifyBgImage}/> //pass theme for edit here
        },
        {
            label: "Themes", 
            child: <SelectTheme createNewTheme={createNewTheme} setActiveTheme={setActiveTheme} editTheme={editTheme}/> 
        }
    ])
    /// MTAB

    return (
        <AuthComponent>
        <Layout>
            {/* { successPopUp && <CreateFormSuccessDialog 
                    template={template} 
                    open={successPopUp} 
                    message={"Success"} 
                    companyname={profile.companyName}
                    handleClose={handleSuccessModalClose}/> } */}
            <div className={commonClasses.root}>
                <Header title="Edit Form" avatarIcon=""/>
                <div className={commonClasses.outerSection}>
                    <div className={commonClasses.innerSection}>
                        {   ( 
                                (alert && alert.length > 0) && alert[0].msg.startsWith("Template")
                            ) 
                        && 
                            <Toast open={(alert && alert.length > 0)} type='success' message={alert[0].msg}/> 
                        }
                        <div className={commonClasses.header}>
                            <Typography variant="h6"> <b>Edit Form</b></Typography>
                        </div>
                        <form className={commonClasses.form} >
                            <MTextField name="name" value={form.name} label="Name" errors = {errors} onChange={onChange}/>
                            <div>
                                <Typography variant="subtitle2" style={{margin: '0px 10px'}}>Form Type</Typography>
                                <Grid container>
                                    <Grid item xs>
                                        <div>
                                            <Checkbox name={constants.formType.survey} checked={formType.survey} color="primary" onChange={toggleFormChecked}/>
                                            <Typography style={{color: 'gray'}} variant="caption"> Survey </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div>
                                            <Checkbox name={constants.formType.review} checked={formType.review} color="primary" onChange={toggleFormChecked}/>
                                            <Typography style={{color: 'gray'}} variant="caption"> Review </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <TagsInput mTags={template.tag} selectedTags={selectedTags}/>
                            {/* <MTextField name="link" value={form.link} label="Link" errors = {errors} disabled={true} onChange={onChange}/> */}

                            <div className={commonClasses.optsPrev}>

                                <Typography style={{color: 'gray', cursor: 'pointer' }} variant="caption" onClick={toggleAdvancedOptions}> {"show theme options"} </Typography>
                                <Typography style={{color: 'gray', cursor: 'pointer'}} variant="caption" > {"show template options"} </Typography>
                                {showAdvancedOptions && <AdvancedOptions 
                                    open={showAdvancedOptions} 
                                    anchor="right" toggleOpen={toggleAdvancedOptions} 
                                    child={
                                        <MTab value={tabValue} childData={childData} handleChange={handleTabChange} handleChangeIndex ={handleTabChangeIndex}/>
                                    }
                                />}

                                <Tooltip title="Preview responder form"> 
                                    <VisibilityIcon onClick={previewResponderForm}/>
                                </Tooltip>

                               {showPreview === true && <PreviewForm 
                                                        gImage={urlForPreview} 
                                                        open={showPreview} 
                                                        handleClose={closeResponderForm} 
                                                        questions={questions}  
                                                        theme={activeTheme}
                                                        welcomeText={welcomeText} 
                                                        goodbyeText={goodbyeText} />
                                        }
                            </div>

                            {
                                <GreetingTextField
                                    tooltipText={"Show Welcome Text"}
                                    value={ welcomeText && welcomeText.text}
                                    name={"welcome"}
                                    onSetShow={onShowDisplay}
                                    onChange={onChangeGreetingText}
                                    show={welcomeText && welcomeText.show}
                                />
                            }

                            {questions && Object.values(questions).map((question, index) => (
                                <QuestionArea 
                                     key={'key_' + index}
                                     name={getKeyByValue(questions, question)}
                                     value={question.text} 
                                     onChange={onChangeQuestion} 
                                     questionKey={getKeyByValue(questions, question)}
                                     answers={question.answers}
                                     setAnswerTypeForQuestion={setAnswerTypeForQuestion}
                                     addAnswer={addAnswer}
                                     answerType={question.answerType}
                                     onChangeAnswer={onChangeAnswer}
                                     removeAnswer={removeAnswer}
                                     onRemove={() => removeQuestion(getKeyByValue(questions, question))} />
                            ))}

                            {
                                <GreetingTextField
                                    tooltipText={"Show GoodBye Text"}
                                    value={goodbyeText && goodbyeText.text}
                                    name={"goodbye"}
                                    onSetShow={onShowDisplay}
                                    onChange={onChangeGreetingText}
                                    show={goodbyeText && goodbyeText.show}
                                />
                            }

                            <div style={{ marginLeft: '10px', display: "inline-flex", alignItems: "center" }}> 
                                <IconButton color="primary" onClick={() => addQuestion()}>
                                    <IoIcons.IoAddCircleSharp/>
                                </IconButton>
                                <Typography>Add a new question </Typography>
                            </div>

                            

                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '40px 0px' }}>
                                <Typography style={{color: 'gray'}}> Powered by ListIn </Typography>
                                
                                <div>
                                    <MButton text="Cancel" type="button" width="130px" marginX="10px" buttonStyle="outline"/>
                                    <MButton text="Share" type="button" onClick={e => onSubmit(e)} name= "share" width="130px" marginX="10px" />
                                    <MButton text="Save" type="button" onClick={e => onSubmit(e)} name="save" width="130px" marginX="10px"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
        </AuthComponent>
    )
}

EditForm.propTypes = {
    editTemplate: PropTypes.func.isRequired,
    getUserThemes: PropTypes.func.isRequired,
    setActiveTheme: PropTypes.func.isRequired,

    isAuthenticated:  PropTypes.bool,
    themes: PropTypes.arrayOf(PropTypes.instanceOf(MTheme)),
    activeTheme: PropTypes.instanceOf(MTheme),
    template: PropTypes.instanceOf(Template),
    templates: PropTypes.arrayOf(PropTypes.instanceOf(Template)),
    error: PropTypes.object,
    loading: PropTypes.bool,
    alert: PropTypes.array,
    user: PropTypes.instanceOf(User),
    profile: PropTypes.instanceOf(Profile),
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    themes: state.theme.themes,
    activeTheme: state.theme.theme,
    template: state.template.template,
    templates: state.template.templates,
    error: state.profile.error,
    loading: state.profile.loading,
    alert: state.alert,
    user: state.auth.user,
    profile: state.profile.data,
});

export default connect(mapStateToProps, { editTemplate, getUserThemes, setActiveTheme })(EditForm)
