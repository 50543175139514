import React from 'react'

const BulletIcon = () => {
  return (
    <div className='w-6 h-6 bg-[#29C785] rounded-full flex flex-shrink-0'>
        <div className="inner w-3 h-3 border-2 border-white rounded-full m-auto"></div>
    </div>
  )
}

export default BulletIcon