
import React from 'react'

import PropTypes from 'prop-types'

import { genericSceneStyles, Colors } from '../../../assets/js/styles'
import {  InputBase } from '@material-ui/core' 
import clsx from 'clsx'
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = ({ name, search, dataName, onChange, onClearSearch, onClickSearchIcon, width, customStyle={} }) => {
    const commonClasses = genericSceneStyles()
    return (
            <div className={commonClasses.outlinedFieldDiv} style={{ ...customStyle}}>
                <InputBase
                    id="outlined-textarea-3"
                    style={{ minWidth: width ? width : 'inherit'}}
                    className={commonClasses.searchBar}
                    placeholder= {`search ${dataName ? dataName : ''}...`}
                    type="text"
                    inputProps={{ 'aria-label': 'search templates' }}
                    value={(search && search.searchTerm) ? search.searchTerm : ''}
                    name ={name}
                    onChange={e => onChange(e)}
                />
                { (search && search.applied) 
                    ? <CloseIcon className={commonClasses.cursor} onClick={(e) => onClearSearch(e)} style={{ color: Colors.danger }}/>
                    : <SearchIcon  className={commonClasses.cursor} onClick={(e) => onClickSearchIcon(e)} style={{ color: Colors.gray }}/>
                }
            </div>
    )
}

SearchBar.propTypes ={
    name: PropTypes.string.isRequired,
    search: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    onClickSearchIcon: PropTypes.func.isRequired
}

export default SearchBar
