import React, { useState } from 'react'

//MUI
import { makeStyles } from '@material-ui/core/styles'

//Prop-types
import PropTypes from 'prop-types'

//icons
import CloseIcon from '@material-ui/icons/Close';

import constants, { defaultImageOptions } from '../../../constants/constants'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }, 
    closeIconDiv: {
        width: 'inherit',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    closeIcon:{
        cursor: 'pointer'
    },
    media: {
        width: '80px', 
        height: '80px'
    }, 
    div: {

    }
}))

const ThemeUploadedMedia = ({ media, clearImage }) => {

    const classes = useStyles()
    const [imageOptions, setImageOptions] = useState({
        background: '',
        fit: '',
        position: '',
        brightness: 0
    })

    return (
        <div className={classes.root}>
            <div className={classes.closeIconDiv}>
                <CloseIcon className={classes.closeIcon} onClick={clearImage}/>
            </div>
            <img className={classes.media} src={media} alt={"new Bg"}/>
        </div>
    )
}

ThemeUploadedMedia.propTypes = {
    media: PropTypes.string.isRequired,
}

export default ThemeUploadedMedia