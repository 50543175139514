import React, { useState, useCallback, useRef, useEffect }  from 'react'

import useClickOutside from '../../hooks/useClickOutside'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import Response from '../../models/Response'
import { IconButton, Typography, Popper, Fade, Container, Divider } from '@material-ui/core'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import gravatar from 'gravatar'

import {gravatarKeys} from '../../constants'


import {Colors} from '../../../assets/js/styles'
import ViewResponseDrawer from '../../scenes/ViewResponse/components/ViewResponseDrawer'
import ViewResponseDrawerContent from '../../scenes/ViewResponse/components/ViewResponseDrawerContent'

const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${Colors.background}`,
        borderRadius: '10px',
        margin: '20px',
        color: Colors.lightDarkText
    },
    avatar: {
        margin: '15px',
        borderRadius: '50px',
        [theme.breakpoints.down('xs')]:{
            width: '50px',
            height: '50px',
            borderRadius: '25px',
        }
    }
}))


const ResponseCard = ({response, template, disableButton=false, showFromNotificaition}) => {

    const classes = useStyles()

    const [showDetails, setShowDetails] = useState( showFromNotificaition ? showFromNotificaition : false)

    const handleAction = (value) => (event)=> {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowDetails(prevState => !prevState)
    }

    useEffect(()  => {
        if(showFromNotificaition){
            setTimeout(() => {
                setShowDetails(true)
            }, 1000)
        }
    }, [showFromNotificaition])

    const myRef = useRef()

    useClickOutside(myRef, useCallback(() => setShowDetails(false), []));

    return (
        <div className={classes.root}>
            {/* {response.id} */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img className={classes.avatar} src={response.bitmoji || gravatar.url(response.id, {s: '100', d: gravatarKeys[Math.floor(Math.random() * gravatarKeys.length)]})} alt={'bitmoji'}/>    
                    <Box sx={{ display: 'block'}}>
                        <Typography variant="body1"><b>{response.name ? response.name : 'Anonymous'}</b></Typography>
                        <Typography variant="caption">{new Date(response.dateupdated.seconds*1000).toDateString()}</Typography>
                    </Box>
                </Box>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                   
                    <IconButton disabled={disableButton} onClick={(event) => handleAction("right")(event)}>
                        <MoreHorizIcon />
                    </IconButton>
               
                       
                    {  showDetails && <ViewResponseDrawer 
                                            style={{ background: Colors.background }}
                                            anchor="right" 
                                            open={showDetails} 
                                            toggleOpen={handleAction}>
                                                <ViewResponseDrawerContent
                                                    ref={myRef}
                                                    onClose={setShowDetails}
                                                    response={response} 
                                                    template={(template !== undefined && Object.keys(template).length > 0 ) && template} />
                                        </ViewResponseDrawer>
                    }
                </div>
            </Box>
            <Box>
                <Container >
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: '20px',alignItems: 'center'}}>
                        <Typography variant='caption'>
                            location: {" "}<b>{response.location.country}</b>
                        </Typography>
                        <Typography variant='caption'>
                            Time Spent: {" "}<b>{response.timespent}sec</b>
                        </Typography>
                        {response.completed ? (
                            <div>
                                <CheckCircleIcon style={{ color: 'green'}}/>
                            </div>
                        ): (
                            <div>
                                <HighlightOffIcon style={{ color: 'red'}}/>
                            </div>
                        )}
                    </Box>
                    <Divider style={{ background: Colors.background}}/>
                </Container>
                <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-around', margin: '20px 0', alignItems: 'center'}}>
                    {(template !== undefined && Object.keys(template).length > 0 )&& <Typography variant="body2">
                            Questions: {" "}<b>{Object.values(template.questions).length}</b>
                        </Typography>
                    }
                    <Typography variant="body2">
                        Answers: {" "}<b>{Object.values(response.answers).length}</b>
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}

ResponseCard.propTypes ={
    template: PropTypes.object
}

export default ResponseCard
