import { navBar } from '../../../assets/js/styles'
import { Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { Colors } from '../../../assets/js/styles'


const useStyles = makeStyles(theme => ({
    upgradebtn:{
        background: Colors.primary,
        borderRadius: '10px',
        // width: '50%',
        color: Colors.white,
        '&:hover': {
            color: Colors.primary
        }
    },
    upgradebtnOutline: {
        borderRadius: '10px',
        borderColor: Colors.primary,
        // width: '50%',
        color: Colors.primary,
        '&:hover': {
            color: Colors.primary
        }
    }, 
}))

const MButton = ({ startIcon, text, width, type = "button", value, marginX=0, marginY=0, buttonStyle, onClick, endIcon, height, bg, disable, ...rest}) => {
    const classes = useStyles()
    return (
        <Button variant={buttonStyle === "outline" ? "outlined" : "contained"} 
                // color={Colors.primary}
                disabled={disable}
                startIcon={startIcon ? startIcon : null}
                endIcon={endIcon ? endIcon : null}
                className={buttonStyle === 'outline' ? classes.upgradebtnOutline : classes.upgradebtn} 
                style={{ background: bg && bg , width: width, margin: `${marginY} ${marginX}`, height: height, textTransform: 'none' }} 
                type={type} 
                value={value}
                onClick={onClick}
                {...rest}> 
                    {text}
         </Button>
    )
}

export default MButton