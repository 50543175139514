import React, { useCallback, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Colors } from '../../../../assets/js/styles'

import { makeStyles } from '@material-ui/core/styles'
import { Rating } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '20px', 
        width: 'inherit', 
        height: 'calc(inherit - 100px)',
    }, 
    topSection: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    bottomSection: {
        margin: '15px 0',
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    },
    typo: {
        color: Colors.textColor,
        margin: '10px 0'
    },
    rating: {
        margin: '0'
    },
    sourceDiv: {
        width: '100%',
        display: 'flex', 
        justifyContent: 'space-around'
    }

}))

const SourceComp = ({ classStyle, location, noOfAnswersFromLocation }) => (
    <div className={classStyle}>
        <Typography variant="subtitle2" style={{ color: Colors.textColor }}>
            {location}
        </Typography>
        <Typography>
            {noOfAnswersFromLocation}
        </Typography>
    </div>
)

const TotalReviewRating = ({ responses }) => {
    const classes = useStyles()
    const [locations, setLocations] = useState(null)
    const [averageSentiment, setAverageSentiment] = useState(0)

    const mounted = useRef(false)

    const getAverageSentiment = useCallback(() => {
        let average = 0

        for(let response of Object.values(responses)){
            for(let answer of Object.values(response?.answers)){
                if(answer.sentiment){
                    average += answer.sentiment.average
                }
            }
        }

        setAverageSentiment(average > 0 ? (average/Object.values(responses).length).toFixed(1) : 0)
    }, [responses])
    

    const getLocation = useCallback(() => {

        let listOfCountries = []
        let count = {};

        for(let response of Object.values(responses)){
            let { country } = response?.location  
            listOfCountries.push(country)
        }

        console.log(listOfCountries)
        for (const country of listOfCountries) {
            if (count[country]) {
              count[country] += 1;
            } else {
              count[country] = 1;
            }
        }

        //sort count - descending order
        count = Object.fromEntries(
            Object.entries(count).sort(([ ,a], [ ,b]) => b-a)
        );

        switch(Object.keys(count).length){
            case 0:
                count["-----"] = "--"
                count["------"] = "--"
                count["-------"] = "--"
                setLocations(count)
                break;
            case 1:
                count["-----"] = "--"
                count["------"] = "--"
                setLocations(count)
                break;
            case 2:
                count["-----"] = "--"
                setLocations(count)
                break;
            case 3:
                setLocations(count)
                break;
            default:
                //order by highest
                setLocations(count.slice(0, 3))
                break;
        }

    }, [responses])


    useEffect(() => {
        if (mounted.current === false){
            if(Object.values(responses).length > 0){
                getAverageSentiment()
                getLocation()

                mounted.current = true
            }
        }
    }, [getAverageSentiment, getLocation, responses])
   

    return (
        <div className={classes.root}>
            <div className={classes.topSection}>
                <Typography variant="subtitle1" className={classes.typo} style={{ marginTop: '20px'}}> YOUR RATING </Typography>
                <Typography variant="h3"> {averageSentiment} </Typography>
                <Rating 
                    value={parseFloat(averageSentiment)}
                    name="total rating" 
                    disabled 
                    className={classes.rating}/>
                <Typography variant="caption" style={{ color: Colors.gray}}> Learn More </Typography>
            </div>
            <div className={classes.bottomSection}>
                <Typography variant="subtitle1" className={classes.typo}> <b>Feedback Source</b> </Typography>  
                
                {(locations) && Object.keys(locations).map((item, index) => (
                    <SourceComp 
                        key={`${item}_item_${index}`}
                        classStyle={classes.sourceDiv} 
                        location={item} 
                        noOfAnswersFromLocation={locations[item]} />
                ))}
                
            </div>
        </div>
    )
}


TotalReviewRating.proptTypes = {
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default TotalReviewRating
