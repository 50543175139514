import React from 'react'
import { FaAsterisk } from 'react-icons/fa'

const AsteriskIcon = () => {
  return (
    <div className='w-6 h-6 bg-[#29C785] text-white rounded-full flex flex-shrink-0'>
      <span className='m-auto'>
        <FaAsterisk size={14} />
      </span>
    </div>
  )
}

export default AsteriskIcon