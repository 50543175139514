/* eslint-disable import/no-anonymous-default-export */
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    ACCOUNT_DELETED,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
    LOG_OUT,
    USER_GUEST,
    CLEAR_USER,
    AUTH_ERROR,
    AUTH_LOADING,
    LOG_OUT_ERROR,
    PASSWORD_RESET_EMAIL_CONFIRM,
    PASSWORD_RESET_EMAIL_SENT,
    PASSWORD_RESET_ERROR
} from '../types'

// import { encrypt } from '../../utils/cryptoWrapper'

const initialState = {
    token: null,
    isAuthenticated: false,
    loading: false,
    user: null,
    error: null,
    pwd_reset: null,
    log_Out: false
}

export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case LOGIN_SUCCESS:

            return {
                ...state,
                log_Out: false,
                user: {
                    email: payload.email, 
                    uid: payload.uid,
                },
                token: payload.token,
                isAuthenticated: true,
                loading: false,
                error: null
            }
        case REGISTER_SUCCESS: 
            
            return {
                ...state,
                log_Out: false,
                user: {
                    email: payload.email, 
                    uid: payload.uid,
                },
                token: payload.token,
                isAuthenticated: true,
                loading: false,
                error: null
            }
        case USER_LOADED:
            // localStorage.setItem('token', payload.token)

            // localStorage.setItem('user', JSON.stringify({
            //     email: payload.email, 
            //     uid: payload.uid
            // }))
            
            return {
                ...state, 
                user: {
                    email: payload.email, 
                    uid: payload.uid,
                },
                token: payload.token,
                isAuthenticated: true,
                loading: false,
                error: null
            }
        case PASSWORD_RESET_EMAIL_SENT:
            return{
                ...state, 
                pwd_reset: {
                    email: payload.email, 
                    error: null
                }
            }
        case PASSWORD_RESET_EMAIL_CONFIRM:
            return{
                ...state, 
                pwd_reset: {
                    email: payload.email, 
                    error: ''
                }

                //TODO: Fill properties of object
            }
        case PASSWORD_RESET_ERROR:
            return{
                ...state, 
                pwd_reset: null
            }
        case REGISTER_FAIL:
            return {
                user: null,
                token: null,
                isAuthenticated: false,
                loading: false,
                error: payload
            }
        case AUTH_LOADING:
            return{
                ...state, loading: true
            }
        case AUTH_ERROR:
            localStorage.removeItem('token')
            return {
                ...state,
                user: null,
                token: null,
                isAuthenticated: false,
                loading: false,
                error: payload,
            }
            // return state;
        case LOGIN_FAIL:
            return{
                ...state,
                loading: false,
                error: payload,
                isAuthenticated: false
            }
        case CLEAR_USER: 
            return initialState
        case USER_GUEST: 
            return {
                ...state,
                user: null,
                loading: false
            }
        case LOG_OUT:
            return { 
                ...initialState,
                log_Out: true
            }
        case LOG_OUT_ERROR: 
            //when error occurs, still log 
            return {
                ...state,
                error: payload
            }
        case ACCOUNT_DELETED:
            localStorage.removeItem('token');
            return {
                ...state,
                user: null,
                token: null,
                isAuthenticated: false,
                loading: false,
                error: null
            };
        default:
            return state;
    }
}