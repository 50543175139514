import React, { useState, useEffect, useCallback, useRef } from 'react'
import Layout, { Header } from '../../components/Layout'
import { Typography, Button, Grid, useMediaQuery, IconButton } from '@material-ui/core'
import PropTypes from 'prop-types'

// custom components
import MTextField from '../../components/MTextField'
import MButton from '../../components/MButton'

import avatarDefault from '../../../assets/img/avatar.png'

// redux
import { connect } from 'react-redux';

// redux actions
import { saveProfile, getProfile } from '../../redux/actions/profileAction'

import defaultPNG from '../../../assets/img/default.png' 


import { profileStyles, genericSceneStyles, Colors } from '../../../assets/js/styles'

// my icons
import avatar from '../../../assets/img/avatar.png'
import notification from '../../../assets/img/notification.png'

//utils
import { checkEmptyObject, validateEmail } from '../../utils/helpers'

import { makeStyles } from '@material-ui/core/styles'

// react icons
import * as FiIcons from "react-icons/fi"
import * as AiIcons from "react-icons/ai"
import * as MdIcons from "react-icons/md"
import * as GrIcons from "react-icons/gr"
import * as IoIcons from "react-icons/io5"
import * as HiIcons from "react-icons/hi"
import Spinner from '../../components/Spinner'
import AuthComponent from '../../components/AuthComponent'

const useStyles = makeStyles(theme => ({
    image: {
        display: 'flex', 
        justifyContent: "center", 
        flexDirection: "column", 
        alignItems: "center", 
        width: "inherit", 
        height: "inherit",
        '&:hover': {
            cursor: "pointer"
        }
    },

    //style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", width: "inherit", height: "inherit" }}
}))

const Profile = ({ saveProfile,user, profile, getProfile, loading }) => {

    const classStyles = useStyles()

    const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
    const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'))

    const classes = profileStyles()
    const commonClasses = genericSceneStyles()

    const inputRef = useRef(null)

    const [selectedFile, setSelectedFile] = useState(null)
    const [imageForDisplay, setImageForDisplay] = useState('')

    const [updateProfile, setUpdateProfile] = useState({
        name : '',
        email: '',
        companyName: '',
        phone: '',
        companySector: '',
        country: '',
        city: '',
        companySize: '',
        url: ''
    })

    const getData = useCallback(async(mUser) => {
        if (profile === null){
            await getProfile(mUser)
        }
        setUpdateProfile({
            ...profile,
        })
    }, [getProfile, profile])

    useEffect(() => {
        if(!profile){
            if(user){
                getData(user)
            }
        }else{
            setUpdateProfile({
                ...profile,
            })
        }
    }, [profile, getData, user])

    const [errors, setErrors] = useState(null)

    const onChange = useCallback((e) => {
        setUpdateProfile({
            ...updateProfile,
            [e.target.name]: e.target.value
        })

        setErrors({ ...errors,
          [e.target.name]: null
        })
    }, [errors, updateProfile])

    const triggerChange = (e) => {
        inputRef.current.click()
    }

    const onFileChange = (e) => {
        setImageForDisplay(URL.createObjectURL(e.target.files[0]))
        setSelectedFile({
            selectedFile: e.target.files[0]
        })
    }

    
    // console.log(profile)
    // console.log(updateProfile)

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();

        //File Handling
        const file = selectedFile ? selectedFile : null;

        const { emptyKeys, isEmpty } = checkEmptyObject(updateProfile)
        // console.log(emptyKeys)
        // console.log(isEmpty)
        if (isEmpty){
            // console.log(`Found empty Keys => ${emptyKeys.length}`)
            let errorObj = {}
            for (let b of emptyKeys){
                errorObj[b] = `Please include a valid ${b}`
            }
            setErrors({ 
                ...errorObj,
            })
            return
        }

        //TODO:--
        //validate phone number
        //country picker
        //city picker

        if(validateEmail(updateProfile.email) === false){
            setErrors({...errors,
                email: 'Please include a valid email'
            })
            return
        }

        updateProfile.dateCreated = profile.dateCreated

        await saveProfile(updateProfile, file)
    }, [errors, saveProfile, selectedFile, updateProfile, profile])

    const AvatarArea = useCallback(() => (
        <div>
            <input type="file" onChange={onFileChange} style={{ display: "none"}} ref={inputRef}/>
            <div className={classStyles.image} >
                <img onClick={triggerChange} 
                    src={imageForDisplay !== '' ? imageForDisplay : (updateProfile.url !== '' ? updateProfile.url : defaultPNG )} 
                    width='200px' 
                    height='200px' 
                    style={{ border: `1px solid ${Colors.primary}`, borderRadius: '100px'}} 
                    alt="profile avatar"/>
                <HiIcons.HiOutlineCamera style={{ width: '20px', height: '20px' }}/>  
            </div>
            <Typography variant="body1" className={classes.profileInfoLabels} style={{ color: Colors.black}}> {updateProfile.name}</Typography>
            <Typography variant="body1" className={classes.profileInfoLabels}> {updateProfile.companyName}</Typography>
            <Typography variant="body1" className={classes.profileInfoLabels}> <IoIcons.IoLocationOutline style={{ color: 'purple'}}/> {updateProfile.country}</Typography>
            <div className={classes.profileInfoLabels}><FiIcons.FiEdit /></div>
        </div>
    ), [classes, imageForDisplay, updateProfile, classStyles])

    const FormArea = useCallback(() => (
        <div>
            <form className={commonClasses.form} onSubmit={e => onSubmit(e)}>

                <MTextField name="name" value={updateProfile.name} label="Full Name" errors = {errors} onChange={onChange}/>

                <MTextField disabled={true} name="email" value={updateProfile.email} label="Email" errors = {errors} onChange={onChange}/>

                <MTextField name="phone" value={updateProfile.phone} label="Phone" errors = {errors} onChange={onChange}/>
                
                <MTextField name="companyName" value={updateProfile.companyName} label="Company Name" errors = {errors} onChange={onChange} />

                <MTextField name="companySector" value={updateProfile.companySector} label="Company Sector" errors = {errors} onChange={onChange}/>

                <MTextField name="companySize" value={updateProfile.companySize} label="Company Size" errors = {errors} onChange={onChange}/>

                <MTextField name="city" value={updateProfile.city} label="City" errors = {errors} onChange={onChange}/>

                <MTextField name="country" value={updateProfile.country} label="Country" errors = {errors} onChange={onChange}/>

                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start', margin: '15px 5px'}}>
                    <MButton text="Save" type="submit" value="Login" width="20%"/>
                    {/* <Button variant="outlined" className={classes.btn} type='submit' value='Login' disableElevation>
                        Save
                    </Button> */}
                </div>

            </form>
        </div>
    ), [updateProfile, commonClasses, errors, onChange, onSubmit]);


    return (
        <AuthComponent>
        <Layout >

            {loading ? (
                    <Spinner/>
                ) : (
                    <div className={commonClasses.root} style={{ color: Colors.lightDarkText }}>
                        <Header title="Profile" avatarIcon={updateProfile.url}/>
                        <div className={commonClasses.outerSection}>
                            <div className={commonClasses.innerSection}>
                                <Grid container>
                                    {
                                        isMdDown && (
                                            <>
                                                <Grid item xs={12}>
                                                    <div className={commonClasses.header}>
                                                        <Typography variant="h6"> <b>Profile Management</b></Typography>
                                                    </div>
                                                    {AvatarArea()}
                                                </Grid>
                                                <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}item xs={12}>
                                                    {FormArea()}
                                                </Grid>
                                            </>
                                        )
                                    }

                                    {
                                        isLgUp && (
                                            <>
                                                <Grid item lg={9} xs={12}>
                                                    <div className={commonClasses.header}>
                                                        <Typography variant="h6"> <b>Profile Management</b></Typography>
                                                    </div>
                                                    {FormArea()}
                                                </Grid>
                                                <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '35px' }}item lg={3} xs={12}>
                                                    {AvatarArea()}
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                            </div>
                        </div>
                    </div>
                )}
        </Layout>
        </AuthComponent>
    )
}

Profile.propTypes = {
    saveProfile : PropTypes.func.isRequired,
    getProfile: PropTypes.func.isRequired,
    user: PropTypes.object,
    error: PropTypes.object,
    profile: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    alert: PropTypes.array,
};

Profile.defaultProps = {
    profile: { },
    user: { }
}

const mapStateToProps = state => ({
    profile: state.profile.data,
    loading: state.profile.loading,
    user: state.auth.user,
    error: state.profile.error,
    alert: state.alert
});

export default connect(mapStateToProps, { saveProfile, getProfile })(Profile);