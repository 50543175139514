import React, { useEffect, useState, useCallback, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import MButton from '../../../components/MButton'
import { ButtonGroup, Divider, Grid, Button } from '@material-ui/core'
import { Rating } from '@material-ui/lab'

import { Suspense } from 'react'

import Spinner from '../../../components/Spinner'

import { Colors } from '../../../../assets/js/styles'

import constants from '../../../constants'

import { sortQuestions } from '../../../utils/helpers'

import StarBorderIcon from '@material-ui/icons/StarBorder';

import { SmileyContainer, MultiChoiceContainer, smileyIcons } from '../../../components/ResponderComponents'


const useStyles = makeStyles(theme => ({
    topSection: {
        margin: '30px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    middleSection: {
        margin: '30px 0',
    },
    cancelBtn: {
        cursor: 'pointer',
        padding: '5px 10px',
        borderRadius: '10px',
        '&:hover': {
            color: Colors.rose,
            border: '1px solid white'
        }
    }
}))


// Questions Answered Component
const QuestionComp = ({ answer, itemNumber, template }) => {

    const getDisplayType =  useCallback(() => {
        
        let returnVal = null

        const { rank, text, ratingSmiley, ratingStar, multiChoice } = constants.answerTypes

        const answerType = template.questions[answer.questionKey].answerType

        switch(answerType){
            case rank:
                returnVal = (
                    <div style={{  margin: '20px 0px', marginLeft: '20px' }}>
                        <ButtonGroup size="small" style={{ backgroundColor: template || template.theme.colors.Answers }} aria-label="outlined primary button group">
                            <Button> { answer.reply } </Button>
                        </ButtonGroup>
                    </div>
                )
                break;
            case text:
                returnVal = (
                    <div style={{  margin: '20px 0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start',  }}>
                            <Typography variant="h6">
                                {answer.reply}
                            </Typography>
                        </div>
                    </div>
                )
                break;
            case ratingSmiley:
                returnVal = (
                    <div style={{  margin: '20px 0px' }}>
                         <div style={{ display: 'flex', justifyContent: 'flex-start',  }}>
                         <Suspense fallback={<Spinner/>}>
                                <Rating
                                    disabled={true}
                                    // style={{
                                    //     color: theme.colors.Answers
                                    // }}
                                    // classes={{ 
                                    //     iconEmpty: classes.whitegray
                                    // }}
                                    value={parseInt(answer.reply, 10)}
                                    name="customized-icons"
                                    getLabelText={(value) => smileyIcons[value].label}
                                    IconContainerComponent={SmileyContainer}
                                />
                            </Suspense>
                        </div>
                    </div>
                )
                break;
            case ratingStar:
                returnVal = (
                    <div style={{ margin: "20px 0"}}>
                       <div style={{ display: 'flex', justifyContent: 'flex-start',  }}>
                            <Suspense fallback={<Spinner/>}>
                                <Rating
                                    disabled={true}
                                    size="large"
                                    // style={{
                                    //     color: theme.colors.Answers
                                    // }}
                                    // classes={{ 
                                    //     iconEmpty: classes.whitegray
                                    // }}
                                    name="customized-empty"
                                    defaultValue={parseInt(answer.reply, 10)}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                />
                            </Suspense>
                        </div>
                    </div>
                )
                break;
            case multiChoice:
                returnVal = (
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start',  }}>
                            <MultiChoiceContainer 
                                // isSelected={(answerForQuestion && answerForQuestion.reply) && item.value === answerForQuestion.reply}
                                onClick={() => {  }}
                                value={answer.reply} 
                                style={{ marginLeft: 0}}
                                color={template || template.theme.colors.Answers} /> 
                        </div>
                    </div>
                )
                break;
            default: break;
        }

        return returnVal;
    }, [answer, template])

    return (
        <div style={{ margin: '20px 0', display: 'flex'}}>
            
            <div style={{ display: "flex" }}>
                <Typography variant="h6" style={{ marginRight: '10px', width: '30px' }}> 
                    <b style={{ color: 'black'}}> {itemNumber}.</b>
                </Typography>
            </div>
            <div style={{ display: "block" }}>
                {answer && 
                    <>
                        <Typography variant="h6"> 
                            {answer.questionText}
                        </Typography>
                        {(template && template.questions !== undefined) && getDisplayType()}
                    </>
                }
            </div>
        </div>
    )
}

//Question Answer details Component, e.g device type, location... e.t.c 
const QuestionDetailsDiv = ({ label, value, style }) => {
    return (
        <div style={{ ...style, borderBottom: `1px solid lightgray`, margin: '0px 10px', borderRadius: '5px'}}>
            <Typography variant="caption"> 
                {label}
            </Typography> 
            <Typography style={{ color: 'black' }} variant="body1">
                {value}
            </Typography>
        </div>
    )
}


const ViewResponseDrawerContent =  React.forwardRef(({ response, template, onClose }, ref) => {

    const classes = useStyles()

    const [localAnswers, setLocalAnswer] = useState(null)

    const arrangeAnswers = useCallback(() => {
        let returnVal = []
        if (template){
            let sortedQuest = sortQuestions(Object.values(template.questions))
            
            for(let quest of sortedQuest){
                let answer = Object.values(response.answers).find(item => item.questionText.toLowerCase() === quest.text.toLowerCase())
                returnVal.push(answer)
            }
        }
        return returnVal
    }, [response, template])

    useEffect(() => {
        setLocalAnswer(arrangeAnswers())
    }, [arrangeAnswers])

    return (
        <Box ref={ref} style={{ color: Colors.lightDarkText, background: Colors.background }}> 
            <Container>
                <Box className={classes.topSection}>
                    <Typography variant="h5" style={{ color: Colors.black }}> <b>Response</b></Typography>
                    <div>
                        <Typography variant="body2" className={classes.cancelBtn} onClick={() => onClose(false)}> Cancel </Typography>
                    </div>
                </Box>
                <Divider/>
                <Box className={classes.middleSection}>
                    <Grid container>
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"Started time"} 
                                value={`${new Date(response.starttime).toDateString()}`} />
                        </Grid>
                        <Grid style={{ margin:'20px 0' }}  item xs={4}>
                            <QuestionDetailsDiv 
                                label={"End time"} 
                                value={`${new Date(response.starttime).toDateString()}`} />
                        </Grid>
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"Channel"} 
                                value={"LINK"} />
                        </Grid>
                        {/* <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"IP Address"} 
                                value={response.location} />
                        </Grid> */}
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"Browser"} 
                                value={response.browserType} />
                        </Grid>
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"OS"} 
                                value={response.osType} />
                        </Grid>
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"Device"} 
                                value={response.deviceType} />
                        </Grid>
                        <Grid style={{ margin:'20px 0' }} item xs={4}>
                            <QuestionDetailsDiv 
                                label={"Location"} 
                                value={response.location.country + ' ' + response.location.city} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider/>
                <Box>
                    {localAnswers && localAnswers.map((item, index) => (
                        <QuestionComp 
                            key={`itemino_${index}`} 
                            itemNumber={index+1} 
                            answer={item}
                            template={template}/>
                    ))}
                </Box>
           </Container>
        </Box>
    )
})

export default ViewResponseDrawerContent