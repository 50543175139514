import React, { useEffect, useState, useCallback, Suspense, lazy } from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery'

import CloseIcon from '@material-ui/icons/Close';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import Grow from '@material-ui/core/Grow'



import StarBorderIcon from '@material-ui/icons/StarBorder';


import PropTypes from 'prop-types'

import constants from '../../../constants'
import { invertColor, isBW } from '../../../utils/helpers';

// Components
import MButton from '../../../components/MButton'
import { SmileyContainer, MultiChoiceContainer, smileyIcons } from '../../../components/ResponderComponents'

// assets
import Logo from '../../../../assets/img/landing/logo.svg'
import Spinner from '../../../components/Spinner';

const Rating = lazy(() => import('@material-ui/lab/Rating'));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIconDiv: {
        // backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '5vh',
    },
    questionArea:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        // backgroundColor: 'blue'
    },
    bottom:{
        paddingRight: '10px',
        paddingLeft: '10px',
        height: '10vh',
        display: 'flex',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        margin: '5px',
        fontWeight: 'bold',
        background: 'transparent',
        border:'none',
        outline: 'none',
        padding: '0px',
        width: 'inherit',
        height: "inherit"
    },
    answerRoot:{
        margin: '20px 0px'
    },
    btn:{
        '&:hover': {
            border: '2px inset #fff',
            // marginRight: '30px'
        }
    },
    whitegray: {
        color: '#98AFC7'
    },
    btnGroup: {
        // width: '50px',
        height: '30px'
    },
    authlogo: {
        height: '50px', 
        padding: '10px',
    },
}))

const noTransfrom = { // for material ui buttons
    textTransform: 'none'
}

const PreviewForm = ({ open, handleClose, questions, theme, bgImage, welcomeText, goodbyeText }) => {

    const classes = useStyles()

    const { answerTypes } = constants

    const setUp = useCallback(() => {
        let returnVal = questions
        if (welcomeText && goodbyeText) {
            if (welcomeText.show === true && goodbyeText.show === true){
                returnVal = { welcomeText, ...questions, goodbyeText }
            }
    
            if (welcomeText.show === true && goodbyeText.show === false){
                returnVal =  { welcomeText, ...questions }
            }
    
            if (welcomeText.show === false && goodbyeText.show === true){
                returnVal = { ...questions, goodbyeText }
            } 
        }
        
        return returnVal
    }, [goodbyeText, welcomeText, questions])

    const [mQuestions, ] = useState(setUp())

    const defaultTheme = useSelector(state => state.theme.themes.filter((item) => item.owner === "default"))

    const [mTheme, ] = useState(theme ? theme : defaultTheme[0]) //use default theme from redux if theme prop is null/undefined

    const [questionCount, ] = useState(Object.keys(mQuestions).length || 0)

    const isXSDown = useMediaQuery(theme => theme.breakpoints.down('xs'))

    const [currentQuestion, setCurrentQuestion ] = useState({
        index: 0,
        key: Object.keys(mQuestions)[0],
        value: Object.values(mQuestions)[0],
        responderAnswer: ''
    })   // first question 
    
    const handleNext = () => {
        const lastIndex = Object.keys(mQuestions).length - 1
        if (currentQuestion.index < lastIndex){
            const nextIndex = currentQuestion.index + 1
            setCurrentQuestion({
                index: nextIndex,
                key: Object.keys(mQuestions)[nextIndex],
                value: Object.values(mQuestions)[nextIndex],
                responderAnswer: ''
            })
        }else if (currentQuestion.index === lastIndex){
            //lastIndex... need do finish

        }
    }

    const handlePrev = () => {
        const firstIndex = 0;
        if (currentQuestion.index > firstIndex){
            const prevIndex = currentQuestion.index - 1
            setCurrentQuestion({
                index: prevIndex,
                key: Object.keys(mQuestions)[prevIndex],
                value: Object.values(mQuestions)[prevIndex],
                responderAnswer: ''
            })
        }else if (currentQuestion.index === firstIndex){
            //firstIndex.... Cannot do nothing

        }
    }

    useEffect(() => {
        

        return () => {

        }
    }, [])

    const getAnswerDisplay = useCallback(() => {
        let returnVal = null;

        const { answerType, answers } = currentQuestion.value;

        const { multiChoice, text, rank, ratingSmiley, ratingStar} = answerTypes;

        switch (answerType) {
            case multiChoice: 
                returnVal = (
                    <div className={classes.answerRoot}>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width:'inherit' }}>
                            {answers.map((item, index ) => (
                                <MultiChoiceContainer key={'Multi_Ans_'+ item.value +'_' + index} value={item.value} color={mTheme.colors.Answers} /> 
                            ))}
                        </div>
                    </div>
                )
                break;
            case text: 
                returnVal = (
                    <div className={classes.answerRoot}>
                         <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <TextField
                                InputProps={{
                                    className : classes.input,
                                    style: {
                                        color: mTheme.colors.Answers
                                    },
                                    disableUnderline: true
                                }}
                                fullWidth
                                // className={classes.input}
                                id="standard-textarea"
                                // label="Multiline Placeholder"
                                placeholder="Type answer here"
                                multiline
                            />
                        </div>
                    </div>
                )
                break;
            case rank: 
                returnVal = (
                    <div className={classes.answerRoot}> 
                        <div style={{ display: 'flex', justifyContent: 'center'}}>
                            <ButtonGroup size="small" orientation={ isXSDown ? "vertical" : "horizontal" } style={{ backgroundColor: mTheme.colors.Answers }} aria-label="outlined primary button group">
                                <Button >1</Button>
                                <Button >2</Button>
                                <Button >3</Button>
                                <Button >4</Button>
                                <Button >5</Button>
                                <Button >6</Button>
                                <Button >7</Button>
                                <Button >8</Button>
                                <Button >9</Button>
                                <Button >10</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                )
                break;
            case ratingSmiley: 
                returnVal = (
                    <div className={classes.answerRoot}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Suspense fallback={<Spinner/>}>
                                <Rating
                                    style={{
                                        color: mTheme.colors.Answers
                                    }}
                                    classes={{ 
                                        iconEmpty: classes.whitegray
                                    }}
                                    name="customized-icons"
                                    defaultValue={2}
                                    getLabelText={(value) => smileyIcons[value].label}
                                    IconContainerComponent={SmileyContainer}
                                />
                            </Suspense>
                        </div>
                    </div>)
                break;
            case ratingStar: 
                returnVal = ( 
                    <div className={classes.answerRoot}> 
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Suspense fallback={<Spinner/>}>
                                <Rating
                                size="large"
                                    style={{
                                        color: mTheme.colors.Answers
                                    }}
                                    classes={{ 
                                        iconEmpty: classes.whitegray
                                    }}
                                    name="customized-empty"
                                    defaultValue={2}
                                    // precision={0.5}
                                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                                />
                            </Suspense>
                        </div>
                    </div>)
                break;
            default: break;
        }

        return returnVal;

    }, [answerTypes, currentQuestion.value, isXSDown, classes, mTheme.colors.Answers])

    return  mTheme ? (
        <Dialog 
        PaperProps={{
            style: (bgImage && bgImage !== '') ? {
                        backgroundImage: `url(${bgImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover'
                    } : {
                        backgroundColor: mTheme.colors.Background
                    }
        }}
        className={classes.app}  fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            
            <div className={classes.closeIconDiv} >
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.questionArea} >
                {
                    currentQuestion && 
                        <div>
                            <Typography style={{ color: mTheme.colors.Questions }} variant={ isXSDown ? 'h4' : 'h3'}>
                                {currentQuestion.value.text}
                                {/* {currentQuestion.value.answerType}
                                {currentQuestion.value.answers.length} */}
                            </Typography>
                            {getAnswerDisplay(currentQuestion.answerType) }
                        </div>
                }
            </div>
            <div className={classes.bottom} >
                <div style={{ height: 'inherit'}}>
                    <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
                </div>
                <ButtonGroup className={classes.btnGroup} color="primary" aria-label="outlined primary button group">
                    <Button className={classes.btn} 
                            variant="outlined" 
                            style={{ ...noTransfrom, 
                                backgroundColor: mTheme.colors.Buttons,
                                color: invertColor(mTheme.colors.Buttons, isBW(mTheme.colors.Buttons)) 
                            }}  
                            onClick={() => handlePrev()}>
                                Prev
                    </Button>
                    <Button className={classes.btn} 
                            variant="outlined" 
                            style={{ ...noTransfrom, 
                                backgroundColor: mTheme.colors.Buttons, 
                                color: invertColor(mTheme.colors.Buttons, isBW(mTheme.colors.Buttons)) 
                            }} 
                            onClick={() => handleNext()}>
                                Next
                    </Button>
                </ButtonGroup>
            </div>

        </Dialog>
    ) : (
        <Spinner/>
    )
}

PreviewForm.propTypes = {
    questions: PropTypes.object.isRequired,
    open: PropTypes.bool,
    handleClose: PropTypes.func
}

export default PreviewForm