/* eslint-disable import/no-anonymous-default-export */
import {
    SUB_MENU_OPEN,
    INDEX_CHANGED,
    SUBMENU_INDEX_CHANGED,
    GET_INITIAL_MENU
} from '../types'

// import { encrypt } from '../../utils/cryptoWrapper'

const initialState = {
    currentIndex: parseInt(localStorage.getItem('me'), 10) || -1,
    subMenu: JSON.parse(localStorage.getItem('sme')) || { index: null, open: false }
}

export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case GET_INITIAL_MENU: 
            localStorage.setItem('me', JSON.stringify(0));
            localStorage.setItem('sme', JSON.stringify({ index: null, open: false }));
            return{
                currentIndex: 0,
                subMenu: { index: null, open: false }
            }
        case SUBMENU_INDEX_CHANGED:
            localStorage.setItem('sme', JSON.stringify(payload));
            return {
                ...state,
                subMenu: payload
            }
        case INDEX_CHANGED: 
            localStorage.setItem('me', JSON.stringify( payload.currentIndex));
            return {
                ...state,
                currentIndex: payload.currentIndex
            }
        default:
            return state;
    }
}