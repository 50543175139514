import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'

import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'

import clsx from 'clsx'

import { navBar, Colors, drawerWidth } from '../../../assets/js/styles'
import { useTheme } from '@material-ui/core/styles'

// React Pro Side Bar
import { Link, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Button, IconButton, useMediaQuery } from '@material-ui/core';

import Logo from '../../../assets/img/landing/logo.svg'

import constants, { SideBarData } from '../../constants'

import { setMenuIndex, setSubMenuIndex } from "../../redux/actions/menuActions"
import { logout } from "../../redux/actions/authAction"
// import { disableNotificationListener } from '../../redux/actions/notificationAction'


const highLightedBg = (index, newIndex, sub = false) => {
    if (sub === true) { 
        return {
            background: index === newIndex ? Colors.primary: "inherit",
            // marginLeft: '35px'
        }
    }else{
        return {
            background: index === newIndex ? Colors.primary: "inherit",
        }
    }
}

const customStyles = makeStyles(theme => ({
    upgradebtn:{
        background: Colors.primary,
        borderRadius: '10px',
        // width: '50%',
        // elevation: '0',
        color: Colors.white,
        '&:hover': {
            color: Colors.primary
        }
    },
    accordionRoot: {
        background: Colors.background
    },
    drawerPaper: {
        width: drawerWidth,
        border:"none"
    },
    FooterDiv:{
        padding: '18px'
    },
    bottomText: {
        margin: '0 15px',
        marginBottom: '18px',
        color: 'rgb(162, 184, 202)'
    },
}))

const NavBar = (props) => {
    const classes = navBar();
    const customClasses = customStyles()

    const history = useHistory()
    const dispatch = useDispatch()

    const disableNotificationListener = useSelector(state => state.notification.unsubscribe)

    const { setMenuIndex, currentIndex, subMenu, setSubMenuIndex, user } = props

    const setMenu = (index) => {
        setMenuIndex(index)
        setSubMenuIndex(null, false)
    }

    const openChange = (event, open) => {
        setSubMenuIndex(null, open)
    }

    const setSubMenu = (index) => {
        setSubMenuIndex(index, subMenu.open)
        setMenuIndex(null)
    }

    const triggerLogOut = () => {

        if (disableNotificationListener){
            dispatch(disableNotificationListener())
        }
        dispatch(logout())
        // history.push(constants.Routes.Login)
    }

    const isSMUP = useMediaQuery(theme => theme.breakpoints.up('sm'))

    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    // const container = window !== undefined ? () => window().document.body : undefined;

    const drawerContent = (
        <div className={classes.drawerContent}>

            <div className={classes.logo_and_button_div}> 
                <img src={Logo} className={classes.authlogo} alt="LogoImage"/>
            </div>

            <div style={{ margin: '10px', marginTop: isSMUP && '-75px' }}>
                <List style={{ background: Colors.background }}>
                    {SideBarData.map((item, index) => (
                        item.children ? 
                            <div key={item.title + '___' +index}>
                                <Accordion 
                                    elevation={0}
                                    className={customClasses.accordionRoot} 
                                    expanded={ subMenu && subMenu.open} 
                                    onChange={(event, open) => openChange(event, open)}>
                                    <AccordionSummary
                                       
                                        expandIcon={<ExpandMoreIcon style={{ color: Colors.textColor }} />}
                                        aria-controls="panel1c-content"
                                        id="panel1c-header"
                                        >
                                            {/* <div classes={classes.accordionSumma}> */}
                                                <ListItemIcon style={{ color: Colors.textColor }}>{item.icon}</ListItemIcon>
                                                <div className={classes.accordionSumma}>
                                                    <ListItemText primary={item.title} />
                                                </div>
                                            {/* </div> */}
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        {item.children.map((child, childIndex) => (
                                            <div 
                                                className={childIndex === parseInt(subMenu.index, 10) ? clsx(classes.nav_submenu_item, classes.nav_menu_item_selected) : classes.nav_submenu_item}
                                                key={childIndex}
                                                onClick={() => setSubMenu(childIndex)}>
                                                    <Link className={classes.Link}  to={child.path}>
                                                        <ListItem>
                                                            <ListItemText primary={child.title} />
                                                        </ListItem>
                                                        {/* <Typography> {child.title}</Typography> */}
                                                    </Link>
                                            </div>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        :
                            <div 
                                className={index === currentIndex ? clsx(classes.nav_menu_item, classes.nav_menu_item_selected) : classes.nav_menu_item}
                                onClick={() => setMenu(index)} 
                                key={index}>
                                     <Link className={classes.Link } to={item.path}>
                                        <ListItem>
                                            <ListItemIcon >{item.icon}</ListItemIcon>
                                            <ListItemText primary={item.title} />
                                        </ListItem>
                                    </Link>
                            </div>
                    ))}

                </List>
            </div>
        
            <div className={classes.upgradebtnDiv}>
                <Button elevation={0} variant="contained" className={customClasses.upgradebtn}> Upgrade </Button>
            </div>

            <div className={customClasses.FooterDiv} > 
                <Typography className={customClasses.bottomText} variant="body2">Integration</Typography>
                <Typography className={customClasses.bottomText} variant="body2">Help</Typography>
                <Typography 
                    onClick={(e) => triggerLogOut(e)}
                    className={clsx(customClasses.bottomText, classes.pointer)} variant="body2">
                        Log Out
                </Typography>
            </div>
        </div>
    )

    return (
        //    <ProSidebar collapsed={false}  >
        <div className={classes.root} style={{ background: Colors.background}}>
            <nav className={classes.drawerNav} aria-label="menu_drawr">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                {/* <Hidden smUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                            {drawerContent}
                    </Drawer>
                </Hidden> */}
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: customClasses.drawerPaper,
                        }}
                        variant="permanent"
                        open >
                            {drawerContent}
                    </Drawer>
                </Hidden>
            </nav>
        </div>
    )
} 

NavBar.propTypes = {
    currentIndex : PropTypes.number,
    subMenu: PropTypes.object,
    setMenuIndex: PropTypes.func,
    setSubMenuIndex: PropTypes.func,
    user: PropTypes.object
}

const mapStateToProps = state => ({
    currentIndex: state.menu.currentIndex,
    subMenu: state.menu.subMenu,
    user: state.auth.user
});


export default connect(mapStateToProps, { setMenuIndex, setSubMenuIndex })(NavBar)