import React, { useState, useEffect, useCallback } from 'react'
import { Switch,Box, Tooltip } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'
import MTextField from '../MTextField'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // width: '100%',
        // justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    show:{
        display: 'flex'
    },
    hidden: {
        display: 'none'
    }
}))

const GreetingTextField = ({ value, name, onChange, onSetShow, show, errors, tooltipText}) => {

    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Tooltip title={tooltipText}>
                <Switch 
                    className={classes.switch}
                    checked={show}
                    color="primary"
                    onChange={onSetShow}
                    name={name}
                    // name={"Show welcome text"}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}/>
            </Tooltip>
            <MTextField 
                classes={clsx(classes.show, {
                    [classes.hidden]: show === false
                })}
                name={name}
                value={value} 
                // fullwidth={true} 
                maxLength={500} 
                label={''} 
                errors={errors} 
                onChange={onChange}/>
        </Box>
    )
}

export default GreetingTextField
