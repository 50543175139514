
//Modules to hide Nav Bar on Scroll
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide'
import crypto from 'crypto-js'
import axios from 'axios'

//handles Scroll for navBar
// interface HandleScrollProps{
//     children: React.ReactElement
// }

const HandleScroll = (props) => {
    const { children } = props
    const trigger = useScrollTrigger()

    return (
        <Slide direction='down' appear={true} in={!trigger} timeout={400}>
             {children} 
        </Slide>
    )
}

const sortResponses = (arr) => arr.sort((a, b) => a.datecreated > b.datecreated ? 1 : -1)

const sortQuestions = (arr) => arr.sort((a, b) => a.index > b.index ? 1 : -1)

const addSpace = (company) => {
    return company.replace('_', ' ')
} 

const removeSpace = (company) => {
    return company.replace(' ', '_')

} 
const buildShareLink = (link, company) => {
    return window.location.origin + "/u/" + removeSpace(company) + "/" + link
}

const generateExternalLink = (templateName, userId, profileId) => {
    return encrypt(`${userId},${templateName},${profileId}`)
}

const validateEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
    }
    // alert("You have entered an invalid email address!");
    return false;
};

const checkEmptyObject = (obj) => {
    let isEmpty = true
    let emptyKeys = []

    Object.keys(obj).filter((key) => {
        isEmpty = obj[key] !== '' && obj[key] !== null ? false : true
        if (isEmpty){
            emptyKeys.push(key)
        }
        return isEmpty
    })

    return {
        emptyKeys,
        isEmpty
    }
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
    // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    //   (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    // );
}
  
const isEmpty = (obj) => {
    for(var i in obj) return false;
    return true
}

const decrypt = (data) => {
    if (typeof(data) === "string") {
        return crypto.enc.Utf8.stringify(crypto.AES.decrypt(data,  process.env.REACT_APP_CRYPTO_SECRET, 
            {
                keySize: 128 / 8,
                iv: process.env.CRYPTO_SECRET,
                mode: crypto.mode.CBC,
                padding: crypto.pad.Pkcs7
            }));
    }
    return JSON.parse(crypto.enc.Utf8.stringify(crypto.AES.decrypt(data,  process.env.REACT_APP_CRYPTO_SECRET, 
        {
            keySize: 128 / 8,
            iv: process.env.CRYPTO_SECRET,
            mode: crypto.mode.CBC,
            padding: crypto.pad.Pkcs7
        })));
}

const encrypt = (data) => {

    let toEncrypt 

    if (typeof(data) === "object"){
        toEncrypt = JSON.stringify(data)
    }else{
        toEncrypt = data
    }

    return crypto.AES.encrypt(toEncrypt, process.env.REACT_APP_CRYPTO_SECRET,
     {
        keySize: 128 / 8,
        iv: process.env.REACT_APP_CRYPTO_SECRET,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7
      }).toString();
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

const invertColor = (hex, bw) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // https://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

const isBW = (hex) => {
    if (hex === 'black' || hex === '#000' || hex === '#000000' || hex === "white" || hex === "#fff" || hex === "#ffffff"){
        return true
    }
}

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

const shadeColor = (color, percent) => {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

const getClientIp = async () => { 
    const version = "/api/v1" 
    const apiRoute = process.env.REACT_APP_CLOUD_FUNCTION_ROUTE
    const res = await axios.get(`${apiRoute}${version}/getIp`)
    return res.data.ip
};

export { 
    addSpace,
    sortQuestions,
    generateExternalLink,
    getClientIp,
    shadeColor,
    getKeyByValue,
    isBW,
    invertColor,
    HandleScroll,
    validateEmail,
    checkEmptyObject,
    uuidv4,
    isEmpty,
    encrypt,
    decrypt,
    buildShareLink,
    sortResponses
}