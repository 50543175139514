import React, { useCallback, useState, memo, useRef } from 'react'
import { Accordion, AccordionSummary, 
         Typography, AccordionDetails, Container,
         List, ListItemText, ListItem, Popper, Fade, Paper, Divider, IconButton
    } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import * as IoIcons from "react-icons/io5"

//Prop-types
import PropTypes from 'prop-types'

import { genericSceneStyles, questionComponent } from '../../../../assets/js/styles'

import * as FaIcons from 'react-icons/fa'
import * as TiIcons from 'react-icons/ti'
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import CreateIcon from '@material-ui/icons/Create';
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore'

import constants  from '../../../constants'
import useClickOutside from '../../../hooks/useClickOutside'

import Draggable from 'react-draggable'
import MultiChoiceAnswer from './MultiChoiceAnswers'

const answerTypesLookup = [
    {
        icon: <FormatListNumberedIcon />,
        answerType: constants.answerTypes.rank, 
    },
    {
        icon: <CreateIcon/>,
        answerType: constants.answerTypes.text, 
    },
    {
        icon: <SpellcheckIcon/>,
        answerType: constants.answerTypes.multiChoice, 
    },
    {
        icon: <SentimentSatisfiedIcon/>,
        answerType: constants.answerTypes.ratingSmiley, 
    },
    {
        icon: <StarHalfIcon/>,
        answerType: constants.answerTypes.ratingStar, 
    },
]

const popperStyles = makeStyles(theme => ({
    popperList :{
        padding: '10px',
        margin: '5px 0px',
         width: '100%', 
         display: 'flex',
        '&:hover': {
            cursor: 'pointer',
            background: 'lightgreen',
        }
    },
}))


const QuestionArea = ({ name, value, onChange, onRemove, answers, questionKey, answerType, onChangeAnswer, addAnswer, setAnswerTypeForQuestion,  removeAnswer }) => {
    const commonClasses = genericSceneStyles()
    const classes = questionComponent()

    const popperClasses = popperStyles()

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState();
    const [currentAnswerType, setCurrentAnswerType] = useState(answerType);
  
    const [popperDisplay, setPopperDisplay] = useState("inherit")

    const popper = useRef()

    const closeQuestionTypeLookUp = useCallback(() => setOpen(false), [])

    useClickOutside(popper, closeQuestionTypeLookUp)

    const handlePopper = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPopperDisplay("inherit")
      setPlacement(newPlacement);
    };

    //CUSTOM STATE TO HANDLE ACCORDION TOGGLE
    const [expand, setExpand] = useState(false)
    const toggleExpand = () => {
        setExpand((prev) => !prev)
    }

    //Disable event propagation to parent for input field
    const disablePropagation = useCallback((event)=> {
        event.stopPropagation()
    }, [])


    const setIcon = useCallback((event, answerType) => {
        event.stopPropagation()
        setPopperDisplay("none")
        setOpen(false);
        if (answerType === constants.answerTypes.multiChoice){
            setExpand(true)
        }else{
            setExpand(false)
        }
        setCurrentAnswerType(answerType)
        setAnswerTypeForQuestion(answerType, questionKey)
    }, [setOpen, setCurrentAnswerType, setAnswerTypeForQuestion, questionKey])


    const getIcon = () => {
        const { rank, text, ratingStar, ratingSmiley, multiChoice } = constants.answerTypes

        switch(currentAnswerType){
            case rank : 
                return (<FormatListNumberedIcon className={classes.icon} style={{color: 'green'}} onClick={handlePopper("top")}/>)
            case text : 
                return (<CreateIcon className={classes.icon} style={{color: 'green'}} onClick={handlePopper("top")}/>)
            case ratingStar : 
                return (<StarHalfIcon className={classes.icon} style={{color: 'green'}} onClick={handlePopper("top")}/>)
            case ratingSmiley : 
                return (<SentimentSatisfiedIcon className={classes.icon} style={{color: 'green'}} onClick={handlePopper("top")}/>)
            case multiChoice : 
                return (<SpellcheckIcon className={classes.icon} style={{color: 'green'}} onClick={handlePopper("top")}/>)
            default:
                return null
        }
    }

    const getFriendlyText = (type) => {
        const { rank, text, ratingStar, ratingSmiley, multiChoice } = constants.answerTypes
        switch(type){
            case rank:
                return "Number rank (1-10)"
            case text:
                return "Text area"
            case ratingStar:
                return "Star rating"
            case ratingSmiley:
                return "Smiley rating"
            case multiChoice:
                return "Custom choice"
            default: break;
        }
    }

    return (
        <Accordion expanded={expand} className={classes.root} elevation={10}>
            <AccordionSummary style={{ width: '100%' }} 
                expandIcon={<ExpandMoreIcon />} 
                IconButtonProps={{ 
                    onClick: toggleExpand, 
                    style: { 
                        width: '20px', height: '20px',
                        outlineColor: 'lightgreen',
                        outline: 'solid',
                        color: 'lightgreen',
                        display: currentAnswerType === constants.answerTypes.multiChoice ? 'inherit' : 'none'
                    }
                }}>
                <div className={classes.inputroot}>
                    <input name={name} className={classes.input} onClick={disablePropagation} type="text" onChange={(e) => onChange(e)} value={value}/>
                    <div className={classes.iconSection}>
                        <Popper style={{ display: popperDisplay }} open={open} ref={popper} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper>
                                    {answerTypesLookup && answerTypesLookup.map((item, index) => (
                                        <Container className={popperClasses.popperList}  onClick={(e) => setIcon(e, item.answerType)} >
                                            {item.icon}
                                            <Typography style={{ marginLeft: '5px'}}>{getFriendlyText(item.answerType)}</Typography>
                                        </Container>
                                    ))}
                                </Paper>
                            </Fade>
                        )}
                        </Popper>
                        {getIcon()}
                        <TiIcons.TiDelete className={classes.icon} style={{color: 'red'}} onClick={onRemove}/>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails className={classes.dBlock}>
                { answers && answers.map((answer, index) => (
                    <MultiChoiceAnswer
                         key={'key_' + index} 
                         name={answer.name} 
                         value={answer.value} 
                         onChange={e => onChangeAnswer(e, questionKey)} 
                         onRemove={() => removeAnswer(questionKey, answer.name)} 
                     />
                ))}
                { answers.length < 4 && <div style={{ marginLeft: '10px', display: "inline-flex", alignItems: "center" }}> 
                    <IconButton color="primary" onClick={() => addAnswer(questionKey)}>
                        <IoIcons.IoAddCircleSharp/>
                    </IconButton>
                </div>}
            </AccordionDetails>
        </Accordion>
    )
}

QuestionArea.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired, 
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    answers: PropTypes.array.isRequired,
    questionKey: PropTypes.string.isRequired,
    answerType: PropTypes.string.isRequired,
    onChangeAnswer: PropTypes.func.isRequired,
    addAnswer: PropTypes.func.isRequired, 
    setAnswerTypeForQuestion: PropTypes.func.isRequired,  
    removeAnswer: PropTypes.func.isRequired
}

export default memo(QuestionArea)