import { useHistory, useLocation, Link } from 'react-router-dom'

import LogoImg from '../../../assets/img/landing/logo.svg'
import { useCallback, useState, useRef, useEffect } from 'react'

import { checkEmptyObject, validateEmail } from '../../utils/helpers'
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

import constants from '../../constants'

const WaitList = () => {

    const { push } = useHistory()

    const firstnameRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const companyNameRef = useRef()
    const phoneRef = useRef()

    const location = useLocation()

    const [showAppreciation, setShowAppreciation] = useState(false)

    const [form, setForm] = useState({
        firstname: "",
        lastname: "", 
        email: ""
    })

    const [formErrors, setFormErrors] = useState({
        firstname: "",
        lastname: "", 
        email: ""
    })

    const onChange = useCallback((e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setFormErrors({ 
            ...formErrors,
            [e.target.name]: null
        })
    }, [formErrors, form])


    const saveBetaEmail = useCallback(async () => {

        try{
            const instance = FirebaseServiceFactory.getInstance()

            const anonUser = await instance.loginAnonymous()
    
            if (anonUser){

                const alreadyExist = await instance.getOne(fsCollection.BETAUSER, { email: form.email })

                if (!alreadyExist){
                    const saveSuccess = await instance.saveOne(fsCollection.BETAUSER, form)
    
                    if (saveSuccess){

                        firstnameRef.current.value = ""
                        lastnameRef.current.value = ""
                        emailRef.current.value = ""
                        companyNameRef.current.value = ""
                        phoneRef.current.value = ""

                        alert("Sign Up Success")
                        
                        setForm({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        setFormErrors({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        await instance.logOut()

                        push(constants.Routes.JoinListBeta+ "?success=true")
                    }
                }else{
                    alert(`${alreadyExist.email} is already registered`)
                }
            }
        }catch(e){
            console.log("Save Error [Beta User] ->", e)
        }

        // check form object if email is set okaya
        // login anonymously - guest
        // save beta record to firstore as guest
        // confirm save
        // logout anonymously - guest

    }, [form])


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        const { emptyKeys, isEmpty } = checkEmptyObject(form)
        
        if (isEmpty){
            let errorObj = {}

            for (let b of emptyKeys){
                errorObj[b] = `Please include a valid ${b}`
            }

            setFormErrors({ 
                ...errorObj,
            })
            return
        }

        if(validateEmail(form.email) === false){
            setFormErrors({
                ...formErrors,
                email: 'Please include a valid email'
            })
            return
        }

        await saveBetaEmail()
    }, [form, formErrors, saveBetaEmail])

    useEffect(() => {

            const { search } = location 

            if (search.length > 0){
                const outcome = search.split("=")[1]
    
                if (outcome === "true"){
                    setShowAppreciation(true)
                }
            }
        
    }, [location])
    
    return showAppreciation ? (
        <div className='flex-1 flex items-center justify-center flex-col p-5 h-screen bg-[#16222D]'>
          <h1 className='last-h text-8xl p-5 font-semibold text-white'>Thank you</h1>
          <p className='last-p text-2xl text-white font-medium'>We will be in touch with you</p>
          <Link to={'/'} className='last-btn  bg-[#29c785] text-white max-w-md p-3 px-4 w-full rounded-lg mt-5 text-center'>Return to Listin</Link>
        </div>
    ) : (
        <section className='form flex flex-col items-center justify-center text-center gap-10 h-screen p-5 bg-[#16222D] md:resize-none'>
          <div>
              <h1 className='welcome text-2xl from-neutral-700 text-center text-white'>
                  Thank you for your interest.</h1>
                  <p className='details text-lg text-white'>Please complete the details below and we will contact you about our product.</p>
          </div>
          <form onSubmit={handleSubmit} className='flex-1 flex items-center justify-center flex-col gap-5 p-3 px-[5%] max-w-lg w-full md:resize-none border-8 border-[#29c785]'>
              <div className='logo mr-auto'>
              <a href="./" className="logo">
                      <img src={LogoImg}  alt="" />
                  </a>
              </div>
                <input ref={firstnameRef} onChange={onChange} name="firstname" type="text" placeholder="Enter your First name" className='bg-gray-200
                max-w-md p-3 px-4 w-full rounded-lg' />

                <input ref={lastnameRef} onChange={onChange} name="lastname" type="text" placeholder="Enter your Last name" className='bg-gray-200
                max-w-md p-3 px-4 w-full rounded-lg' />

                <input ref={companyNameRef} onChange={onChange} name="companyname"  type="text" placeholder="Enter your Company Name" className='bg-gray-200
                max-w-md p-3 px-4 w-full rounded-lg' />

                <input ref={emailRef} onChange={onChange} name="email" type="email" placeholder="Enter your Company E-mail" className='bg-gray-200
                max-w-md p-3 px-4 w-full rounded-lg' />

                <input ref={phoneRef} onChange={onChange} name="phonenumber" type="phone" placeholder="Enter your Phone Number" className='bg-gray-200
                max-w-md p-3 px-4 w-full rounded-lg' />
                
                <button type='submit' className='submit-btn bg-[#29c785] text-white max-w-md p-3 px-4 w-full rounded-lg mt-5 text-center'>Submit</button>
          </form>    
        </section>
    )
  }
  
  export default WaitList