import React from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import useSwitchState from '../../hooks/useSwitchState'

const MDialog = ({ open, content, fullScreen, handleClose, maxWidth, TransitionComponent, handleOpen, bgColor }) => {

    // const [open, { on, off }] = useSwitchState(open)  //use this in parent to handle state open and close
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            maxWidth={maxWidth ? maxWidth : 'md'}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={TransitionComponent}>
                <DialogContent style={{ background: bgColor}}>
                        {content}
                </DialogContent>
      </Dialog>
    )
}

export default MDialog