import React from 'react'
import {Typography} from '@material-ui/core'

const KilledView = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant="h3">
            Server Under Maintenance!!!!, We will be back soon.
        </Typography>
    </div>
  )
}

export default KilledView