import FaAsterisk from '../../components/AsteriskIcon'
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'

import { useState, useRef, useCallback} from 'react'

import { checkEmptyObject, validateEmail } from '../../utils/helpers'
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'


const PricingPage =  () => {

    const [form, setForm] = useState({
        email: "",
        firstname: "",
        lastname: "", 
        message: ""
    })

    const [formErrors, setFormErrors] = useState({
        email: "",
        firstname: "",
        lastname: "", 
        message: ""
    })

    const firstnameRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const messageRef = useRef()

    const handleChange = useCallback((e) => {
        e.preventDefault()

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setFormErrors({ 
            ...formErrors,
            [e.target.name]: null
        })
    }, [form, formErrors])


    const subscribeNewsLetter = useCallback(async () => {

        try{
            const instance = FirebaseServiceFactory.getInstance()

            const anonUser = await instance.loginAnonymous()
    
            if (anonUser){

                const alreadyExist = await instance.getOne(fsCollection.NEWSLETTER, { email: form.email })

                if (!alreadyExist){
                    const saveSuccess = await instance.saveOne(fsCollection.NEWSLETTER, form)
    
                    if (saveSuccess){

                        firstnameRef.current.value = ""
                        lastnameRef.current.value = ""
                        emailRef.current.value = ""
                        messageRef.current.value = ""

                        alert("Subscription successful")
                        
                        setForm({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        setFormErrors({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        await instance.logOut()
                    }
                }else{
                    alert(`${alreadyExist.email} is already subscribed`)
                }
            }
        }catch(e){
            console.log("Save Error [NewsLetter] ->", e)
        }

    }, [form])


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        const { emptyKeys, isEmpty } = checkEmptyObject(form)
        
        if (isEmpty){
            let errorObj = {}

            for (let b of emptyKeys){
                errorObj[b] = `Please include a valid ${b}`
            }

            setFormErrors({ 
                ...errorObj,
            })
            return
        }

        if(validateEmail(form.email) === false){
            setFormErrors({
                ...formErrors,
                email: 'Please include a valid email'
            })
            return
        }

        await subscribeNewsLetter()
    }, [form, formErrors, subscribeNewsLetter])


  return (
    <section id="how-it-works" className='bg-[#E5E5E5] p-10 px-[8%]'>
    <h1 className='text-4xl font-bold border-l-8 p-2 py-0 border-[#29C785] text-[#27292E]'>Payment Plan</h1>
    
    <div className="card-list grid grid-cols-[_repeat(auto-fit,_minmax(300px,_1fr))] gap-10 py-10">
        {/* Card 1 */}
        <div className="card h-[630px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1] hover:rounded-2xl">
            <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">1</div>
            <h3 className="font-bold">14 Days Free Trial</h3>

            <ul className="flex flex-col gap-5 mt-2">
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />                    
                    <span>Create 2 feedback forms</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />                    
                    <span>Reply 50 Customer Feedbacks</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />                    
                    <span>Capture 2 response Location Tags</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />                    
                    <span>Get 2 survey templates (NPS, CSAT)</span>
                </li>
                {/* <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />                    
                    <span>Create only 3 new response forms monthly *includes survey form and review form *review form is editable</span>
                </li> */}
            </ul>
            {/* <button className='bg-[#29C785] p-3 text-white rounded-xl px-5 mt-3'>Free Trial</button> */}

        </div>

        {/* Card 2 */}
        <div className="card h-[630px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1] hover:rounded-2xl">
        <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">2</div>
            <h3 className="font-bold">Basic N9,800</h3>

            <ul className="flex flex-col gap-5 mt-2">
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Create 10 feedback forms</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Reply 200 Feedbacks</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Capture all response location tags</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Accesss 3 Default feedback templates forms
                    </span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Create only 10 response types/forms monthly *includes survey form and review form *review form is editable</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Install listin feedback system in your buildings, websites and mobile app</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Data Analysis Report (NPS, CSAT)</span>
                </li>
            </ul>
            {/* <button className='bg-[#29C785] p-3 text-white rounded-xl px-5 mt-3 '>Subscribe</button> */}
            
        </div>

        {/* Card 3 */}
        <div className="card h-[630px] bg-white flex-1 flex flex-col items-center p-5 py-8 gap-5 transition-all duration-500 hover:scale-105 hover:shadow-lg hover:shadow-[#8bcbb1] hover:rounded-2xl">
        <div className="num bg-[#29C785] w-12 h-12 text-2xl flex items-center justify-center text-white rounded-full">3</div>
            <h3 className="font-bold">Pro N18,600</h3>

            <ul className="flex flex-col gap-5 mt-2">
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Unlimited feedback response monthly</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Unlimited reply</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Capture all response location data</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Create unlimited response types/forms</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Data Analysis Report (NPS, CSAT)</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>Sentiment Analysis Report</span>
                </li>
                <li className='flex items-start gap-3'>
                    <FaAsterisk size={14} />
                    <span>IInstall listin feedback system in your buildings, websites and mobile app</span>
                </li>
            </ul>
            {/* <button className='bg-[#29C785] p-3 text-white rounded-xl px-5 mt-3 '>Subscribe</button> */}
        </div>
    </div>

    <section id="grow-with-us" className='flex flex-col md:flex-row gap-10 p-10 px-[8%] bg-[#29c785]'>
        <div className="details max-w-sm flex flex-col flex-1 md:flex-none gap-5 p-6 ">
            <p className='text-2xl text-white font-extrabold' >
               Lets Get <br /> in  <br /> Touch</p>

            <div className="social flex gap-2">
                <a href='#' className='w-10 h-10 rounded-full bg-white text-[#29c785] inline-flex items-center justify-center'>
                    <FaFacebookF />
                </a>
                <a href='#' className='w-10 h-10 rounded-full bg-white text-[#29c785] inline-flex items-center justify-center'>
                    <FaTwitter />
                </a>
                <a href='#' className='w-10 h-10 rounded-full bg-white text-[#29c785] inline-flex items-center justify-center'>
                    <FaInstagram />
                </a>
            </div>
        </div>
        <form onSubmit={handleSubmit} className='flex-1 flex md:items-end justify-center flex-col gap-3'>
            <input ref={firstnameRef} name="firstname" onChange={handleChange} type="text" placeholder="Enter your first name" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
            <input ref={lastnameRef} name="lastname" onChange={handleChange} type="text" placeholder="Enter your last name" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
            <input ref={emailRef} name="email" onChange={handleChange} type="email" placeholder="Enter your E-mail Address" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
            <input ref={messageRef} name="message" onChange={handleChange}type="textarea"  placeholder="Type your Message" className='bg-gray-300 max-w-md p-8  w-full rounded-lg' />
            <button type="submit" className='bg-white text-[#29c785] max-w-md p-3 px-4 w-full rounded-lg mt-5'>Subscribe</button>
        </form>
    </section> 
 </section>

  )
}

export default PricingPage