class Response{
    constructor(id, bitmoji, name, email, location, deviceType, osType, browserType, timespent, starttime, endtime, completed, profileId, companyName, templateId,  answers, datecreated, dateupdated) {
        this.id = id
        this.name = name ? name : ''
        this.email = email ? email : ''
        this.bitmoji = bitmoji // could be A,B,C options or 1,2,3 options
        this.deviceType = deviceType ? deviceType : ''
        this.osType = osType ? osType : ''
        this.browserType = browserType ? browserType : ''
        this.location = location;
        this.timespent = timespent;  // the answer option text, bicycle,train,car
        this.starttime = starttime;
        this.endtime = endtime;
        this.completed = completed;
        this.companyName = companyName;
        this.profileId = profileId
        this.templateId = templateId;
        this.answers = answers;
        this.datecreated = datecreated;
        this.dateupdated = dateupdated;
    }

    toFirestore() {
        return {
            bitmoji : this.bitmoji,
            name : this.name,
            email : this.email,
            location : this.location,
            deviceType : this.deviceType,
            osType : this.osType,
            browserType : this.browserType,
            timespent : this.timespent, 
            completed : this.completed,
            starttime: this.starttime,
            endtime: this.endtime,
            companyName : this.companyName,
            profileId: this.profileId,
            templateId : this.templateId,
            answers : this.answers,
            datecreated : this.datecreated,
            dateupdated : this.dateupdated,
        };
    }

    static fromFirestore(snapshot){
        const data = snapshot.data();
        return new Response(snapshot.id, data.bitmoji, data.name, data.email, data.location, data.deviceType, data.osType, data.browserType, 
                                    data.timespent, data.starttime, data.endtime, data.completed, data.profileId, data.companyName, data.templateId, 
                                    data.answers, data.datecreated, data.dateupdated);
    }

}

export default Response;