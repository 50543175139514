import React from 'react';
import empty_response from '../../../assets/img/empty_response.svg'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root:{
        margin: '20px',
        padding: '20px',
        width: 'inherit',
        flexDirection: 'column',
        height: 'inherit',
        display: 'flex', alignItems: 'center'
    },
    img: {
        width: '70%',
        height: '70%',
    },
    typo: {
        margin: '20px'
    }
}))

const EmptyResponseCard = () => {

    const classes = useStyles()

    return (
        <div className={classes.root} >
            <img className={classes.img} src={empty_response} alt="emptyresponse"/>
            <Typography variant="body1" className={classes.typo}>
                Create and share templates you love
            </Typography>
        </div>
    )
};

export default EmptyResponseCard;
