import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import Layout, { Header } from '../../components/Layout'
import Spinner from '../../components/Spinner';
import { connect } from 'react-redux'

import clsx from 'clsx'

import { sortResponses } from '../../utils/helpers'

//redux actions
import { getDashBoardData } from '../../redux/actions/dashboardActions'
import { getResponses } from '../../redux/actions/responseActions'
import { getTemplates } from '../../redux/actions/templateAction'
import { enableNotificationListener } from '../../redux/actions/notificationAction'


import PropTypes from 'prop-types'
import { genericSceneStyles, Colors } from '../../../assets/js/styles';
import { Box, Container, Grid, Typography, Hidden, useMediaQuery } from '@material-ui/core';

import SearchBar from '../../components/SearchBar'
import MButton from '../../components/MButton';

import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles'
import { EmptyTemplateCard, ResponseCard } from '../../components/Card';
import TotalOverView from './components/TotalOverView';
import TotalReviewRating from './components/TotalReviewRating';
import MonthlySatisfactory from './components/MonthlySatisfactory';
import constants from '../../constants';
import EmptyResponseCard from '../../components/Card/EmptyResponseCard';
import AuthComponent from '../../components/AuthComponent';

// const { SentimentAnalyzer } = require('node-nlp');
// import { SentimentAnalyzer } from 'node-nlp';

import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

const useStyles = makeStyles(theme => ({
    root: {
    },
    responseFeedDiv: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '20px', 
    },
    analyticsDiv: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '20px', 
    },
    homeInnerSection: {
        borderRadius: '30px',
        width: 'inherit', 
        height: 'calc(inherit - 100px)',
        // border: `1px solid ${Colors.background}`, 
    }
}))

const Home = ({ 
    loading, profile, responses, templates, responseLoading,
    getDashBoardData, 
    getTemplates,
    getResponses,

    notifications,
    disableNotificationListener,
    enableNotificationListener
}) => {

    const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const commonClasses = genericSceneStyles()

    const classes = useStyles()

    const mounted = useRef(false)

    const [search, setSearch] = useState({
        searchTerm: '',
        applied: false
    })

    // const [notification, setNotification] = useState(null)

    const getData = useCallback(async() => {
        if (mounted.current === false){
            if (profile){
                
                //TODO: - check if data exists, else dont call again until refresh page

                // setNotification(FirebaseServiceFactory.getInstance().getNotification(fsCollection.RESPONSE, { profileId: profile.id }))
                
                const awaitActions = [
                    getDashBoardData(),
                    getResponses(profile.id),  
                    getTemplates(profile, { })
                ]

                await Promise.all(awaitActions)
                mounted.current = true
            }
        }
     }, [getDashBoardData, profile, getResponses, getTemplates])


    useEffect(() => {
        getData()
        
        // const sentiment = new SentimentAnalyzer({ language: 'en' });
        // sentiment
        //     .getSentiment('I like cats')
        //     .then(result => console.log(result));

        return async () => {

        }
    }, [getData])

    
    

    const onChange = (e) => {
        setSearch(prev => ({
            ...prev,
            searchTerm: e.target.value,
        }))
    }

    const onClearSearch = () => {
        // console.log("onClearSearch")
    }

    const onClickSearchIcon = () => {
        // console.log("onClickSearchIcon")
    }

    
    return (
        <AuthComponent>
        <Layout>
            {
               loading ? (
                    <Spinner/>
                ) : (
                    <div style={{ color: Colors.lightDarkText }} className={commonClasses.root}>
                        <Header title="Home" avatarIcon={""} showDate={true}/>
                        <div className={commonClasses.outerSection}>
                            <div className={classes.homeInnerSection}>
                                <div className={commonClasses.header}>
                                    <div style={{ width: isSmDown ? '100%' : 'inherit' }}>
                                        {profile && <Typography style={{ color: Colors.black }} variant='h6'>{profile.companyName}</Typography>}
                                        {/* <Typography variant="subtitle2"> {}</Typography> */}
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Hidden smDown> 
                                            {/* <SearchBar 
                                                customStyle={{ marginRight: '15px'}}
                                                name={"searchTerm"} 
                                                dataName={'responses'}
                                                search={search} 
                                                onChange={onChange}
                                                onClearSearch={onClearSearch}
                                                onClickSearchIcon={onClickSearchIcon}/> */}
                                        
                                            <MButton disabled={true}  text="Filter" height="40px" endIcon={<FilterListIcon/>} />
                                        </Hidden> 
                                    </div>
                                </div>
                                <div className={commonClasses.body}>
                                    <Grid container>
                                        <Grid item className={commonClasses.analyticsDiv}  xs={12} sm={12} md={12} lg={8}>
                                            <div style={{ padding: '0 10px'}}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={7} style={{ paddingRight: '5px'}} >
                                                        <TotalOverView 
                                                            responses={responses}
                                                            reviewCount={templates.filter(item => item.type === constants.formType.review).length}
                                                            surveyCount={templates.filter(item => item.type === constants.formType.survey).length}
                                                            />
                                                    </Grid>
                                                    <Grid item xs={12} sm={5} style={{ paddingLeft: '5px'}}>
                                                        <TotalReviewRating
                                                            responses={responses}/>
                                                    </Grid>
                                                </Grid>
                                                <div>
                                                    <MonthlySatisfactory/>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid className={classes.responseFeedDiv} item xs={12} sm={12} md={12} lg={4}>
                                            <div style={{ padding: '0 10px'}}>
                                                {
                                                    responseLoading ? (
                                                        <Spinner/>
                                                    ) : (
                                                        (responses && responses.length > 0) 
                                                        ?  ((responses.length > 3) ? sortResponses(responses).slice(0,3).map((item, index) => (
                                                                    <Grid key={'item__'+index} xs item>
                                                                        <ResponseCard response={item} disableButton={true}/>
                                                                    </Grid>
                                                            )) : (
                                                                [...sortResponses(responses)].map((item, index) => (
                                                                    <Grid key={'item__'+index} xs item>
                                                                        <ResponseCard response={item} disableButton={true}/>
                                                                    </Grid>
                                                                ))
                                                            ))  
                                                        : (
                                                            <EmptyResponseCard/>                                                            
                                                        )
                                                    )
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div> 
                        </div> 
                    </div>
                ) 
            }
        </Layout>
        </AuthComponent>
    )
}

Home.propTypes = {
    loading: PropTypes.bool.isRequired,
    responseLoading: PropTypes.bool.isRequired,
    getDashBoardData: PropTypes.func.isRequired,
    getResponses: PropTypes.func.isRequired,
    getTemplates: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(PropTypes.object).isRequired,
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,

    //in-app notification
    notifications: PropTypes.arrayOf(PropTypes.object),
    enableNotificationListener: PropTypes.func,
    disableNotificationListener: PropTypes.func,
}

const mapStateToProps = (state) => ({
    loading: state.dashboard.loading,
    profile: state.profile.data,
    responses: state.response.responses,
    templates: state.template.templates,
    responseLoading: state.response.loading,

    //in-app notification
    notifications: state.notification.data,
    disableNotificationListener: state.notification.unsubscribe
})

export default connect(mapStateToProps, { getDashBoardData, getResponses, getTemplates, enableNotificationListener })(Home);