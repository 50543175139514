import React, { useCallback } from 'react'

import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Colors } from '../../../../assets/js/styles'

import { makeStyles } from '@material-ui/core/styles'

import ReactSpeedometer from 'react-d3-speedometer'

const useStyles = makeStyles((theme) => ({
    root: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '20px', 
        width: 'inherit', 
        height: 'calc(inherit - 100px)',
    }, 

}))

const CountComp = ({ count, text }) => (
    <div style={{ display: 'block', marginTop: '20px'}}>
        <Typography variant='subtitle2' style={{ color: Colors.textColor}}>
        {text}
        </Typography>
        <Typography variant="h5">
            { count ? count : 0 }
        </Typography>
    </div>
)


const TotalOverView = ({ reviewCount, surveyCount, responses, satisfactoryScore }) => {
    const classes = useStyles()

    const getNormalizeSatisfaction = useCallback(() => {
        let summation = 0

        for(let response of Object.values(responses)){
            for(let answer of Object.values(response.answers)){
                if(answer.sentiment){
                    summation += answer.sentiment.score
                }
            }
        }

        const normalize = (val, max, min) => { return (val - min) / (max - min); }
       
        return normalize(summation * 100, 100, 10)
    }, [responses])

    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <CountComp count={reviewCount} text="TOTAL REVIEWS"/>
                    </Grid>
                    <Grid item xs>
                        <CountComp count={surveyCount} text="TOTAL SURVEYS"/>
                    </Grid>
                </Grid>
                <Grid container style={{ height: 'inherit', display: 'flex', justifyContent: 'center'}}>
                    {/* <Typography> Sa</Typography> */}
                    <ReactSpeedometer
                        minValue={1}
                        maxValue={100}
                        // dimensionUnit={"px"}
                        // fluidWidth={false}
                        value={getNormalizeSatisfaction()}
                        height={230}
                        // needleHeightRatio={40} 
                        needleColor={Colors.black}
                        currentValueText="Satisfaction Meter"
                        customSegmentStops={
                            [
                               1, 25, 50, 75, 100
                            ]
                        }
                        customSegmentLabels={[
                        {
                            text: "Poor",
                            position: "INSIDE",
                            color: "#555",
                            fontSize: "13px",
                        },
                        {
                            text: "Fair",
                            position: "INSIDE",
                            color: "#555",
                            fontSize: "13px",
                        },
                        {
                            text: "Average",
                            position: "INSIDE",
                            color: "#555",
                            fontSize: "13px",
                        },
                        {
                            text: "Good",
                            position: "INSIDE",
                            color: "#555",
                            fontSize: "13px",
                        },
                        // {
                        //     text: "hghv",
                        //     position: "INSIDE",
                        //     color: "#555",
                        //     fontSize: "10px",
                        // },
                        ]}
                    />
                </Grid>
            </Container>
        </div>
    )
}

TotalOverView.proptTypes = {
    reviewCount: PropTypes.number.isRequired,
    surveyCount: PropTypes.number.isRequired,
    satisfactoryMeterData: PropTypes.object.isRequired,
}

export default TotalOverView
