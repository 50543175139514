import {
    REQUEST_DASHBOARD_DATA,
    RECEIVE_DASHBOARD_DATA,
    DASHBOARD_LOADING,
    DASHBOARD_ERROR
} from '../types'

const initialState = {
    error: null,
    loading: false
}

const dashboardReducer = (state=initialState, action) => {

    const {type, payload} = action

    switch(type){
        case REQUEST_DASHBOARD_DATA: 
            return {
                ...state,
                error: null,
                loading: true,
            }
        case RECEIVE_DASHBOARD_DATA: 
            return {
                ...state,
                error: null,
                loading: false,
            }
        case DASHBOARD_LOADING: 
            return {
                ...state,
                loading: false,
            }
        case DASHBOARD_ERROR: 
            return {
                ...state,
                error: payload,
                loading: false,
            }
        default: 
            return state;
    }
}

export default dashboardReducer