/* eslint-disable import/no-anonymous-default-export */
import {
    GET_NOTIFICATION,
    SAVE_NOTIFICATION,
    UPDATE_NOTIFICATION_SEEN,

    UPDATE_NOTIFICATION_SEEN_ERROR,
    SAVE_NOTIFICATION_ERROR,
    GET_NOTIFICATION_ERROR,

    NOTIFICATION_LOADING,
} from '../types'

const initialState = {
    // unsubscribe: null, 
    data: [],
    error: null,
    loading: false
}

export default function(state = initialState, action){
    const {type, payload} = action 

    switch (type) {
        case GET_NOTIFICATION: 

            const [ _, data ] = payload

            // if (state.unsubscribe){ //&& typeof(state.unsubscribe) === "function"
            //     state.unsubscribe()
            // }

            return{
                ...state,
                // unsubscribe: unsubscribe,
                loading: false,
                data: data,
            }
        case SAVE_NOTIFICATION:
            return {
                ...state,
                loading: false,
                data: payload.notification
            }
        case UPDATE_NOTIFICATION_SEEN: 

            //This is removing the notification s a
            return {
                ...state,
                loading: false,
                data: payload //setting updated notification values
            }

        case NOTIFICATION_LOADING:

            return {
                ...state,
                loading: true,
            }
        case SAVE_NOTIFICATION_ERROR :
        case UPDATE_NOTIFICATION_SEEN_ERROR:
        case GET_NOTIFICATION_ERROR:

            return{
                ...state,
                error: payload,
                loading: false,
            }
        default:
            return state;
    }
}