import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa'
import { forwardRef, useState, useCallback, useRef } from 'react'

import { checkEmptyObject, validateEmail } from '../../utils/helpers'
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

const GrowWithUs = forwardRef((props, ref) => {

    const [form, setForm] = useState({
        email: "",
        firstname: "",
        lastname: "", 
    })

    const [formErrors, setFormErrors] = useState({
        email: "",
        firstname: "",
        lastname: "", 
    })

    const firstnameRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()

    const handleChange = useCallback((e) => {
        e.preventDefault()

        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setFormErrors({ 
            ...formErrors,
            [e.target.name]: null
        })
    }, [form, formErrors])


    const subscribeNewsLetter = useCallback(async () => {

        try{
            const instance = FirebaseServiceFactory.getInstance()

            const anonUser = await instance.loginAnonymous()
    
            if (anonUser){

                const alreadyExist = await instance.getOne(fsCollection.NEWSLETTER, { email: form.email })

                if (!alreadyExist){
                    const saveSuccess = await instance.saveOne(fsCollection.NEWSLETTER, form)
    
                    if (saveSuccess){

                        firstnameRef.current.value = ""
                        lastnameRef.current.value = ""
                        emailRef.current.value = ""

                        alert("Subscription successful")
                        
                        setForm({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        setFormErrors({
                            firstname: "",
                            lastname: "", 
                            email: ""
                        })
                        await instance.logOut()
                    }
                }else{
                    alert(`${alreadyExist.email} is already subscribed`)
                }
            }
        }catch(e){
            console.log("Save Error [NewsLetter] ->", e)
        }

    }, [form])


    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();

        const { emptyKeys, isEmpty } = checkEmptyObject(form)
        
        if (isEmpty){
            let errorObj = {}

            for (let b of emptyKeys){
                errorObj[b] = `Please include a valid ${b}`
            }

            setFormErrors({ 
                ...errorObj,
            })
            return
        }

        if(validateEmail(form.email) === false){
            setFormErrors({
                ...formErrors,
                email: 'Please include a valid email'
            })
            return
        }

        await subscribeNewsLetter()
    }, [form, formErrors, subscribeNewsLetter])


    return (
        <section ref={ref} id="grow-with-us" className='flex flex-col md:flex-row gap-10 p-10 px-[8%]'>
            <div className="details max-w-sm flex flex-col flex-1 md:flex-none gap-5">
                <h1 className='text-4xl font-bold border-l-8 p-2 py-0 border-[#29C785] text-[#27292E]'>Grow with us</h1>
                <p className='text-xl'>
                    Subscribe to Listin ™ <br className='hidden md:inline' />Get updates on new <br className='hidden md:inline' />features, releases and <br className='hidden md:inline' />other product tips and <br className='hidden md:inline' />announcements.
                </p>

                <div className="social flex gap-2">
                    <a href='#' className='w-10 h-10 rounded-full bg-[#29c785] text-white inline-flex items-center justify-center'>
                        <FaFacebookF />
                    </a>
                    <a href='#' className='w-10 h-10 rounded-full bg-[#29c785] text-white inline-flex items-center justify-center'>
                        <FaTwitter />
                    </a>
                    <a href='#' className='w-10 h-10 rounded-full bg-[#29c785] text-white inline-flex items-center justify-center'>
                        <FaInstagram />
                    </a>
                </div>
            </div>
            <form onSubmit={handleSubmit} className='flex-1 flex md:items-end justify-center flex-col gap-3'>
                <input ref={firstnameRef} name="firstname" onChange={handleChange} type="text" placeholder="Enter your first name" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
                <input ref={lastnameRef} name="lastname" onChange={handleChange}  type="text" placeholder="Enter your last name" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
                <input ref={emailRef} name="email" onChange={handleChange} type="email" placeholder="Enter your E-mail Address" className='bg-gray-300 max-w-md p-3 px-4 w-full rounded-lg' />
                <button type='submit' className='bg-[#29c785] text-white max-w-md p-3 px-4 w-full rounded-lg mt-5'>Subscribe</button>
            </form>

            {/* <Toast 
                open={((showInfo && showInfo.success) ? true : false)} 
                type={ showInfo.success ? "success" : "failure" }
                message={showInfo.message}/>  */}

        </section>
    )
})

export default GrowWithUs