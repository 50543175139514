import React, { useRef, useCallback } from 'react'
import {  GrowWithUs,
    DashboardPage,
    Header,
    HeaderStyle2,
    HowItWorks } from '../../components/Landing'
import Footer from '../../components/Landing/Footer'

const LandingPage = () => {

    const betaSignUpRef = useRef()

    const handleScroll = useCallback(() => {
        betaSignUpRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' })
    }, [])

    return (
        <div className='overflow-x-hidden'>
            {/* <Header handleScroll={handleScroll}/> */}
            <HeaderStyle2 />
            <HowItWorks />
            <DashboardPage />
            <GrowWithUs ref={betaSignUpRef} />
            <Footer/>
        </div>
    )
}

export default LandingPage
