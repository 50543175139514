import {  Popper, Fade, Paper, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Colors, drawerWidth } from '../../../assets/js/styles'
import { useSelector } from 'react-redux'

import { NotificationCard, EmptyNotificationCard } from '../../components/NotificationContainer'


const containerStyles = makeStyles(theme => ({
    root: {
        background: Colors.background,
        // width: '35%',
        // [theme.breakpoints.down('sm')]: {
        //     width: '70%'
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '100%'
        // },
        borderRadius: '20px'
    },
    title: {
        margin: '5px',
        display: 'flex',
        justifyContent: 'center'
    },
    paper: {
        border: `1px solid lightgray`,
        overflowX: 'hidden',
        overflowY: 'scroll',
        maxHeight: '90vh'
    }
}))

const NotificationContainer = ({ open, anchorEl, removeAnchorEl,  placement="bottom" }) => {

    const classes = containerStyles()

    const notifications = useSelector(state => state.notification.data)

    // console.log(notifications)

    return (
        <Popper  style={{ display: "inherit" }}  open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.paper} >
                    <div className={classes.title}>
                        You have {notifications ? notifications.length : 0} notifications
                    </div>
                    <Divider/>
                    {(notifications && notifications.length > 0)
                        ?   notifications.map((notif, index) => (
                                <NotificationCard removeAnchorEl={removeAnchorEl} key={"notif_"+ index} notification={notif}/>
                            ))
                        :   <EmptyNotificationCard/>
                    }
                </Paper>
            </Fade>
        )}
        </Popper>
    )

}


export default NotificationContainer;