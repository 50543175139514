import { firestore, 
        fireRegisterWithEmailAndPwd, 
        fireLoginWithEmailAndPwd, 
        fireLoginAnonymous, 
        auth, 
        storage, 
        saveToStorage, 
        deleteFromStorage,
        downloadUrl, 
        onAuthChanged, 
        fireLoginGoogle,
        fireLoginOut,
        fireConfirmResetPassword,
        createSnapShotListener,
        fireResetPassword, 
        remoteConfig,
        getMyRemoteConfig,
        onFetchAndActivateConfig } from './firebase'

import { GoogleAuthProvider, setPersistence,
    browserLocalPersistence,
    browserSessionPersistence, } from 'firebase/auth'

import { onSnapshot, collection, 
        addDoc, getDocs, getDoc, 
        query, where, serverTimestamp, 
        limit, orderBy, 
        deleteDoc, doc, updateDoc, Timestamp } from 'firebase/firestore'

import constants from '../constants'

//models 
import User from '../models/User'
import Profile from '../models/Profile'
import Template from '../models/Template'
import AnswerType from '../models/AnswerType'
import Question from '../models/Question'
import MTheme from '../models/MTheme'
import BetaUser from '../models/BetaUser'
import Response from '../models/Response'
import Notification from '../models/Notification'
import NewsLetter from '../models/NewsLetter'

// import Theme from '../mdoels/Theme'

// Helpers
const getDocFrom = async (ref) =>{
    const doc = await ref.get()
    const returnVal = Object.assign({ id: doc.id }, doc.data()) // Alternative to spread operator
    return returnVal
}

class FirebaseService {
    
    constructor(){
        this._db = firestore
        this._timestamp = serverTimestamp
        this._auth = auth
        this.getStorageRef = storage //get storage path function -- pass path as param
        this.saveToStorage = saveToStorage
        this.deleteFromStorage = deleteFromStorage
        this._downloadUrl = downloadUrl
        this._unsubscribeListener = null
        this._remoteConfig = remoteConfig
        this.setRemoteConfigOptions()
        onFetchAndActivateConfig()
    }

    equalTo = "=="
    lessThanEqual = "<="
    greaterThanEqual = ">="
    greaterThan = ">"
    lessThan = "<"
    notEqualTo = "!="

    handlers = {
        profile: Profile,
        response: Response,
        theme: MTheme,
        user: User,
        template: Template,
        notification: Notification,
        betauser: BetaUser,
        newsletter: NewsLetter

       ///finish up other col => class mapping

    }

    configKeys = Object.freeze({
        killSwitch: "kill_switch"
    })

    async saveStorage(path, file, uid){
        const uploadSuccess = await this.saveToStorage(this.getStorageRef(`/${path}/${uid}/${file.name}`), file)
        // console.log(uploadSuccess)
        const url = await downloadUrl(uploadSuccess.ref)
        return url
    }

    // Remote Config
    async setRemoteConfigOptions(options = { minimumFetchInterval : 44000000, defaultConfig: { kill_switch: 'false' }}){ 
        const { defaultConfig, minimumFetchInterval } = options
        remoteConfig.defaultConfig = defaultConfig
        remoteConfig.settings.minimumFetchIntervalMillis =  minimumFetchInterval //3600000; - 1hours,   //44000000 - 12 hours
    }

    getRemoteConfig(key){
        // await onFetchAndActivateConfig()
        const config = getMyRemoteConfig(key)
        return config
    }
    //End Remote Config

    async deleteStorage({ url }){
        const result = await this.deleteFromStorage(this.getStorageRef(url))
        return result
    }

    get AUTH(){
        return this._auth
    }

    get DB(){
        return this._db
    }

    get serverTimeStamp(){
        return this._timestamp()
    }

    async getCurrentUser(){
        let user
        try{
            user = await onAuthChanged()
        }catch(e){
            user = null
        }
        return user
     }
 
     async getCurrentProfile(email){
         const profile = await this.getOne(fsCollection.PROFILE, { email: email })
         return profile
     }

    async loginAnonymous(){
        let user
        try{
            user = await fireLoginAnonymous()
        }catch(e){
            user = null
        }
        return user
    }

    async logOut() {
        try{
            await fireLoginOut()
        }catch(e){
            throw e
        }
    }

    setPersistenceSession(){
        setPersistence(this._auth, browserSessionPersistence)
    }

    //This the Option By Default - wont be called unless I have to
    setPersistenceLocal(){
        setPersistence(this._auth, browserLocalPersistence)
    }

    async forgotPassword(email, authCodeSettings){
        await fireResetPassword(email, authCodeSettings)
    }

    async confirmPassword(oobCode, newPassword){
        await fireConfirmResetPassword(oobCode,newPassword)
    }

    async loginGoogle(){
        
        const result = await fireLoginGoogle()
        const credential = GoogleAuthProvider.credentialFromResult(result);

        const token = credential.accessToken;
        const user = result.user;

        //errors
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);

        const sub = constants.Sub

        let profile = await this.getOne(fsCollection.PROFILE, { email: user.email })
        if (!profile || profile === null){
            const profileData = { 
                dateCreated: this.serverTimeStamp, 
                dateUpdated: this.serverTimeStamp, 
                companyName: user.email.split("@")[0],
                email: user.email,
                plan: sub.basic 
            }
            profile = await this.saveOne(fsCollection.PROFILE, profileData)
        }

        return (credential.user === null || profile === null) ? null : { ...user, token: token, profile }
    }

    async login(email, pwd){

        const userCredentials = await fireLoginWithEmailAndPwd(email, pwd)

        let user = await this.getOne(fsCollection.USER, {email: userCredentials.user.email})

        let profile = await this.getOne(fsCollection.PROFILE, { email: email })

        return (user === null || profile === null) ? null : { ...user, token: userCredentials.user.accessToken, profile }
    }

    async register(email, pwd, fullname, companyname){

        let returnData
        try{
            const userCredentials = await fireRegisterWithEmailAndPwd(email, pwd)

            const sub = constants.Sub
    
            const userData = new User("", userCredentials.user.uid, userCredentials.user.email, fullname)
    
            let newUser = await this.saveOne(fsCollection.USER, userData.toFirestore())
    
            const profileData = { 
                dateCreated: this.serverTimeStamp, 
                dateUpdated: this.serverTimeStamp, 
                companyName: companyname,
                email: email,
                plan: sub.basic 
            }

            let newProfile = await this.saveOne(fsCollection.PROFILE, profileData)
    
            returnData = { ...newUser, token: userCredentials.user.accessToken, profile: newProfile }
        }catch(e){
            returnData = null
        }
        
        return returnData
    }

    async getNotification(type, params, filter = { limit : 10 }){
        let unsubscribe, data

        try{

            const ref = collection(this._db, type)

            // const now = new Date()
        
            // const currenDate =  Timestamp.fromDate(now)

            // const anHourAgo = new Date(now.getTime() - ( 1000 * 60 * 60)); //An hour in milliseconds

            const whereClause = where(`${Object.keys(params)[0]}`, this.equalTo, `${Object.values(params)[0]}`)

            const whereClause2 = where(`seen`, this.equalTo, false)
           
            const q = query(ref, whereClause, whereClause2, limit(filter.limit));

            const [unsub, snapData] = await createSnapShotListener(q)

            unsubscribe = unsub

            this._unsubscribeListener = unsubscribe

            if (Object.values(fsCollection).includes(type)){
                const key = type.toLowerCase()
                data = []

                for (let dat of snapData){
                    data.push(this.handlers[key].fromFirestore(dat))
                }
                // returnVal = !snapshot.empty ?  : null
            }
            // data = notifications

        }catch(e){

        }

        return [ unsubscribe, data ]
    }

    // Simple Query.... only first item in params object is used
    async getOne(type, params){
        let returnVal

        try {

            const ref = collection(this._db, type)
            const q =  query(ref, where(`${Object.keys(params)[0]}`, this.equalTo, `${Object.values(params)[0]}`))
            const snapshot = await getDocs(q)
            //await getDocs(collection(this._db, type)); //query to get all documents with where clause

            if (Object.values(fsCollection).includes(type)){
                const key = type.toLowerCase()
                returnVal = !snapshot.empty ? this.handlers[key].fromFirestore(snapshot.docs[0]) : null
            }

        }catch(e){

            returnVal = null
        }
        return returnVal
    }

    //Get One Collection Item By id
    async getOneById(type, id){
        let returnVal
        try{

            const docRef = doc(this._db, type, id);
            const snapshot = await getDoc(docRef);
            //await getDocs(collection(this._db, type)); //query to get all documents with where clause
            
            if (Object.values(fsCollection).includes(type)){
                const key = type.toLowerCase()
                returnVal = snapshot.exists() ? this.handlers[key].fromFirestore(snapshot) : null
            }

        }catch(e){
            returnVal = null
        }
        return returnVal
    }

    //Save One Collection Item
    async saveOne(type, model){
        let returnVal

        try{
            const ref = await addDoc(collection(this._db, type), model)
            const snap = await getDoc(ref)

            if (Object.values(fsCollection).includes(type)){
                const key = type.toLowerCase()
                returnVal = snap.exists() ? this.handlers[key].fromFirestore(snap) : null
            }

        }catch(e){
            returnVal = null
        }
        return returnVal;  
    }

    //Get All Themes including defaults [i.e where profileId === "default"]
    async getAllThemes(type, params){
        
        const ref = collection(this._db, type)
        let q, q2
        if (Object.keys(params).length ===  0) return;

        if (Object.keys(params).length === 1){ //simple query
            q =  query(ref, 
                where(`${Object.keys(params)[0]}`, this.equalTo, `${Object.values(params)[0]}`), 
                // Object.keys(filter).length > 0 ? orderBy(filter.date) : null,
                limit(10))

            q2 =  query(ref, 
                where(`${Object.keys(params)[0]}`, this.equalTo, `default`), 
                // Object.keys(filter).length > 0 ? orderBy(filter.date) : null,
                limit(10))
        }

        const [snapshot, snapp] = await Promise.all([getDocs(q), getDocs(q2)])

        let returnVal = []

        if (!snapshot.empty){
            for (let ab of snapshot.docs){
                returnVal.push(MTheme.fromFirestore(ab))
            }
        }
        if (!snapp.empty){
            for (let ab2 of snapp.docs){
                returnVal.push(MTheme.fromFirestore(ab2))
            }
        }

        return returnVal
    }

    //Get many items in collection
    async getMany(type, params, filter = { }){
        let returnVal = []

        try{
            const ref = collection(this._db, type)
            let q

            if (Object.keys(params).length ===  0) return;

            if (Object.keys(params).length === 1){ //simple query
                q =  query(ref, 
                    where(`${Object.keys(params)[0]}`, this.equalTo, `${Object.values(params)[0]}`), 
                    // Object.keys(filter).length > 0 ? orderBy(filter.date) : null,
                    limit(10))
            }else if (Object.keys(params).length === 2){ ///complex query
                q =  query(ref, 
                    where(`${Object.keys(params)[0]}`, this.equalTo, `${Object.values(params)[0]}`), 
                    where(`${Object.keys(params)[1]}`, this.equalTo, `${Object.values(params)[1]}`),
                    // filter ? orderBy(filter.date) : null,
                    limit(10))
            }

            const snapshot = await getDocs(q)

            if (Object.values(fsCollection).includes(type)){
                const key = type.toLowerCase()
                if (!snapshot.empty){
                    for (let ab of snapshot.docs){
                        returnVal.push(this.handlers[key].fromFirestore(ab))
                    }
                }
            }

        }catch(e){
            returnVal = []
        }
        return returnVal;
    }

    async updateById(type, id, data){
        let successRef = false
        try{
            const ref = doc(this._db, type, id)
            await updateDoc(ref, data)
            // console.log("FIREBASESERIVE.class>>> updateById", updateRet)
            successRef = true
        }catch(e){
            successRef = false
        }
        return successRef
    }

    async deleteOne(type, id){
        let successRef = false

        try {
            if ([...Object.values(fsCollection)].includes(type)){
                await deleteDoc(doc(this._db, type, id));
                successRef = true   
            }
        } catch (err) {
            successRef = false
        }
        return successRef
    }

    // async update() {
    // }   
}


export const fsCollection = {
    USER: "User",
    PROFILE: "Profile",
    TEMPLATE: "Template",
    ANSWERTYPE: "AnswerType",
    ANSWEROPTION: "AnswerOption",
    QUESTION: "Question",
    THEME: "Theme",
    RESPONSE: "Response",
    NOTIFICATION: "Notification",
    BETAUSER: "BetaUser",
    NEWSLETTER: "NewsLetter",
}

export const FirebaseServiceFactory = (() => {
    var instance;

    function createInstance () {
        var fs = new FirebaseService();
        return fs;
    }

    return {
        // Singleton Design Pattern
        getInstance: () => {
            if (!instance) {
                instance = createInstance();
            }
            return instance;
        }
    };
})();


export default FirebaseService