import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react'

//icons
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

//constants
import constants from '../../../constants'

//MUI
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

//Prop-types
import PropTypes from 'prop-types'

//genric components
import { GreenRadio } from '../../../components/Radio'
import MButton from '../../../components/MButton'

//components
import ThemeUploadedMedia from './ThemeUploadedMedia'

//ListIn Logo
import Logo from '../../../../assets/img/landing/logo.svg'


const useStyles = makeStyles(them => ({
    root:{
        margin: '10px 0px', 
        display: 'flex',
        justifyContent: 'space-between', alignItems: 'center'
    }, 
    accordion: {
        width: '100%',

    },
    radioGroup:{
        width: '100%',
    },
    dBlock:{
        display: 'block',
    },
    radioSection: {
        marginTop: '5px'
    },
    optionText: {
        
    }, 
    switch:{

    },
    label:{
        display: 'flex',
    },
    icons:{

    },
    authlogo: {
        height: '50px', 
        padding: '10px',
    },
}))

//Options with accordion styles below... Need to check which option is given to know what radio buttons to display
// --name: "Background",
// --name: "ListInBrand",
const optionNameBg = "Background"
const optionNameBrand = "ListInBrand"

const ThemeOptions = ({ option, notifyFileChange, notifySwitchChange, handleRadioChange }) => {
    const classes = useStyles()
    const inputRef = useRef()

    const { mSwitch, lookupdata, accordion } = constants.ThemeOptionTypes

    const [localOpts, setLocalOpts] = useState(option)

    const [renderer, setRenderer] = useState(null)

    const [bgImage, setBgImage] = useState({
        forDisplay: '',
        forSave: ''
    })

    // console.log(localOpts)

    //const [applyFor, setapplyFor] = useState(false);
    
    // const toggleApplyForAll = useCallback(() => {
    //     setapplyFor(prev => !prev)
    // }, [])

    const triggerUpload = useCallback(() => {
        inputRef.current.click()
    }, [])

    const onFileChange = useCallback((e) => {
        setBgImage({
            forDisplay: URL.createObjectURL(e.target.files[0]),
            forSave: e.target.files[0]
        })

        // console.log(e.target)

        notifyFileChange(e.target)
    },[notifyFileChange])

    const clearImage = useCallback(() => {
        setBgImage({
            forDisplay: '',
            forSave: ''
        })
        notifyFileChange(null) //change bg image file
    },[notifyFileChange])

    const triggerSwitchChange = useCallback((e, name) => {
        if (localOpts.name === name){
            setLocalOpts(prev => ({
                ...prev,
                value: e.target.checked
            }))
        }
        notifySwitchChange({ ...localOpts, value: e.target.checked })

    }, [localOpts, notifySwitchChange])

    //CUSTOM STATE TO HANDLE ACCORDION TOGGLE
    const [expand, setExpand] = useState((localOpts?.url !== '' || bgImage?.forDisplay !== ''))
    const toggleExpand = () => {
        setExpand((prev) => !prev)
    }
   
    const getRender = () => {

        let returnData = null

        switch(localOpts.displayType){
            case mSwitch:

                const siwtchOptions = ["ShowQuestion","IndicateMandatoryQ","AskName", "AskEmail",]

                for (let val of siwtchOptions){
                    if (localOpts.name === val){
                        // console.log(localOpts.value)
                        returnData = (
                            <>
                                <div className={classes.label}>
                                    <Typography className={classes.optionText}>{localOpts.label}</Typography>
                                    <InfoIcon/>
                                </div>
                                <Switch
                                    className={classes.switch}
                                    checked={localOpts.value}
                                    color="primary"
                                    onChange={(e) => triggerSwitchChange(e, localOpts.name)}
                                    name={localOpts.name}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                            </>
                        )
                    }
                }
                break;
            case lookupdata:
                returnData = ( 
                    <>
                        {/* <div className={classes.label}>
                            <Typography className={classes.optionText}>{optionText}</Typography>
                            <InfoIcon/>
                        </div> */}
                    </>
                )
                break;
            case accordion:

                const accordOptions = ["ListInBrand","Background",]

                for (let val of accordOptions){
                    if (localOpts.name === val){
                        returnData = ( 
                            //expanded={expand}
        
                            <Accordion expanded={expand} elevation={0}  className={classes.accordion} > 
                                <AccordionSummary style={{ width: '100%' }} 
                                    expandIcon={<ExpandMoreIcon />} 
                                    IconButtonProps={{ 
                                        onClick: toggleExpand, 
                                        style: { 
                                            width: '12px', height: '12px',
                                            outlineColor: 'lightgreen',
                                            outline: 'solid',
                                            color: 'lightgreen',
                                            display: 'inherit' 
                                        }
                                    }}>
                                    {/* <div className={classes.inputroot}> */}
                                         <Typography className={classes.optionText}>{localOpts.label}</Typography>
                                    {/* </div> */}
                                </AccordionSummary>
                                <AccordionDetails className={classes.dBlock}>
                                    { localOpts.name === optionNameBg && 
                                        <>
                                            <RadioGroup row className={classes.radioGroup} value={localOpts.value} onChange={handleRadioChange}>
                                                <FormControlLabel value="Image" control={<GreenRadio />} label="Image" />
                                                <FormControlLabel value="Video" control={<GreenRadio  disabled={true}/>} label="Video" />
                                                {/* <FormControlLabel value="Gradient" control={<GreenRadio />} label="Gradient" /> */}
                                            </RadioGroup>
                                            <div className={classes.radioSection}>
                                            {/* url */}
                                                {localOpts.value === "Image" && (
                                                    bgImage.forDisplay === ''
                                                        ? 
                                                            <>
                                                                <input type="file" ref={inputRef} name="Image" onChange={onFileChange} accept="image/*" style={{ display: "none" }}/>
                                                                <MButton onClick={triggerUpload} name="Image" text="Image" buttonStyle="outline" startIcon={<CloudUploadIcon/>}/>
                                                            </>
                                                        : 
                                                            <ThemeUploadedMedia media={bgImage.forDisplay ? bgImage.forDisplay : localOpts.url } clearImage={clearImage} />  
                                                    )
                                                }
        
                                                {localOpts.value === "Video" && <MButton name="Video" text="Video" buttonStyle="outline"/>}
                                                {/* {radioValue === "Gradient" && <MButton name="Gradient" text="Gradient" buttonStyle="outline"/>} */}
                                            </div>
                                        </>
                                    }
        
                                    { localOpts.name === optionNameBrand && 
                                        <div style={{ display: 'block'}}>
                                            <div>
                                                <img src={Logo} className={classes.authlogo} style={{ padding: 0}}alt={"ListIn Logo"}/>
                                            </div>
                                            <Typography variant="caption" style={{ color:'gray'}}> All free accounts will have the ListIn brand mark appearing on the end user survey</Typography>
                                            <RadioGroup className={classes.radioGroup} value={localOpts.value} onChange={handleRadioChange}>
                                                <FormControlLabel value="default_show_badge" control={<GreenRadio />} label="Show by default" />
                                                <FormControlLabel value="premium_remove_badge" control={<GreenRadio disabled={true}/>} label="Remove badge" />
                                            </RadioGroup>
                                            {/* <div  style={{ display: 'flex', marginTop: '30px', alignItems: 'center'}}>
                                                <Checkbox readOnly={true} name={"apply"} checked={applyFor} color="primary" style={{ margin: 0, padding: 0 }} onChange={toggleApplyForAll}/>
                                                <Typography style={{color: 'gray'}} variant="caption">Apply for all surveys </Typography>
                                            </div> */}
                                        </div>
                                    }
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                }
                
                break;
            default: break;
        }
        return returnData
    }

    const setUrlImage = useCallback(() => {

        if (localOpts){
            // console.log(localOpts)

            if (localOpts.name === optionNameBg){
                if (localOpts.url && localOpts.url !== ''){
                    setBgImage(prev => ({
                        ...prev,
                        forDisplay: localOpts.url,
                    }))

                    // console.log(localOpts.url)
                    // Open Accordion
                    setExpand(true)
                }
            }
        }
    }, [localOpts])

    useEffect(() => {
        setUrlImage()    
    }, [setUrlImage])

    return (
        <div className={classes.root}>
            {getRender()}
        </div>
    )
}

ThemeOptions.propTypes = {
    option: PropTypes.object.isRequired,
    handleSwitchChange: PropTypes.func,
    handleRadioChange: PropTypes.func.isRequired,
    notifyFileChange: PropTypes.func.isRequired,

    // themeType: PropTypes.oneOf([ 
    //     constants.ThemeOptionTypes.mSwitch,
    //     constants.ThemeOptionTypes.lookupdata,
    //     constants.ThemeOptionTypes.accordion,
    // ]).isRequired,


    // value: PropTypes.oneOfType([
    //     PropTypes.bool, 
    //     PropTypes.string
    // ]),
}

export default ThemeOptions
