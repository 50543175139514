import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import CreateNewThemeCard from './CreateNewThemeCard'

import PropTypes from 'prop-types'


import ThemeCard from './ThemeCard'

const SelectTheme = ({ createNewTheme, onSetBgForActiveTheme, setActiveTheme, editTheme, deleteTheme, onCancel }) => {

    const themes = useSelector(state => state.theme.themes)
    const activeTheme = useSelector(state => state.theme.theme)

    const setDeafaultIfNeeded = useCallback(() => {
        if (!activeTheme){
            setActiveTheme(themes.filter(item => item.isDefault === true)[0])
        }
    }, [setActiveTheme, activeTheme, themes])

    const triggerActiveTheme = useCallback((theme) => {
        setActiveTheme(theme)

        //TODO:- get themes bg img if exist, else leave null
        onSetBgForActiveTheme(null)
    }, [setActiveTheme,onSetBgForActiveTheme ])

    useEffect(() => {
        setDeafaultIfNeeded()

        return () => {
            
        }
    }, [setDeafaultIfNeeded])

    const getDefaultTheme = useCallback(() => {
        return themes.find((item, index) => item.isDefault === true)
    }, [themes])

    return (
        <div>
            <Typography variant="body2">My Themes </Typography>
            <div>
                <Grid container>
                    <Grid item xs={6}>
                        <CreateNewThemeCard createNewTheme={createNewTheme} />
                    </Grid>
                    <Grid item xs={6}>
                        <ThemeCard 
                            theme={ getDefaultTheme() } 
                            showActive={ 
                                ((activeTheme) && activeTheme.id === getDefaultTheme()?.id)} 
                            setActiveTheme={()=>triggerActiveTheme(getDefaultTheme())}
                        />
                    </Grid>
                    {themes && themes.filter(item => item.isDefault !== true).map((item, index) => (
                        <Grid key={'key_'+ index} item xs = {6}>
                            <ThemeCard 
                                deleteTheme={deleteTheme}
                                theme={item} 
                                showActive={ (activeTheme) && activeTheme.id  === item.id } 
                                setActiveTheme={()=>triggerActiveTheme(item)}
                                editTheme={editTheme} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

SelectTheme.propTypes = {
    createNewTheme: PropTypes.func,
}


export default SelectTheme