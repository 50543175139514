import { makeStyles } from '@material-ui/core/styles'
import authIllustration from '../img/authImage.png';

const Colors = {
    primary : "#29C785",
    secondary : "#fff",
    background: "#f1f4fa",

    textColor: "#779bb5",

    lightDarkText: 'rgba(0,0,0,0.6)',

    white: "#ffffff",
    black: "#000000",
    warning : "#ff9800",
    danger : "#f44336",
    success : "#4caf50",
    info : "#00acc1",
    rose : "#e91e63",
    gray : "#999999",

    menuIconColor : '#869eb6'
}

const formFieldWidth = '90ch'
const NavBarHeight = "100px";
const drawerWidth = 260;
const questionAreaWidth = '70%'
const fullWidth = '100%'



const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
};

const login_card_radius = "35px";

const sharedAuthButon = {
    border: '1px solid transparent',
    borderRadius: '17px',
    background: Colors.white,
    width: 'calc(20%)',
    color: "#ADD8E6"
}

const questionComponent = makeStyles((theme) => ({
    root:{
        width: questionAreaWidth,
        margin: '20px 0px',
        [theme.breakpoints.down("md")]:{
            width: "100%"
        },
    },
    inputroot:{
        marginRight: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        height: '40px',
        // marginLeft: '10px',
        outline: 'double',
        outlineColor: Colors.primary,
        // [theme.breakpoints.down("md")]:{
        //     width: "100%"
        // }
    },
    input: {
        margin: '5px',
        fontWeight: 'bold',
        background: 'transparent',
        border:'none',
        outline: 'none',
        padding: '0px',
        width: 'inherit',
        height: "inherit"
    },
    iconSection: {
        display: 'flex', alignItems: 'center',height: 'inherit'  
    },
    icon: {
        margin: '0px 10px',
        height: '20px',
        width: '20px', 
        '&:hover':{
            cursor: 'pointer'
        }
    },
   popperList :{
        padding: '10px',
        margin: '5px 0px',
         width: '100%', 
         display: 'flex',
        '&:hover': {
            cursor: 'pointer',
            background: 'lightgreen',
        }
    },
    multiChoiceOptionRoot:{
        width: '100%', 
        height: '50px',
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        background: Colors.background,
        margin: '20px 0px',
        marginLeft: '10px',
        border: '1px solid transparent',
        borderRadius: '10px',
        padding: '10px'
    },
    dBlock: {
        width: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        // [theme.breakpoints.down('sm')]:{
        //     display: 'block',
        //     // margin: 'auto'
        // }
    }
}))

const login_styles = {
    base: makeStyles((theme) => ({
        root: {
            background: "rgba(241, 244, 250, 1)",
            height: "100vh",
            width: '80vw',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]:{
                height: "inherit"
            },
            [theme.breakpoints.down("xs")]:{
                height: "inherit"
            }
        },
        typo:{
            color: Colors.warning
        },
        gridContainer: {
            width: '80%',
            border: `1px solid transparent`,
            borderRadius: `${login_card_radius}`,
        },
        gridItem: {
            background: Colors.secondary,
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        form:{
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                marginBottom: theme.spacing(3),
                width: '65ch',
                [theme.breakpoints.down('xs')]: {
                  width: 'calc(100% - 20px)'
                }
              }
        },
        btn:{
            width: 'calc(100% - 50px)',
            border: '1px solid transparent',
            borderRadius: '17px',
            background: Colors.primary,
            color: Colors.white
        },
        left: {
            border: `1px solid transparent`,
            background: 'rgba(250, 250, 251, 1)',
            borderRadius: `${login_card_radius} 0px 0px ${login_card_radius}`,
        },
        right: {
            border: `1px solid transparent`,
            borderRadius: `0px ${login_card_radius} ${login_card_radius} 0px `
        },
        rightInnerContainer:{
            display: 'inline-block'
        },
        leftImage: {
            height: "350px",
            width: "inherit",
            backgroundImage: `url(${authIllustration})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]:{
                height: "150px",
                width: "inherit"
            },
            [theme.breakpoints.down("md")]:{
                height: "250px",
                width: "inherit"
            }
            
        },
        logo_and_button_div:{
            display: 'flex',
            justifyContent: "space-between",
            alignItems: "center",
            margin: '0px 20px'
        },
        loginButon: {
            ...sharedAuthButon
        },
        createAccounButon: {
            ...sharedAuthButon,
            width: '150px',
            [theme.breakpoints.down("xs")]:{
                width: "100px"
            }
        },
        authlogo: {
            width: "140px",
            height: "140px",
            [theme.breakpoints.down("md")]: {
                width: "100px",
                height: "100px"
            },

        },
       
    })),

    //Components for login Here
}


const responderStyles = makeStyles((theme) => ({
    root: {
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center',
    },
    closeIconDiv: {
        // backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '5vh',
    },
    questionArea:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
        // backgroundColor: 'blue'
    },
    bottom:{
        paddingRight: '10px',
        paddingLeft: '10px',
        height: '10vh',
        display: 'flex',
        paddingBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    input: {
        margin: '5px',
        fontWeight: 'bold',
        background: 'transparent',
        border:'none',
        outline: 'none',
        padding: '0px',
        width: 'inherit',
        height: "inherit"
    },
    answerRoot:{
        margin: '20px 0px'
    },
    btn:{
        '&:hover': {
            border: '2px inset #fff',
            // marginRight: '30px'
        }
    },
    whitegray: {
        color: '#98AFC7'
    },
    logo: {
        // width: "100px",
        // height: "79px",
        // [theme.breakpoints.down("xs")]: {
        //     width: "100px",
        //     height: "63px"
        // }
    },
    btnGroup: {
        // width: '50px',
        height: '30px'
    },
    spinnerDiv: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        width: '100vw', 
        height: '100vh'
    }, 
    detailsDiv: {
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column',
        alignItems: 'center',
        margin: '200px',
        [theme.breakpoints.down('sm')]: {
            margin: '30px',
        },
    },
    avatar: {
        // display: 'flex', 
        // justifyContent: 'center', 
        marginBottom: '30px',
    },
    email_name_field: {
        marginBottom: '20px',
        border: '1px solid lightgray',
        height: '40px',
        // [theme.breakpoints.up('sm')]: {
        //     width: '80%',
        // },

        borderRadius: '10px',
        padding: '10px'
    }
}));


const container = makeStyles((theme) => ({
    root: {

    }
}));

const homeContainer = makeStyles((theme) =>({
    root:{
        backgroundColor: Colors.primaryColor
    },
}))

const navBar = makeStyles((theme) => ({
    root:{
        // flexGrow: 1,
        height: '100vh',
        background: Colors.background,
        overflow: 'hidden',
    },
    nav_menu_item:{
        background: "inherit",
        border: '1px solid transparent',
        borderRadius: '10px',
        color: Colors.textColor,
        '&:hover':{
            background: Colors.primary,
            color: Colors.white
        },
        '&:active':{
            background: Colors.primary,
            color: Colors.white
        },
        '&:focus':{
            background: Colors.primary,
            color: 'Colors.white'
        }
    },
    nav_menu_item_selected: {
        background: Colors.primary,
        color: Colors.white
    },
    nav_submenu_item: {
        // background: "inherit",
        color: Colors.textColor,
        border: '1px solid transparent',
        // height: '60px',
        width: '100%',
        borderRadius: '10px',
        '&:hover':{
            background: Colors.primary,
            color: Colors.white
        },
        '&:active':{
            background: Colors.primary,
            color: Colors.white
        },
        '&:focus':{
            background: Colors.primary,
            color: Colors.white
        }
    },
    
    NavBarLogo:{
        width: "80px",
        height: "80px"
    },
    upgradebtnDiv:{
        display: 'flex', justifyContent: 'center'
    },
    upgradebtn:{
        background: Colors.primary,
        borderRadius: '10px',
        // width: '50%',
        color: Colors.white,
        '&:hover': {
            color: Colors.primary
        }
    },
    upgradebtnOutline: {
        borderRadius: '10px',
        borderColor: Colors.primary,
        // width: '50%',
        color: Colors.primary,
        '&:hover': {
            color: Colors.primary
        }
    }, 
    logo_and_button_div:{
        display: 'flex', justifyContent: 'center'
    },
    MenuItemIcon:{
        // background: 'rgb(241, 244, 249)',
        color: Colors.white,
    },
    bottomText: {
        margin: '20px',
        color: 'rgb(162, 184, 202)'
    },
    pointer:{
        cursor: 'pointer'
    },
    FooterDiv: {
        padding: '18px',
        // paddingBottom: '10px'
    },


    //new Drawer styles
    drawerNav: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
          marginRight: '60px', //fixing nav content width issues
          border: 'none'
        },
        background: Colors.background
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        border:"none"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        background: 'rgb(241, 244, 249)'
    },
    accordionRoot: {
        background: Colors.background
    },
    accordionDetails: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '40px',
    },
    Link: {
        textDecoration: 'none',
        color: 'inherit',
    }, 
    accordionSumma: {
        color: Colors.textColor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }, 
    authlogo: {
        // height: '50px', 
        padding: '10px',
        
    },
    menuList: {
        margin: '10px',
        marginTop: '-75px',
        [theme.breakpoints.down("sm")]: {
            marginTop: '-20px'
        }
    }
}));

const genericSceneStyles = makeStyles((theme) => ({
    root: {
        width: '100%', 
        // height: '100vh',
        border: '1px solid transparent', 
        borderRadius: '40px', 
        backgroundColor: 'white'
    },
    header:{
        display:'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 40px',
        [theme.breakpoints.down('sm')]:{
            justifyContent: 'center',
        }
    },
    outerSection: {
        display: 'flex', 
        justifyContent: 'center',
        width: 'inherit', 
        padding: '20px',
        [theme.breakpoints.down('sm')]:{
            padding: '0'
        }
    },
    innerSection: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '30px', 
        width: 'inherit', 
        height: 'calc(inherit - 100px)'
    },
    form:{
        marginLeft: '30px', 
        marginRight: '30px', 
        '& .MuiTextField-root': {
            // margin: theme.spacing(1),
            // marginBottom: theme.spacing(2),
            width: '65ch',
            [theme.breakpoints.down('sm')]: {
                // width: 'calc(100% - 15x)'
                width: '80vw'
            },
            [theme.breakpoints.up("lg")]:{
                width: formFieldWidth
            }
        }
    },
    optsPrev:{
        display: 'flex',
        justifyContent: 'space-between',
        width: 'calc(70%)',
        margin: '20px 0px',
        [theme.breakpoints.down("md")]:{
            width: "100%"
        },
        '&:hover': {
            cursor: 'pointer'
        }
    },
    body: {
        marginLeft: '30px', 
        marginRight: '30px', 
        [theme.breakpoints.down("sm")]:{
            marginLeft: '0px', 
            marginRight: '0px', 
        }
    },
    outlinedFieldDiv: {
        // &:focus': {
            //    color: 'red',
            //    outlineColor: 'red'
                
            // }
            // width: '100%',
            display: 'flex',
            alignItems: 'center',
        padding: '10px',
        border: `1px solid ${Colors.background}`, 
        borderRadius: '25px',
        '&:hover': {
            borderColor: `${Colors.primary}`
        },
    },
    outlinedInputField: {
        
    },
    searchBar: {
        height: '20px',
        borderWidth: "1px",
        // minWidth: '60vw'
    },
    mDialog:{
        background: Colors.background
    },
    cursor: {
        '&:hover': {
            cursor: "pointer"
        }
    }
}))

const profileStyles = makeStyles((theme) => ({
    root: {
        width: '100%', 
        // height: '100vh',
        border: '1px solid transparent', 
        borderRadius: '40px', 
        backgroundColor: 'white'
    },
    profileTopHeader:{
        display:'flex', 
        justifyContent: 'space-between',
        margin: '10px 40px'
    },
    profileHeader: {
        marginLeft: '40px',
        margin: '10px 0px'
    },
    outerSection: {
        display: 'flex', 
        justifyContent: 'center',
        width: 'inherit', 
        padding: '20px',
    },
    innerSection: {
        border: `1px solid ${Colors.background}`, 
        borderRadius: '30px', 
        width: 'inherit', 
        height: 'calc(inherit - 100px)'
    },
    card:{
        
    },
    // formDiv:{
    //     display: 'flex', 
    //     marginLeft: '40px', 
    // },
    form:{
        marginLeft: '30px', 
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // marginBottom: theme.spacing(2),
            width: '65ch',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            [theme.breakpoints.up("lg")]:{
                width: formFieldWidth
            }
        },
    },
    topRightIcons:{
        width: '40px', height: '40px', margin: '0px 10px' 
    },
    profileInfoLabels: {
        textAlign: 'center',
        margin: '10px 0px',
        display: 'flex', justifyContent: 'center',
        color: Colors.menuIconColor
    }
}))

const ladingPageContainer = makeStyles((theme) => ({
    root: {
        marginTop: NavBarHeight,
        display: "inline-block",
    },
    gridcontainer:{
        // backgroundColor: Colors.grayColor,
    }
}));

const containerGrid = makeStyles((theme) => ({
    container: {

    },
    itemLeft:{
        // backgroundColor: "pink",
        width : "100%",
        height: "100%",
    },
    itemRight:{
        backgroundColor: "blue"
    },
    typo1:{

    }
}));

const aboutContainer = makeStyles((theme) => ({
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}))

const cardStyles = makeStyles(theme => ({
    root:{
        margin: "20px",
        display: "inline-block",
        // background: 'blue',
        border: `1px solid ${Colors.background}`,
        borderRadius: '30px',
        padding: '15px',
       
    },
    mDialog:{
        background: Colors.background
    },
    imgsection:{
        width: "100%",
    },
    textsection: {
        marginTop: '10px',
        width: "100%",
        // display: 'flex'
    },
    tagsArea: {
        display: 'flex',
    },
    buttonsection: {
        width: "100%",
        display: "flex", 
        justifyContent : "flex-end",
        alignItems : "center",
        margin: "15px 0px"
    },
    tag:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: 'rgba(113, 149, 64, 1)',
        background: 'rgba(245,248,237, 1)',
        border: '1px solid transparent',
        borderRadius: '8px',
        // padding: '0px',
        minWidth: '30px',
        height: '20px',
        marginRight: '5px'

    },
    stubImage: {
        // width: '100%',
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        }
    },
    custom_shape_divider: {
        // position: "absolute",
        bottom: "0",
        left: "0",
        // width: "inherit",
        overflow: "hidden",
        lineHeight: "0",
        transform: "rotate(180deg)",
        // width: '50%'
    },
    svg: {
        position: "relative",
        display: "block",
        width: "calc(100% + 1.3px)",
        // height: "100px",
        transform: "rotateY(180deg)",
    },
    shapeFill:{
        fill: Colors.primary//"#44393980"
    },
    popperListDiv: {
        backgroundColor: "#ffffff", 
        border: "1px solid lightgray",
        borderRadius: '4px',
        width: '150px'
    },
    popperList: {
        padding: '10px',
        margin: '5px 0px',
        width: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '&:hover': {
            cursor: 'pointer',
            background: 'lightgray',
        }
    }
}))



export {
    questionComponent,
    Colors, 
    drawerWidth,
    transition,
    container,
    login_card_radius,
    containerGrid,
    navBar,
    homeContainer,
    aboutContainer,
    ladingPageContainer,
    profileStyles,
    responderStyles,
    genericSceneStyles,
    login_styles,
    sharedAuthButon,
    authIllustration,
    cardStyles, formFieldWidth,
}