import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../../assets/js/styles';


const useStyles = makeStyles(theme => ({
    root: {
        background: Colors.background,
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '70%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
}))
             
const ViewResponseDrawer = ({ anchor, open, toggleOpen, children }) => {
    
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Drawer 
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                classes={{
                    paper: classes.root,
                }}
                anchor={anchor} 
                open={open} 
                onClose={toggleOpen}>
                    {children}
                </Drawer>
        </div>
    )
}


export default ViewResponseDrawer