
import { combineReducers } from 'redux';
import auth from './authReducer';
import profile from './profileReducer'
import alert from './alertReducer'
import menu from './menuReducer'
import template from './templateReducer'
import theme from './themeReducer'
import dashboard from './dashboardReducer'
import response from './responseReducer'
import notification from './notificationReducer'


export default combineReducers({
  auth,
  profile,
  alert,
  menu,
  template,
  theme,
  response,
  dashboard,
  notification
});