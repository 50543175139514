class User{
    constructor(id = "", uid, email, fullname) {
        this.id = id;
        this.uid = uid;
        this.email = email;
        this.fullname = fullname
    }

    toFirestore() {
        return {
            uid: this.uid,
            email : this.email,
            fullname : this.fullname, 
        }
    }

    static fromFirestore(snapshot) {
        let data = snapshot.data();
        return new User(snapshot.id, data.uid, data.email, data.fullname);
    }
}

export default User;

