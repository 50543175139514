import React, { memo } from 'react'
import Bordered from '../../../components/BorderedDiv'
import { Typography, Avatar } from '@material-ui/core'

import { genericSceneStyles, questionComponent } from '../../../../assets/js/styles'

import * as FaIcons from 'react-icons/fa'
import * as TiIcons from 'react-icons/ti'
import RemoveIcon from '@material-ui/icons/Remove';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';


import { Colors } from '../../../../assets/js/styles'

import Draggable from 'react-draggable'

const MultiChoiceAnswers = ({ label, name, value, onChange, onRemove }) => {
    const commonClasses = genericSceneStyles()
    const classes = questionComponent()
    return (
        // <Bordered>
            <div className={classes.multiChoiceOptionRoot} > 
                {/* <Avatar style={{ width:'25px', height: '25px', background: 'pink' }}>{label}</Avatar>
                 */}
                <QuestionAnswerIcon style={{ width:'25px', height: '25px', color: 'pink' }}/>
                <input name={name} className={classes.input} type="text" onChange={(e) => onChange(e)} value={value}/>
                <div className={classes.iconSection}>
                    <RemoveIcon className={classes.icon} style={{color: 'pink'}} onClick={onRemove}/>
                </div>
            </div> 
        // </Bordered>
    )
}

export default MultiChoiceAnswers