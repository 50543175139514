import React, { useCallback } from 'react'
import { Typography, TextField, useMediaQuery } from '@material-ui/core'


const MTextField = ({ name, value, onChange, label , errors, question, disabled=false, fullwidth, type="text", maxLength, onKeyDown, classes, placeholder="", ...rest }) => {
    const isLg = useMediaQuery(theme => theme.breakpoints.down("lg"))

    const setMaxLength = useCallback(() => {
        if (maxLength){
            return {
                style: {
                    background: disabled ? "lightgray": "inherit"
                },
                maxLength: maxLength && maxLength
            }
        }
        return {
            style: {
                background: disabled ? "lightgray": "inherit"
            },
        }
    }, [disabled, maxLength])

    return (
        <div style={{ margin: '30px 0', dislay:"flex", justifyContent: "flex-start", height: `${ isLg ? '72px' : 'inherit'}` }}>

            {question && <Typography variant="subtitle2" style={{ marginLeft: '10px', marginTop: '0px'}}><b>{question}</b></Typography> }

            <Typography variant="subtitle2">{label}</Typography>

            <TextField
            autoComplete="off"
                className={classes}
                inputProps={setMaxLength()}
                disabled={disabled}
                error={(errors && errors[name]) && true}
                helperText={(errors && errors[name]) && <p style={{ }}>{errors[name]}</p>}
                // id="outlined-textarea-1"
                // label={label}
                fullWidth={fullwidth}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                type={type}
                name ={name}
                value={value ? value : ''}
                onChange={e => onChange(e)}
                variant="outlined"
                {...rest}
            />
        </div>
    )
}

export default MTextField
