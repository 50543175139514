/* eslint-disable import/no-anonymous-default-export */
import {
   ADD_THEME,
   GET_THEME,
   GET_THEMES,
   EDIT_THEME,
   THEME_LOADING,
   THEME_ERROR,
   SET_ACTIVE_THEME,
   DELETE_THEME,
} from '../types'

const initialState = {
    theme: null,
    themes: [],
    loading: false,
    error: null
}

export default function(state = initialState, action){
    const { type, payload } = action 

    switch (type) {
        case ADD_THEME:
            return {
                ...state,
                error: null,
                themes: [...state.themes, payload],
                theme: payload
            };
        case GET_THEME:
            return {
                ...state,
                error: null,
                theme: payload
            };
        case GET_THEMES:
            return {
                ...state,
                error: null,
                theme: payload.theme,
                themes: payload.themes
            };
        case SET_ACTIVE_THEME:
            return{
                ...state,
                theme: payload
            }
        case THEME_LOADING:
            return {
                ...state,
                loading: payload,
            };
        case THEME_ERROR:
            return {
                ...state,
                error: payload
            };
        case DELETE_THEME:
            return {
                ...state, 
                theme: payload.theme,
                themes : payload.themes
            }
        case EDIT_THEME:

            return state
        default:
            return state;
    }
}