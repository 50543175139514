import React, { useState, useEffect, useCallback, useRef } from 'react'

import { useHistory } from "react-router-dom"

import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import Layout, { Header } from '../../components/Layout'
import { genericSceneStyles, Colors } from '../../../assets/js/styles'
import { Typography, Grid, useMediaQuery } from '@material-ui/core' 

import MButton from '../../components/MButton'

// import * as IoIcons from "react-icons/io5"

import  { TemplateCard, EmptyTemplateCard } from '../../components/Card'
// import { mergeClasses } from '@material-ui/styles'

import { getTemplates } from '../../redux/actions/templateAction'

// import useSwitchState from '../../hooks/useSwitchState'
import Spinner from '../../components/Spinner'
import SearchBar from '../../components/SearchBar'
import User from '../../models/User'
import MTheme from '../../models/MTheme'
import constants from '../../constants'
import AuthComponent from '../../components/AuthComponent'

const all = 'All'
const my = 'My Templates'

const defaultTemplate = "default"

const Template = ({ profile, templates, error, loading, alert, isAuthenticated, getTemplates, user, themes }) => {
    const commonClasses = genericSceneStyles()

    const history = useHistory()

    const [filter, setFilter] = useState(all)
    const [templateCopy, setTemplateCopy] = useState(templates)

    const isSMDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const [search, setSearch] = useState({
        searchTerm: "",
        applied: false
    })

    const fetchTemplates = useCallback(async() => {
        getTemplates(profile, { }) // filters - { }
    }, [getTemplates, profile])

    const mounted = useRef(false)

    useEffect(() => {

        if (mounted.current === false ){
            fetchTemplates()
            mounted.current = true
        }
        return () => {
            
        }
    }, [fetchTemplates])

    const onChange = useCallback((e) => {
        setSearch({
            ...search,
            [e.target.name] : e.target.value
        })
    }, [search])

    const onClickSearchIcon = useCallback((e) => {
        e.preventDefault()
        const { searchTerm } = search

        if (searchTerm.trim() !== ''){
            setTemplateCopy(templates.filter((item, index) => { 
                if(item.name.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true
                }
                for(let val of item.tag){
                    if (val.toLowerCase().includes(searchTerm.toLowerCase()) ){
                        return true
                    }
                }
                return false
            }))
            setSearch({
                ...search,
                applied: true
            })
        }
        
    }, [search, templates])


    const onClearSearch = useCallback((e) => {
        e.preventDefault()
        setTemplateCopy(templates)
        setSearch({
            ...search,
            applied: false
        })
    }, [templates, search])


    const handleFilter = useCallback((e) => {
        const filterText = e.target.innerText.trim() ?? e.target.textContent.trim()
        switch (filterText){
            case all: 
                setTemplateCopy(templates);
                setFilter(all)
                break;
            case my:
                setTemplateCopy(templates.filter(item => item.profileId !== defaultTemplate))
                setFilter(my)
                break;
            default: break;
        }
        
    }, [templates])

    const getOptions = useCallback((item) => {
        let tempActions = { 
            ...constants.templateActions
        }

        let returnData

        if(item.profileId === 'default'){
            delete tempActions.delete

            if (item.isActive === true){
                returnData = { ...tempActions }
            }else{
                let tempActions2 = { 
                    ...tempActions
                }
                delete tempActions2.copy
                returnData = { ...tempActions2 }
            }

        }else{

            if (item.isActive === true){
                returnData = { ...constants.templateActions }
            }else{
                let tempActionss = { 
                    ...constants.templateActions
                }
                delete tempActionss.copy
                returnData = { ...tempActionss }
            }
        }

        return returnData
    }, [])

    return ( 
    <AuthComponent>
    <Layout>
        {loading
            ? 
                (<Spinner/>) 
            :
                (
                    //handle error
                    <div className={commonClasses.root}>
                        <Header title="Templates" avatarIcon=""/>
                        <div className={commonClasses.outerSection}>
                            <div className={commonClasses.innerSection}>
                                <div className={commonClasses.header}>
                                    {!isSMDown && <Typography variant="h6"> <b>Use Template</b></Typography>}

                                    <SearchBar 
                                        name={"searchTerm"} 
                                        dataName={'templates'}
                                        search={search} 
                                        onChange={onChange}
                                        onClearSearch={onClearSearch}
                                        onClickSearchIcon={onClickSearchIcon} />
                                </div>
                                <div className={commonClasses.body}>
                                    {!isSMDown && 
                                        <Typography variant="subtitle1" 
                                            style={{ margin: "0 10px", color: Colors.gray }}> 
                                            { filter === all ? 'All Templates' : my }
                                        </Typography>}
                                    <div style={{ margin: "0px 0" }}>
                                        <MButton onClick={handleFilter} text={all} width="60px" marginX="10px"/>
                                        <MButton onClick={handleFilter} text={my} width="140px" marginX="10px" buttonStyle="outline"/>
                                    </div>
                                    <div >
                                        <Grid container >
                                            {
                                                (search && search.applied)  
                                                    ?   <>
                                                            {
                                                                templateCopy.map((tem, index) => (
                                                                    <Grid key={'index_' + index} item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                        <TemplateCard
                                                                            options={getOptions(tem)}
                                                                            isDefault={tem.profileId === "default"} 
                                                                            key={'index_' + index}
                                                                            template={tem}
                                                                            user={user}
                                                                            templates={templates}
                                                                            themes={themes}
                                                                            />
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </>
                                                    : 
                                                    <>
                                                    { filter === all 
                                                    ?
                                                        templates.length > 0 && templates.map((tem, index) => (
                                                                <Grid key={'index_' + index} item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <TemplateCard 
                                                                        options={getOptions(tem)}
                                                                        isDefault={tem.profileId === "default"}
                                                                        key={'index_' + index}
                                                                        template={tem}
                                                                        user={user}
                                                                        templates={templates}
                                                                        themes={themes}
                                                                        />
                                                                </Grid>
                                                        )) 
                                                    : templates.filter(item => item.profileId !== defaultTemplate).length > 0 &&
                                                            templates.filter(item => item.profileId !== defaultTemplate).map((tem, index) => (
                                                                <Grid key={'index_' + index} item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <TemplateCard 
                                                                        options={getOptions(tem)}
                                                                        isDefault={tem.profileId === "default"}
                                                                        key={'index_' + index}
                                                                        template={tem}
                                                                        user={user}
                                                                        templates={templates}
                                                                        themes={themes}
                                                                        />
                                                                </Grid>
                                                            )) 
                                                        }
        
                                                        {  filter === all ? templates.length === 0 && 
                                                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                                            <EmptyTemplateCard/>
                                                                        </Grid>
                                                                    : 
                                                                    templates.filter(item => item.profileId !== defaultTemplate).length === 0 && 
                                                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                                                            <EmptyTemplateCard/>
                                                                        </Grid>
                                                        }
                                                    </>
                                            }
                                                
                                        </Grid>
                                        {/* Collection of templates created */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </Layout>
       </AuthComponent>
    )
}

Template.propTypes = {
    templates: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.object,
    loading: PropTypes.bool,
    alert: PropTypes.array,
    profile: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    getTemplates: PropTypes.func,
    user: PropTypes.object,
    themes: PropTypes.arrayOf(PropTypes.instanceOf(MTheme)),
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    templates: state.template.templates,
    error: state.template.error,
    loading: state.template.loading,
    profile: state.profile.data,
    alert: state.alert,
    user: state.auth.user,
    themes: state.theme.themes,
});

export default connect(mapStateToProps, { getTemplates })(Template)
