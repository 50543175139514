import axios from 'axios';
import { setAlert } from './alertActions';
import { setInitialProfile } from './profileAction'
import { getInitialMenu } from './menuActions'

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOG_OUT,
  CLEAR_USER, 
  AUTH_LOADING,
  LOG_OUT_ERROR,
  USER_GUEST,
  PASSWORD_RESET_EMAIL_SENT,
  PASSWORD_RESET_ERROR
} from '../types';

//firebase
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

const version = "/api/v1" 


const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`; //x-auth-token
  } else {
    delete axios.defaults.headers.common['authorization'];
  }
};


const setDispatchError = (dispatch, error, type ) => {
  
    if (typeof error.response == "undefined" || error.response === undefined){
        dispatch({
            type: type,
            payload: {
                msg: 'Service Unavailable',
                status: 503
            }
        })
    }else if (error.response.data){
        dispatch({
            type: type,
            payload: error.response.data
        })
    }else{
        dispatch({
            type: type,
            payload: {
                msg: error.response.statusText,
                status: error.response.status
            }
        })
    }
}

export const forgotPassword = (email) => async dispatch => {
    try{
        const instance = FirebaseServiceFactory.getInstance()

        // const myUrl = process.env.REACT_APP_MY_DOMAIN

        // // const actionCodeSettings = {
        // //     url: `${myUrl}/forgot_password?email=${email}`,
        // // }

        await instance.forgotPassword(email, null)

        dispatch({
            type: PASSWORD_RESET_EMAIL_SENT,
            payload: email
        })

        dispatch(setAlert("Password Reset Email Sent", "info"))

    }catch(error){
        dispatch({
            type: PASSWORD_RESET_ERROR,
            payload: error
        })
    }
}

export  const loadUser = (data) => dispatch =>
    new Promise( async (resolve, reject) => {
        // if (localStorage.token){
        //     setAuthToken(localStorage.token)
        // }

        try{
            const instance = FirebaseServiceFactory.getInstance()

            const auth = instance.AUTH

            let currentUser = null

            if (data){
                currentUser = auth.currentUser ? auth.currentUser : await instance.getCurrentUser()
                const { email, uid, accessToken } = currentUser

                let profile = await instance.getOne(fsCollection.PROFILE, { email: email })

                //loadConfig - account type on profile will be used to fetch config for user 


                if (data.profile){ //Optimize profile
                    dispatch(setInitialProfile(data.profile))
                }

                if (data.uid === uid && data.email === email &&  currentUser.accessToken === accessToken){
                    dispatch({
                        type: USER_LOADED,
                        payload: data
                    });
                    resolve(data)
                } else {
                    dispatch({
                        type: AUTH_ERROR,
                        payload: null
                    });
                    reject("Wrong User Credentials")
                }
            }else{

                // const res = await axios.get(`${version}/auth`) // OPTIMIZE:- Maybe fetch profile in this call as well to reduced calls
                //console.log(res)
                currentUser = await instance.getCurrentUser()

                if (currentUser === null){
                    dispatch({
                        type: USER_GUEST,
                        payload: null
                    })
                    resolve({ "isGuest": true })
                }else{
                    const returnData = { 
                        email: currentUser.email,
                        uid: currentUser.uid, 
                        token: currentUser.accessToken 
                    }
                    let profile = await instance.getOne(fsCollection.PROFILE, { email: currentUser.email }) 
    
                    //loadConfig - account type on profile will be used to fetch config for user 
    
                    if (profile){ //optimize profile
                        dispatch(setInitialProfile(profile))
                    }
    
                    dispatch({
                        type: USER_LOADED,
                        payload: returnData
                    });
                    resolve(returnData)
                }
            }

        }catch(error){
            dispatch({
                type: AUTH_ERROR,
                payload: error
            })
            reject(error)
        }
    })
       

export const login = (formData, rememberMe) => async dispatch => {

    // const config = {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // }

    try{
        const instance = FirebaseServiceFactory.getInstance()

        const { email, password } = formData

        const data = await instance.login(email, password)

        if (rememberMe === false){
            instance.setPersistenceSession()
        }

        dispatch(loadUser(data))

        delete data.profile

        // dispatch(getInitialMenu())
        // dispatch(setInitialProfile(data.profile))

        dispatch({
            type: LOGIN_SUCCESS, 
            payload: data
        })

        dispatch(setAlert("Login Success", "info"))

    }catch(error){     
        dispatch({
            type: LOGIN_FAIL,
            payload: error //error payload for duplicate email, duplicate company name
        })
    }
}

export const loginWithGoogle = () => async dispatch => {
    try{
        dispatch({
            type: AUTH_LOADING, 
        })
        
        const instance = FirebaseServiceFactory.getInstance()
        const data = await instance.loginGoogle()

        dispatch(loadUser(data))

        delete data.profile

        // dispatch(getInitialMenu())
        // dispatch(setInitialProfile(data.profile))

        dispatch({
            type: LOGIN_SUCCESS, 
            payload: data
        })

        dispatch(setAlert("Login Success", "info"))

    }catch(error){
        dispatch({
            type: LOGIN_FAIL,
            payload: error //error payload for duplicate email, duplicate company name
        })
    }
}

export const loginWithFb = () => dispatch => {
    try{
        dispatch({
            type: AUTH_LOADING, 
        })
        
        const instance = FirebaseServiceFactory.getInstance()

    

    }catch(error){
        dispatch({
            type: LOGIN_FAIL,
            payload: error //error payload for duplicate email, duplicate company name
        })
    }
}


export const register = (formData)=> async dispatch => {

    try{

        dispatch({
            type: AUTH_LOADING, 
        })
        
        const instance = FirebaseServiceFactory.getInstance()

        const { email, password, fullname, companyname } = formData

        const data = await instance.register(email, password, fullname, companyname)
        dispatch(loadUser(data))

        delete data.profile

        dispatch({
            type: REGISTER_SUCCESS, 
            payload: data
        })
        dispatch(setAlert("Sign Up Complete", "info"))

    }catch(error){
        dispatch({
            type: REGISTER_FAIL,
            payload: error //error payload for duplicate email, duplicate company name
        })
    }
}

export const logout = () => async dispatch => {
    try{
        const instance = FirebaseServiceFactory.getInstance()

        await instance.logOut()

        // dispatch({ type: CLEAR_USER });
        dispatch({ type: LOG_OUT });
    }catch(e){
        dispatch({ type: LOG_OUT_ERROR });
    }
};