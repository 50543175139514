import React, { useEffect, useCallback, useRef } from 'react'
import NavBar from '../NavBar'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'

import { useHistory, Redirect } from 'react-router-dom'

import {FirebaseServiceFactory, fsCollection} from '../../utils/FirebaseService'
import { onSnapshot, collection, 
    query, where, 
    limit } from 'firebase/firestore'

import {SideBarData} from '../../constants'

import {pushNotificationToGlobalState} from '../../redux/actions/notificationAction'

const Layout = (props) => {
    const isSMDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const { push } = useHistory()

    const mounted = useRef(false)

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const currentIndex = useSelector(state => state.menu.currentIndex)
    const subMenu = useSelector(state => state.menu.subMenu)
    const user = useSelector(state => state.auth.user)

    const profile = useSelector(state => state.profile.data)

    const customNavigator = useCallback(() => {
        if(isAuthenticated){
            if (subMenu.open && subMenu.index !== null) { 
                // createNewForm index == 1: has children
                 const route = SideBarData[1].children[subMenu.index].path
                 push(route)
            }else{
                 const route = SideBarData[currentIndex]
                 push(route)
            }
        }
    }, [currentIndex, subMenu, push, isAuthenticated])

    const dispatch = useDispatch()

    const triggerNotif = useCallback(() => {

        if (!profile){
            return null
        }

        const instance = FirebaseServiceFactory.getInstance()

        const ref = collection(instance._db, fsCollection.NOTIFICATION)

        const whereClause = where(`profileId`, instance.equalTo, profile.id)

        const whereClause2 = where(`seen`, instance.equalTo, false)
    
        const q = query(ref, whereClause, whereClause2, limit(10));

        let unsubscribe = onSnapshot(q, (snap) => {
            let snapData = []
            const key = fsCollection.NOTIFICATION.toLowerCase()

            snap.forEach((doc) => {
                snapData.push(instance.handlers[key].fromFirestore(doc))
            })

            //sort by date descending
            snapData = snapData.sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate())

            //call action to store values
            dispatch(pushNotificationToGlobalState(snapData)).catch((err) => { console.log(err)})
        
        }, (error) => {
            // console.log("M0Error -> ", error)
        })

        return unsubscribe

    }, [profile, dispatch])

    useEffect(() => {

        let unsubscribe

        if (mounted.current === false){
            setTimeout(() => {
                // setmProfile(profile)
                unsubscribe = triggerNotif()
                mounted.current = true
            }, 3000)
        }

        return () => {
            if(unsubscribe && typeof(unsubscribe === 'function')){
                unsubscribe()
                mounted.current = false
            }
        }
    }, [triggerNotif, profile]);

    return (
        <div style={{display: 'flex', overflowX: 'hidden' }}>
            {
                !isSMDown && <NavBar style={{flex: '1 auto'}}/>
            }
           {props.children}
        </div>
    )
}

export default Layout;