import shareemail from '../../assets/img/shareemail.png'
import sharelink from '../../assets/img/sharelink.png'
import sharelink2 from '../../assets/img/sharelink2.png'
import shareqrcode from '../../assets/img/shareqrcode.png'

//Mui icons
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StopIcon from '@material-ui/icons/Stop';

// react icons
import * as FiIcons from "react-icons/fi"
import * as AiIcons from "react-icons/ai"
import * as MdIcons from "react-icons/md"

import { IconButton } from '@material-ui/core'

import { Colors } from '../../assets/js/styles'

import homeIcon from '../../assets/img/dashboardIcon.png'
import iconCreateForm from '../../assets/img/iconCreateForm.png'
const iconColor = '#869eb6'


const sideBarIcon = {
    // color: ,
    color: Colors.textColor,
    filter: `opacity(0.5) drop-shadow(0 0 0 ${iconColor})`
    // backgroundColor: Colors.primary
}

export const gravatarKeys = ['identicon', 'monsterid', 'wavatar', 'retro', 'robohash',  ] //'blank', '404', 'mp'

export const SideBarData = [
    {
        title: "Home",
        path: "/",
        icon: <IconButton style={sideBarIcon}>
                    <img style={sideBarIcon} src={homeIcon} alt="dashboard"/>
                </IconButton>,
        children: null,
    },
    {
        title: "Create Form",
        path: "/createform",
        icon: <IconButton ><img src={iconCreateForm} alt="cformdropdown"/></IconButton>,
        children: [
            {
                title: "Create a new Form",
                path: "/createform",
                icon: null,
            },
            {
                title: "Use Template",
                path: "/template",
                icon: null,
            }
        ]
    },
    {
        title: "Profile",
        path: "/profile",
        icon: <IconButton style={sideBarIcon} ><AiIcons.AiOutlineUser/></IconButton>, //
        children: null,
    },
    {
        title: "Feedback",
        path: "/feedback",
        icon: <IconButton style={sideBarIcon}><MdIcons.MdFeedback/></IconButton>,
        children: null,
    }
]

const constants = {
    answerTypes : {
        rank: 'rank',
        text: 'text',
        ratingStar: 'ratingStar',
        ratingSmiley: 'ratingSmiley',
        multiChoice: 'multiChoice',
    }, 
    formType: {
        review: "review",
        survey: "survey"
    },
    Routes: {
        Landing: '/landing',
        Pricing: '/pricing',
        Dashboard: '/dashboard',
        CreateNewForm: '/createform',
        Template: '/template',
        Feedback: '/feedback',
        Profile: '/profile',
        EditTemplate: '/edit-template',
        Login: '/login',
        Register: '/register',
        JoinListBeta: "/join-listin-beta"
    },
    Sub: {
        basic: 'basic',
        premium: 'premium'
    },
    ThemeOptionTypes: {
        lookupdata: "lookupdata",
        mSwitch: "switch",
        accordion: "accordion"
    },
    templateActions: {
        edit: {
            text: 'Edit',
            icon: <EditIcon/>
        },
        share: {
            text: 'Share',
            icon: <ShareIcon/>
        },
        // viewresults:{
        //     text: "View Results",
        //     icon: <VisibilityIcon/>
        // },
        delete: {
            text: "Delete",
            icon: <DeleteForeverIcon/>
        },
        closeactive: {
            text: (isActive) => isActive ? "Close" : "",
            icon: <StopIcon/>
        }
    },
    shareConstants : [
        // {
        //     image: '',
        //     text: 'Send Email'
        // },
        {
            image: sharelink,
            text: 'Create a url'
        },
        {
            image: shareqrcode,
            text: 'QR code'
        },
        // {
        //     image: '',
        //     text: 'Social'
        // }
    ]
}

export const defaultQuestions = {
    "question_0": {
        index: 0,
        text: "Rate your experience",
        answerType: constants.answerTypes.multiChoice,
        answers: [{
            name: "question_0_answer_0",
            value: "good"
        },{
            name: "question_0_answer_1",
            value: "bad"
        },{
            name: "question_0_answer_2",
            value: "average"
        }],
    },
    "question_1": {
        index: 1,
        text: "Where can we improve ?",
        answerType: constants.answerTypes.text,
        answers: [],
    }
}

export const DEFAULT_BRAND_VALUE="default_show_badge"

export const defaultThemeOptions = [
    {
        name: "AskEmail",
        label: "Ask for Email",
        displayType: constants.ThemeOptionTypes.mSwitch,
        value: false,
        info:"This enables the email text. when disbaled, replies are anonymous"
    },
    {
        name: "AskName", 
        label: "Ask for Name",
        displayType: constants.ThemeOptionTypes.mSwitch,
        value: false,
        info:"This enables the full name textfield"
    }, 
    {
        name: "IndicateMandatoryQ",
        displayType: constants.ThemeOptionTypes.mSwitch,
        label: "Make Questions Mandatory",
        value: false,
        info:""
    }, 
    // {
    //     name: "MakeAllCompulsory",
    //     type: constants.ThemeOptionTypes.mSwitch,
    //     label: "Make Every Question Compulsory",
    //     value: false,
    //     info:""
    // },
    {
        name: "ShowQuestion",
        displayType: constants.ThemeOptionTypes.mSwitch,
        label: "Show Question Number",
        value: false,
        info:""
    },
    // {
    //     name: "FontStyles",
    //     type: constants.ThemeOptionTypes.lookupdata,
    //     label: "Font Style",
    //     value: "serif",
    //     info:""
    // },
    // {
    //     name: "ButtonStyle",
    //     type: constants.ThemeOptionTypes.lookupdata,
    //     label: "Button Style",
    //     value: "outline",
    //     info:""
    // },
    {
        name: "Background",
        displayType: constants.ThemeOptionTypes.accordion,
        label: "Background Settings",
        value: "Image",
        url: "",
        info:""
    },
    {
        name: "ListInBrand",
        displayType: constants.ThemeOptionTypes.accordion,
        label: "ListIn Branding",
        value: DEFAULT_BRAND_VALUE,
        info:""
    }
]




export const  defaultImageOptions = [
    {

    }, 
    {

    },
    {

    }
]

export const defaultThemeLookUpData = [
   
]

export const defaultThemeAccordionOptions = [
   
]

export const TemplateCreatedMsg = "Template Created 🎉 !!"
export const TemplateDraftCreated = "Template saved as draft" 


export const TemplateUpdatedMsg = "Template Updated !!"

export default constants

