import {
    PROFILE_SAVE_SUCCESS,
    PROFILE_GET_SUCCESS,
    PROFILE_DELETE_SUCCESS,
    PROFILE_LOADING,
    PROFILE_FAILURE,
    INITIAL_PROFILE_CREATED
} from '../types';

//firebase
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

import { setAlert } from '../actions/alertActions'

import Profile from '../../models/Profile'

export const setInitialProfile = (profileData) => async dispatch => {
    dispatch({
        type: INITIAL_PROFILE_CREATED,
        payload : profileData
    })
}

export const getProfile =(user) => async dispatch => {
    
    try{
        dispatch({
            type: PROFILE_LOADING
        })

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH


        let mProfile
        if (auth.currentUser){

            const { uid, email } = auth.currentUser

            mProfile = await instance.getOne(fsCollection.PROFILE, { email: email})

            dispatch({
                type: PROFILE_GET_SUCCESS,
                payload: mProfile
            })

        }else{
            // const mUser = JSON.parse(localStorage.getItem("user"))

            mProfile = await instance.getOne(fsCollection.PROFILE, { email: user.email})
            
        }

    }catch(e){
        dispatch({
            type: PROFILE_FAILURE,
            payload: e
        })
    }
}

export const saveProfile = (form, file) => async dispatch => {

    try{
        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            dispatch({
                type: PROFILE_LOADING
            })
            
            const timestamp = instance.serverTimeStamp
    
            const filename = file?.selectedFile?.name ? file?.selectedFile?.name : null;
    
            const { name, email, companyName, phone, companySector, country, city, companySize, plan, id, url, dateCreated } = form
    
            let profileModel = new Profile(id, name, email, phone, companyName, companySector, companySize, city, country, dateCreated, timestamp, url, plan)
    
            //CANNOT DELETE PROFILE / USER - just clear fields
            // if(exists){
            if(file && file.selectedFile){
                let imgUrl = await instance.saveStorage('profile', file.selectedFile, auth.uid)

                //remove previously exitsing Image
                if (profileModel.url !== ''){
                    //delet from storage
                    await instance.deleteStorage({ url: profileModel.url})
                }
                profileModel.url = imgUrl
            }
            await instance.updateById(fsCollection.PROFILE, id, profileModel.toFirestore())
            
            dispatch({
                type: PROFILE_SAVE_SUCCESS, 
                payload: profileModel
            })

            dispatch(setAlert("Profile Updated!!", "info"))
            // }
        }
       
    }catch(e){
        dispatch({
            type: PROFILE_FAILURE, 
            payload: e
            //     profile: null, 
            //     loading: false, 
            //     error: e
            // }
        })
    }
    // setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
};