import React, { useCallback, useState, memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

//Icon
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        border: '1px dashed lightgray',
        height: '110px',
        width: 'inherit',
        padding: '15px',
        borderRadius: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '5px',
        '&:hover':{
            cursor: 'pointer'
        }
    }, 
    root2:{
        display: 'inline-block'
    },
    icondiv:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icons:{
        width: '30px',
        height: '30px'
    }
}))

const CreateNewThemeCard = ({ createNewTheme }) => {
    const classes = useStyles()
    return (
        <div className={classes.root} onClick={createNewTheme}>
            <div className={classes.root2}>
                <div className={classes.icondiv}><AddIcon className={classes.icons}/></div>
                <Typography variant="body2"> Create New Theme</Typography>
            </div>
        </div>
    )
}

CreateNewThemeCard.propTypes = {
    createNewTheme: PropTypes.func,
}

export default CreateNewThemeCard