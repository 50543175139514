import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import { Colors } from '../../../assets/js/styles'

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
    //   width: '100%',
    //   '& > * + *': {
    //     marginTop: theme.spacing(2),
    //   },
    },
    color: {
        background: Colors.background,
        color: 'black'
    },
    success:{
        background: Colors.primary
    },
    info: {
        background: 'gray'
    },
    failure: {
        background: 'lightpink'
    },
    indicator: {
        background: 'lightgreen',
        width: '100%',
        height: '10%',
    }
}));

const Toast = ({ open, type, message, color, position={ vertical: 'bottom', horizontal: 'center' } }) => {

    const classes = useStyles();
    const [mOpen, setMopen] = useState(open);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }   
        setMopen(false);
    };

    const getClass = (type) => {
       switch (type){
            case 'info':
                return classes.info
            case 'success' :
                return classes.success
            case 'failure' :
                return classes.failure
            default: return classes.info
       }
    }

    return (
        <div className={classes.root}>
            <Snackbar 
                open={mOpen} 
                // autoHideDuration={6000} 
                onClose={handleClose} 
                anchorOrigin={position}
                message={message}
                ContentProps={{
                    "aria-describedby": "message-id",
                    className: color ? classes.color : getClass(type)
                }}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                />
        </div>
    )
}

export default Toast