import React, { useCallback, useState, useRef, useEffect } from 'react'
import { Typography, IconButton, useMediaQuery, Avatar, Popper, Fade, Paper, ClickAwayListener, Badge } from '@material-ui/core'
import { profileStyles } from '../../../assets/js/styles' 
import { useHistory } from 'react-router-dom'

import { fsCollection, FirebaseServiceFactory } from '../../utils/FirebaseService'

// import { useHistory } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

//default Icons
import avatar from '../../../assets/img/avatar.png'
import notification from '../../../assets/img/notification.png'
import hamburger from '../../../assets/img/hamburger.png'

//Icons
// import CloseIcon from '@mui/icons-material/Close';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import Slide from '@material-ui/core/Slide';
import MenuContent from './MenuContent'

import { Colors } from '../../../assets/js/styles'

import { setMenuIndex, setSubMenuIndex } from "../../redux/actions/menuActions"
import MDialog from '../../components/MDialog'
import MButton from '../MButton'

import defaultPNG from '../../../assets/img/default.png'
import constants from '../../constants'

import NotificationContainer from '../NotificationContainer'
import { enableNotificationListener } from '../../redux/actions/notificationAction'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const Header = ({ title, avatarIcon, notificationIcon, showDate=false }) => {
    const classes = profileStyles()
    const isSMDown = useMediaQuery(theme => theme.breakpoints.down('sm'))

    const history = useHistory()
    const [open, setOpen] = React.useState(false)

    const subMenu = useSelector(state => state.menu.subMenu)
    const currentIndex = useSelector(state => state.menu.currentIndex)

    const profile = useSelector(state => state.profile.data)

    const notifications = useSelector(state => state.notification.data)

    const [mNotification, setMNotifications] = useState(notifications ? notifications : [])

    const showMenuOptions = () => {
        //show pop up
        setOpen(true)
    }

    const dispatch = useDispatch()

    const onClose = () => {
        setOpen(false)
    }

    const [anchorEl, setAnchorEl] = useState(null)

    const [showNofication, setShowNotification] = useState(false)

    const showNotificationContainer = useCallback((e) => {
        setAnchorEl(e.currentTarget)
        setShowNotification(true)
    }, [])

    const hideNotificationBox = () => {
        setAnchorEl(null)
        setShowNotification(false)
    }

    const navigateToCreateTemplate = useCallback(() => {

        dispatch(setSubMenuIndex(0, true))
        dispatch(setMenuIndex(null))
        history.push(constants.Routes.CreateNewForm)

    }, [history, dispatch])


    const removeAnchorEl = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const transformData = (instance, snapData, type) => {
        let returndata = []
        if (Object.values(fsCollection).includes(type)){
            const key = type.toLowerCase()

            for (let dat of snapData){
                returndata.push(instance.handlers[key].fromFirestore(dat))
            }
            // returnVal = !snapshot.empty ?  : null
        }
        return returndata
    }

    // const updateListen = useCallback(async () =>{
    //     // const instance = FirebaseServiceFactory.getInstance()

    //     // const type = fsCollection.NOTIFICATION

    //     // let unsub = null

    //     const query = null

    //     if (profile){

    //         const unn = onSnapshot(query, (snap) => {
    //             let snapData = []
            
    //             snap.forEach((doc) => {
    //                 snapData.push(doc)
    //             })
                
    //             // for (let change of snap.docChanges()){
    //             //   // snapData.push(change.doc)
    //             //   // if (change.type === "added"){
    //             //     // console.table(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>Change;", change)
    //             //     // console.log(">>>>>>>> :ID:", change.doc)
    //             //     // console.table("New Notification: ", change.doc.data());
    //             //   // }
    //             // }
                
    //             }, (error) => {

                
    //             }
    //         )
            
    //         setMNotifications(transformData(instance, data, type))

    //     }else{
    //         //fetch profile
    //         //fetch notifications

    //     }

    //     return unsub

    // }, [profile])

    useEffect(() =>{

        // const unsubscribe =  updateListen()

        return () => {

            // if (unsubscribe && typeof(unsubscribe === 'function')){
            //     console.log(unsubscribe)
            //     unsubscribe()
            // }
        }

    }, [])

    return isSMDown 
        ? (
            <ClickAwayListener onClickAway={hideNotificationBox}>
                <div className={classes.profileTopHeader} style={{ marginLeft: '0px', marginRight: '0px', marginTop: '20px', alignItems: 'center' }}>
                    {/* <Typography>test </Typography> */}
                    <IconButton className={classes.topRightIcons} onClick={showMenuOptions}>
                        <img src={hamburger} width='18px' height='18px' alt="notification avatar"/>
                    </IconButton>
                    <MDialog
                        fullScreen={true}
                        bgColor={Colors.background}
                        className={classes.mDialog}
                        open={open}
                        handleClose={onClose}
                        TransitionComponent={Transition}
                        content={
                            <>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', background: Colors.background }}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={onClose}
                                        aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                <MenuContent
                                    subMenu={subMenu}
                                    currentIndex={currentIndex}
                                    setMenuIndex={setMenuIndex}
                                    setSubMenuIndex={setSubMenuIndex}
                                />
                            </>
                        }
                    />
                    <Typography variant="h6"> <b>{title}</b></Typography>
                    <div>
                        <IconButton className={classes.topRightIcons} onClick={showNotificationContainer}>
                            {notificationIcon 
                                ? <img src={ notificationIcon} width='40px' height='40px' alt="notification avatar"/>
                                :   <Badge badgeContent={mNotification.length} color="primary">
                                        <NotificationsIcon style={{ width: '32px', height: '32px', color: Colors.textColor }}/>
                                    </Badge> 
                            }
                            {/* <img src={notificationIcon ? notificationIcon : notification} width='40px' height='40px' alt="notification avatar"/> */}
                        </IconButton>
                        
                        <NotificationContainer removeAnchorEl={removeAnchorEl} open={showNofication} anchorEl={anchorEl}/>

                        <IconButton className={classes.topRightIcons}>
                            <Avatar>
                                {( avatarIcon && avatarIcon !== '')
                                        ?  <img src={avatarIcon} width='40px' height='40px' alt="Avatar pic"/> 
                                        : (profile && profile.url !== '') 
                                            ?  <img src={profile.url} width='40px' height='40px' alt="Avatar pic"/> 
                                            : <img src={defaultPNG} width='40px' height='40px' alt="Avatar pic"/>
                                }
                            </Avatar>
                            {/* <img src={avatarIcon ? avatarIcon : (profile ? profile.url : '')} width='40px' height='40px' alt="A"/> */}
                        </IconButton>
                    </div>
                </div>
            </ClickAwayListener>
        ):(
            <ClickAwayListener onClickAway={hideNotificationBox}>

                <div className={classes.profileTopHeader}>
                    <div>
                        <Typography variant="h6"> <b>{title}</b></Typography>
                        {showDate && <Typography variant="caption">{new Date().toDateString()}</Typography>}
                    </div>
                    <div>
                        <MButton text={"Create Template"} buttonStyle="outline" onClick={() => navigateToCreateTemplate()}/>
                        <IconButton className={classes.topRightIcons} onClick={showNotificationContainer}>
                            {notificationIcon 
                                ? <img src={ notificationIcon} width='40px' height='40px' alt="notification avatar"/>
                                :   <Badge badgeContent={mNotification.length} color="primary">
                                        <NotificationsIcon style={{ width: '32px', height: '32px', color: Colors.textColor }}/>
                                    </Badge> 
                            }
                            {/* <img src={notificationIcon ? notificationIcon : notification} width='40px' height='40px' alt="notification avatar"/> */}
                        </IconButton>

                        <NotificationContainer removeAnchorEl={removeAnchorEl} open={showNofication} anchorEl={anchorEl}/>
                    
                        <IconButton className={classes.topRightIcons}>
                            <Avatar>
                                {
                                    (avatarIcon && avatarIcon !== '')
                                    ?  <img src={avatarIcon} width='40px' height='40px' alt="Avatar pic"/> 
                                    : (profile && profile.url !== '') 
                                        ?  <img src={profile.url} width='40px' height='40px' alt="Avatar pic"/> 
                                        : <img src={defaultPNG} width='40px' height='40px' alt="Avatar pic"/>
                                }
                            </Avatar>
                        </IconButton>
                    </div>
                </div>
            </ClickAwayListener>
        )
}

export default Header