import {
    ADD_THEME, 
    GET_THEMES, 
    GET_THEME, 
    EDIT_THEME, 
    THEME_ERROR, 
    THEME_LOADING,
    DELETE_THEME,
    SET_ACTIVE_THEME
} from '../types';

import { uuidv4 } from '../../utils/helpers'
import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'

import { setAlert } from '../actions/alertActions'

import MTheme from '../../models/MTheme'

const toggleLoading = (isLoading) => async dispatch => {
    dispatch({
        type: THEME_LOADING,
        payload: isLoading
    })
}

export const saveTheme = (themedata) => async dispatch => {
    
    try{

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            toggleLoading(true)

            const timestamp = instance.serverTimeStamp

            const { name, colors, options, owner } = themedata

            const optionWithImage = options.filter((item, index) => {
                if(item.name === "Background") {
                    return item
                }else{
                    return null
                }
            })

            let bgUrl, newOptions
            if (optionWithImage[0].url !== ''){
                bgUrl = await instance.saveStorage('theme', optionWithImage[0].url, owner)

                newOptions = options.map((item, index) => {
                    if(item.name === "Background") {
                        item.url = bgUrl
                    }
                    return item
                })
            }
            // console.table(newOptions)

            const theme = new MTheme("", owner , name, colors, newOptions ? newOptions : options, false, false, timestamp, timestamp) //get properties data from themedata passed to function

            const newTheme = await instance.saveOne(fsCollection.THEME, theme.toFirestore())

            toggleLoading(false)

            dispatch({
                type: ADD_THEME, 
                payload: newTheme
            })

            dispatch(setAlert("Theme Created", "info"))
            dispatch(setActiveTheme(newTheme))
        }else{
            throw new Error("Invalid User")
        }

    }catch(e){
        dispatch({
            type: THEME_ERROR, 
            payload: e
        })
    }
};


export const setActiveTheme = (theme) => async dispatch => {

    try{
        const instance = FirebaseServiceFactory.getInstance()
        const auth = instance.AUTH.currentUser
        if (auth) {
            if (theme){
                dispatch({
                    type: SET_ACTIVE_THEME, 
                    payload: theme
                })
            }
        }else{
            throw new Error("Invalid User")
        }
        
    }catch(e){
        dispatch({
            type: THEME_ERROR, 
            payload: e
        })
    }
}


export const deleteTheme = (id, themes, theme) => async dispatch => {
    
    try{
        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth ){
            const success = await instance.deleteOne(fsCollection.THEME, id)

            if (success) {
    
                dispatch({ 
                    type: DELETE_THEME, 
                    payload: {
                        themes: themes.filter(item => item.id !== id),
                        theme: theme.id === id ? null : theme
                    }
                })
            }
        }else{
            throw new Error("Invalid User")
        }

    }catch(e){
        dispatch({
            type: THEME_ERROR,
            payload: e
        })
    }
}


export const getUserThemes = (id, themeId=null) => async dispatch => {
    
    try{

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth ){

            toggleLoading(true)

            const instance = FirebaseServiceFactory.getInstance()

            //getAllThemes gets all thems created by user as well as themes marked as { owner: default }
            const themes = await instance.getAllThemes(fsCollection.THEME, { owner : id }) 

            toggleLoading(false)

            dispatch({
                type: GET_THEMES, 
                payload: {
                    themes,
                    theme: themeId ? themes.filter(item => item.id === themeId)[0] : null
                }
            })
        }else{
            throw new Error("Invalid User")
        }

    }catch(e){
        dispatch({
            type: THEME_ERROR, 
            payload: e
        })
    }
};