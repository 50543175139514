import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles(theme => ({
    list: {
    //   width: '600px',
      [theme.breakpoints.down('sm')]:{
          width: '100%'
      }
    },
    fullList: {
      width: 'auto',
    },
    cancelDiv: { 
        width: '100%', 
        display: 'flex', 
        justifyContent: 'flex-start' 
    },
    cancelIcon: {
        color: 'pink',
        cursor: 'pointer',
    },
  }));
  
const AdvancedOptions = ({ anchor, open, toggleOpen, child }) => {
    const classes = useStyles()
    return (
        <div
            // className={clsx(classes.list, {
            //     [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            // })}
            className={classes.list}
            role="presentation"
            >
                <Drawer 
                    // container
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    classes={{
                        paper: classes.list,
                    }}
                    anchor={anchor} 
                    // className={classes.list} 
                    open={open} 
                    onClose={toggleOpen}>
                        <div className={classes.cancelDiv}>
                            <IconButton onClick={toggleOpen} >
                                <CancelIcon className={classes.cancelIcon}  />
                            </IconButton>
                        </div>
                        {child}
                </Drawer>
        </div>
    )
}

export default AdvancedOptions
