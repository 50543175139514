import React, { useState, useCallback } from 'react'
import { AreaChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, ResponsiveContainer } from 'recharts';

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'

import LockIcon from '@material-ui/icons/Lock';

import { useMediaQuery } from '@material-ui/core/'
import { Colors } from '../../../../assets/js/styles';

const data = [
    {
      "name": "Jan",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Feb",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Mar",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Apr",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "May",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "June",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "July",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    },
    {
        "name": "Aug",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    },
    {
        "name": "Sept",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    },
    {
        "name": "Oct",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    },
    {
        "name": "Nov",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    },
    {
        "name": "Dec",
        "uv": 3490,
        "pv": 4300,
        "amt": 2100
    }
]    

const useStyles = makeStyles(theme => ({
    root: {
        margin: '30px 0',
        width: '100%',
        border: `1px solid ${Colors.background}`, 
        borderRadius: '20px', 
        padding: '15px',
        // display: 'grid',
        height: 'calc(inherit - 100px)',
    },
    secondRoot: {
        position: 'relative',
        width: '100%',
        height: 'calc(inherit)',
        padding: '15px',
    },
    div: {
        opacity: 0.8,
    }
}))

const filterOptions = [
    'Yearly','Monthly', 'Weekly'
]

const MonthlySatisfactory = ({ hidden=true }) => {

    const classes = useStyles()

    const [currentSel, setCurrentSel] = useState(filterOptions[0])

    const handleChange = useCallback((event) => {
        setCurrentSel(event.target.value);
    }, [])

    return hidden ? (
        <div className={classes.root}>
           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
               <LockIcon/>
           </div>
        </div>
    ) : (
        <div className={classes.root}>
                <div className={classes.div} style={{ 
                            // gridArea: '1 / 1',  
                            // // backgroundColor: 'gray',
                            // filter: 'blur(4px)',
                            // backdropFilter: 'blur(20px)',
                            // opacity: 0.7
                            zIndex: 9,
                            // backgroundColor: 'red',
                        }}>

                </div>
                {/* //style={{ gridArea: '1 / 1'}} */}
                <div className={classes.div}  > 
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: "center"}}>
                        <Typography> <b> {currentSel+ ' '}Satisfactory Level</b></Typography>
                        <div style={{ width: '25%'}}>
                            <FormControl variant="filled" fullWidth>
                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                <Select
                                // displayEmpty
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentSel}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={filterOptions[0]}>{ filterOptions[0] }</MenuItem>
                                    <MenuItem value={filterOptions[1]}>{ filterOptions[1] }</MenuItem>
                                    <MenuItem value={filterOptions[2]}>{ filterOptions[2] }</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <ResponsiveContainer width="99%" aspect={3}>
                        <AreaChart  data={data} //width={isxSDown ? 550 : 730} height={250}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                </linearGradient>
                                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                            <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
        </div>
    )
}

export default MonthlySatisfactory
