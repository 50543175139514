import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
  wrapper: {
    border: `${10}px solid lightgray`
  },
  greyed: {
    border: `${10}px solid rgba(0, 0, 0, 0.23)`
  }
}))

function Bordered(props) {
    const classes = styles()
    const {
        disableVerticalPadding,
        disableBorderRadius,
        children,
        variant
    } = props;
    return (
        <div
            className={variant === "greyed" ? classes.greyed : classes.wrapper}
            style={{
                paddingLeft: disableVerticalPadding ? 0 : '10px',
                paddingRight: disableVerticalPadding ? 0 : '10px',
                borderRadius: disableBorderRadius ? 0 : '10px'
            }}
        >
        {children}
        </div>
    );
}

Bordered.propTypes = {
  disableVerticalPadding: PropTypes.bool,
  disableBorderRadius: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array
  ]),
  variant: PropTypes.string
};

export default Bordered;
