import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel';

import { Colors, formFieldWidth } from '../../../assets/js/styles'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '10px',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        minHeight: '48px',
        padding: '0 8px',
        border: '1px solid rgb(214, 216, 218)',
        borderRadius: '6px',
        '&:focus-within': {
            border: `1px solid ${Colors.primary}`,
        },
        [theme.breakpoints.down('sm')]: {
            // width: 'calc(100% - 15x)'
            width: '100%'
        },
        [theme.breakpoints.up("lg")]:{
            width: formFieldWidth
        }
    },
    input: {
        flex: 1,
        border: 'none',
        height: '46px',
        fontSize: '14px',
        padding: '4px 0 0 0',
        '&:focus': {
            outline: 'transparent',
        }
    },
    ul: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0',
        margin: '8px 0 0 0',
    },
    tag: {
        width: 'auto',
        height: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        padding: '0 8px',
        fontSize: '14px',
        listStyle: 'none',
        borderRadius: '6px',
        margin: '0 8px 8px 0',
        background: Colors.primary,
    },
    tagTitle:{
        marginTop: '3px'
    },
    closeIcon: {
        display: 'block',
        width: '16px',
        height: '16px',
        lineHeight: '16px',
        textAlign: 'center',
        fontSize: '14px',
        marginLeft: '8px',
        color:  Colors.primary,
        borderRadius: '50%',
        background: '#fff',
        cursor: 'pointer',
    }
}))

const TagsInput = ({ mTags, selectedTags }) => {

    const classes = useStyles()

    const [tags, setTags] = useState(mTags ? mTags : []);

    const addTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setTags([...tags, event.target.value.trim()]);
            selectedTags([...tags, event.target.value.trim()])
            event.target.value = "";
        }
    };

    const removeTags = index => {
        setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
        selectedTags([...tags.filter(tag => tags.indexOf(tag) !== index)])
    };

    return (
        <div className={classes.root}>
            <ul className={classes.ul}>
                {tags.map((tag, index) => (
                    <li key={index} className={classes.tag}>
                        <span className={classes.tagTitle}>{tag}</span>
                        <CancelIcon className={classes.closeIcon} onClick={e => removeTags(index)}/>
                    </li>
                ))}
            </ul>
            <input
                className={classes.input}
                type="text"
                onKeyUp={event => addTags(event)}
                placeholder="Type and press enter to add tags"
            />
        </div>
    );
};
export default TagsInput;