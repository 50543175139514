import dashboard from '../../../assets/img/landing/dashboard.png'

const DashboardPage = () => {
  return (
    <div>
        <div id="how-it-works" className='bg-[#E5E5E5] p-10 px-[8%]'>
        <h1 className='text-4xl font-bold border-l-8 p-2 py-0 border-[#29C785] text-[#27292E]'>Listin Dashboard</h1>
        </div>

        <div className='flex justify-center bg-slate-50 items-center p-8'>
          <img src={ dashboard } alt="dashboard" className='rounded-lg' />
        </div>
    </div>
  )
}

export default DashboardPage