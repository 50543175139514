import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import constants from '../../constants'
import { loadUser } from '../../redux/actions/authAction'

//remove
const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    // console.log(event)
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

// Hook
function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.onload = function() {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
///////// remove******************remove ////

const AuthComponent = ({ isAuthenticated, children, loading, loadUser, log_Out }) => {

    const { push } = useHistory();   
    // const instance = useRef(FirebaseServiceFactory.getInstance())

    const mounted = useRef(false)

    const checkUser = useCallback( async () => {
      if (mounted.current === false){
        if (log_Out === false){
          const user = await loadUser()
          if ((user && (user.hasOwnProperty('isGuest') && user.isGuest === true)) || !user || user === null){
            push(constants.Routes.Landing)
            mounted.current = true
          }else{
            mounted.current = true
          }
        }

      }
        
    }, [loadUser, push, log_Out])

    useEffect(() => {

      checkUser()

    }, [checkUser]);

    if(log_Out){
      return <Redirect to={{ 
        pathname: constants.Routes.Landing
      }}/>
    }

    // console.table([log_Out, loading, isAuthenticated ])

    return (loading === false && isAuthenticated === false) ? (
       <div/>
    ) : (
       <>
         {children}            
       </>
    )
}

AuthComponent.propTypes = {
   isAuthenticated: PropTypes.bool.isRequired,
   loading: PropTypes.bool,
   loadUser: PropTypes.func,
   user: PropTypes.object,
   log_Out: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
    log_Out: state.auth.log_Out
})

export default connect(mapStateToProps, { loadUser })(AuthComponent);