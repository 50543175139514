import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Colors } from '../../../assets/js/styles'
import clsx from 'clsx';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width:'500px',
    [theme.breakpoints.down('sm')]:{
      width: 'inherit',
    }
  },
  paper:{
    marginTop: '20px'
  },
  tabs:{
    background: 'lightgray',
    borderRadius: '10px'
  },
  tab: {
    color: 'white',
    boxShadow: theme.shadows[1],

  },
  tabActive:{
    boxShadow: theme.shadows[4],
    background: Colors.background,
    borderRadius: '10px',
  }
}));

function MTab({ childData, handleChange, handleChangeIndex, value }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Container position="static" color="default" className={classes.paper}>
        <Tabs
          value={value}
          onChange={handleChange}
          // TabIndicatorProps={{
          //   color: ''
          // }}
          // indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabs}
          // aria-label="full width tabs example"
        >
          {childData.map((data, index) => (
             <Tab key={'chilData_label_'+index} className={clsx(classes.tab, {
                                [classes.tabActive]: index === value,
                              })} 
              label={data.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Container>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
         {childData.map((data, index) => (
            <TabPanel key={'tabpanel_'+index} value={value} index={index} dir={theme.direction}>
              {data.child}
            </TabPanel>
         ))}
      </SwipeableViews>
    </div>
  );
}

MTab.propTypes = {
  childData: PropTypes.array.isRequired,
};

export default MTab