
import React, { useState, useCallback, Suspense, lazy, useEffect, useRef } from 'react'
import { useHistory } from "react-router";
import PropTypes from 'prop-types'


// import { useDispatch } from 'react-redux'

//utils
import { uuidv4, isEmpty } from '../../utils/helpers'
import validateTemplateForm from '../../utils/validateTemplateForm'
import { Colors, genericSceneStyles } from '../../../assets/js/styles'

//redux
import { connect, useDispatch } from 'react-redux'
import { saveTemplate } from '../../redux/actions/templateAction'
import { getUserThemes, setActiveTheme, deleteTheme } from '../../redux/actions/themeActions'

//MUI
import { Typography, Checkbox, Grid, IconButton, Tooltip, Hidden } from '@material-ui/core' 
import { makeStyles } from '@material-ui/core/styles'

//constants
import constants, { TemplateCreatedMsg, defaultQuestions } from '../../constants/constants'

//Form and Theme Components
import CreateFormSuccessDialog from './components/CreateFormSuccessDialog';
import AdvancedOptions from './components/AdvancedOptions';
import QuestionArea from './components/QuestionArea'
import CustomizeTheme from './themeComponents/CustomizeTheme';
import SelectTheme from './themeComponents/SelectTheme';


//generic components
import Layout, { Header } from '../../components/Layout'
import MTextField from '../../components/MTextField'
import MButton from '../../components/MButton'
import MTab from '../../components/Tab';
import TagsInput from '../../components/TagsInput'
import Toast from '../../components/Toast'

//Icons
import * as IoIcons from "react-icons/io5"
import VisibilityIcon from '@material-ui/icons/Visibility';

import MTheme from '../../models/MTheme'
import PreviewForm from './components/PreviewForm';
import User from '../../models/User';
import Profile from '../../models/Profile';
import Template from '../../models/Template';
import GreetingTextField from '../../components/GreetingTextField';
import MDialog from '../../components/MDialog';
import useSwitchState from '../../hooks/useSwitchState';
import ShareContent from '../Template/components/ShareContent';

import AuthComponent from '../../components/AuthComponent'


const ButtonText = {
    share: "share",
    save: "save"
}

const QR_Code='QR code'
const Create_URL='Create a url'

const useStyles = makeStyles(theme => ({
    mDialog: {
        // width: '80v',
        // marginTop: 20,
        // marginBottom: 20,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            width: 'inherit'
        }
    }
}))

const CreateNewForm = ({ saveTemplate, getUserThemes,deleteTheme, setActiveTheme, themes, activeTheme, profile, template, loading, isAuthenticated, error, user, alert, templates }) => {
    const commonClasses = genericSceneStyles()

    const classes = useStyles()

    const history = useHistory()
    const [questions, setQuestions] = useState(defaultQuestions)
    const [successPopUp, setSuccessPopUp] = useState(true)
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

    const [form, setForm] = useState({
        name : '',
        type : constants.formType.review,
        tags: ["all"],
        link : '',
        questions : questions
    })

    const [formType, setFormType] = useState({
        review: true,
        survey: false
    });
 
    const [errors, setErrors] = useState(null)

    const selectedTags = (tags) => {
        setForm({
            ...form,
            tags: tags
        })
    }

    const [welcomeText, setWelcomeText] = useState({
        show: true,
        text: 'Hey, Welcome'
    })

    const [goodbyeText, setGoodByeText] = useState({
        show: true,
        text: 'Thank you for the feedback'
    })

    const [sharePopUp, { on: openSharePopUp, off: closeSharePopUp }] = useSwitchState(false)

    // const [sharePopUp, setShowSharePopUp] = useState(false)

    const [disableSaveAndShareButton, setDisableSaveAndShareButton] = useState(false)
    
    const onSubmit = async (e) => {
        e.preventDefault();
 
        const { name, type, link, tags, questions} = form

        const content = e.target.innerText.toLowerCase().trim() ?? e.target.textContent.toLowerCase().trim()

        let isDraft = content === ButtonText.save ? true : false
        
        const isActive = content === ButtonText.save 
                            ? false 
                            : content === ButtonText.share 
                                ? true 
                                : false
    
        const { isValid, templateForm } = validateTemplateForm({ name, type, questions })
        
        if (isValid === true) {
            const obj = { 
                link, 
                isDraft,
                tags,
                name: templateForm.name, 
                type: templateForm.type,
                questions: templateForm.questions,
                welcomeText: welcomeText,
                goodbyeText: goodbyeText,
            }

            const mTheme = activeTheme ? activeTheme : themes.filter(item => item.isDefault === true)[0]
    
            await saveTemplate(obj, profile, mTheme.id, user.uid, templates, isActive)

            if (isActive === true){
                openSharePopUp()
            }
            setDisableSaveAndShareButton(true)
        }else{

            //show errors on ui
            return
        }
    }

    const toggleAdvancedOptions = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
             return;
        }
        setShowAdvancedOptions(prevState => !prevState)
    }

    const toggleFormChecked = useCallback((e) => {

        const { survey, review } = constants.formType
        let obj = {}
        let formType = ''

        if (e.target.name === survey){
            obj[review] = false
            obj[survey] = e.target.checked
            formType = survey
        }else{
            obj[review] = e.target.checked
            obj[survey] = false
            formType = review
        }

        setForm({
            ...form, 
            type: formType
        })

        setFormType(obj)
    }, [setForm, form])

    const onChange =  useCallback(async (e, questionKey) => {
        
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setErrors({ ...errors,
          [e.target.name]: null
        })

    }, [errors, form, setForm])


    const onChangeQuestion = useCallback((e) => {

        // let highesIndex = 0
        // for (let val of Object.values(questions)){
        //     highesIndex = highesIndex > val.index ? highesIndex : val.index
        // }
        // index: highesIndex + 1

        setQuestions({
            ...questions,
            [e.target.name]: { 
                ...questions[e.target.name],
                text: e.target.value,
            }
        })

        setForm({
            ...form,
            questions: questions
        })

        setErrors({ ...errors,
            [e.target.name]: null
        })

    }, [setQuestions, setErrors, questions, errors, form, setForm])


    const onChangeAnswer = useCallback((e, questionKey) => {

        let newAnswers = []

        for (let ab of questions[questionKey].answers){
            if (ab.name === e.target.name){
                ab.value = e.target.value
            }
            newAnswers.push(ab)
        }

        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: newAnswers
            }
        })

        setForm({
            ...form,
            questions: questions
        })

        setErrors({ ...errors,
            [e.target.name]: null
        })

    }, [setQuestions, setErrors, errors, questions, form, setForm])


    const addQuestion = useCallback(() => {
        let key = uuidv4()

        let highesIndex = 0
        for (let val of Object.values(questions)){
            highesIndex = highesIndex > val.index ? highesIndex : val.index
        }

        questions[key] = {
            index: highesIndex + 1,
            text: "Type Question Here",
            answerType: constants.answerTypes.text,
            answers: []
        }

        setQuestions({
            ...questions,
        })

        
        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])
    // console.log(questions)

    const removeQuestion = useCallback((key) => {

        const indexToRemove = questions[key].index

        const indexArr = []
        for (let val of Object.values(questions)){
            indexArr.push(val.index)
        }
        const cleanIndexes = indexArr.map((val) => val === indexToRemove ? -1 : val).filter(index => index !== -1)

        //removes the key from question object
        let newQuestions = Object.fromEntries(Object.entries(questions).filter((val, i) => !val.includes(key)))
        
        for (let index of cleanIndexes.sort((a, b) => a - b)){
            for (let quest of Object.values(newQuestions).sort((a, b) => a - b)) {
                if(quest.index > indexToRemove){
                    if(quest.index === index){
                        quest.index = index - 1
                        break;
                    }
                }
            }
        }
        
        setQuestions({
            ...newQuestions
        })

        setForm({
            ...form,
            questions: newQuestions
        })
    }, [questions, setQuestions, form, setForm])


    const setAnswerTypeForQuestion = useCallback((answerType, questionKey) => {
        
        questions[questionKey] = {
            ...questions[questionKey],
            answerType: answerType
        }

        setQuestions({
            ...questions,
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])


    const getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    const addAnswer = useCallback((questionKey) => {

        let answersList = questions[questionKey].answers
        const answerIndex = questions[questionKey].answers.length
        const answerKey = uuidv4() //answerIndex === 0 ? `answer_${0}` : `answer_${answerIndex}` //uuid
        
        answersList.push({
            name: answerKey,
            value: ""
        })

        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: answersList
            }
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [questions, setQuestions, form, setForm])

    const removeAnswer = useCallback((questionKey, answerName) => {
        let answersList = questions[questionKey].answers
        setQuestions({
            ...questions,
            [questionKey]: { 
                ...questions[questionKey],
                answers: answersList.filter((item) => item.name !== answerName)
            }
        })

        setForm({
            ...form,
            questions: questions
        })
    }, [setQuestions, questions, form, setForm])

    const handleSuccessModalClose = () => {
        setSuccessPopUp(false)
    }

    const checkSuccessDialog = useCallback(() =>{
        if (alert && alert.length > 0){
            if (alert[0].msg === TemplateCreatedMsg){
                setSuccessPopUp(true)
            }
        }
    }, [alert])


    const closeDrawer = useCallback((close) => {
        setShowAdvancedOptions(false)
    }, [])

    const onShowDisplay = useCallback((e) => {

        if (e.target.name === 'welcome'){
            setWelcomeText(prev => ({
                show: !prev.show, 
                text: prev.text 
            }))
        }

        if (e.target.name === 'goodbye'){
            setGoodByeText(prev => ({
                show: !prev.show, 
                text: prev.text 
            }))
        }
    }, [])

    const onChangeGreetingText = useCallback((e) => {
        if (e.target.name === 'welcome'){
            setWelcomeText(prev => ({
                    show: prev.show, 
                    text: [e.target.value] 
                })
            )
        }

        if (e.target.name === 'goodbye'){
            setGoodByeText(prev => ({
                    show: prev.show, 
                    text: [e.target.value] 
                })
            )
        }
    }, [])


    const getThemes = useCallback(async() => {
        if(user){
            getUserThemes(user.uid, { })
        }
    }, [user, getUserThemes])

    const [showPreview, setShowPreview] = useState(false)

    const previewResponderForm = () => {
        setShowPreview(true)
    } 

    const closeResponderForm = () => {
        setShowPreview(false)
    }
    
    useEffect(()  => {

        checkSuccessDialog()
        
        getThemes()
        
    }, [checkSuccessDialog, getThemes])

    //MTAB - to handle index change
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
  
    const handleTabChangeIndex = (index) => {
        setTabValue(index);
    };

    const [urlForPreview, setUrlForPreview] = useState('')

    const notifyBgImage = useCallback((url) => {
       setUrlForPreview(url)
    }, [])

    const createNewTheme = useCallback(() => {
        setTabValue(0);
        setActiveTheme(null)
        notifyBgImage(null)
    }, [setActiveTheme, notifyBgImage])

    const editTheme = useCallback((them) => {
        setTabValue(0);
        setActiveTheme(them)

        //TODO:- get themes bg img if exist, else leave null
        notifyBgImage(null) 

    }, [setActiveTheme, notifyBgImage])

    const [showQR, setShowQR] = useState(false)
    const [showLink, setShowLink] = useState(false)

    const triggerShowLink = useCallback((id) => {
        if (template.link.trim() === "" && template.isActive === false){
            setShowLink(true)
            setShowQR(false)
        }else{
            setShowLink(true)
            setShowQR(false)
        }
    }, [template ])


    const triggerShowQR = useCallback((id) => {
        if (template.link.trim() === "" && template.isActive === false){
            setShowQR(true)
            setShowLink(false)
        }else{
            setShowQR(true)
            setShowLink(false)
        }
       
    }, [ template ])


    const handleShare = useCallback((e, textContent) => {
        switch(textContent) {
            case Create_URL: 
                triggerShowLink()
                break;
            case QR_Code: 
                triggerShowQR()
                break;
            default:
                triggerShowLink();
                break;
        }
    }, [triggerShowLink, triggerShowQR])
    

    // console.log(urlForPreview)

    const childData = useRef([ 
        { 
            label: "Customize", 
            child: <CustomizeTheme onSetBgForActiveTheme={notifyBgImage} onCancel={closeDrawer} /> //pass theme for edit here
        },
        {
            label: "Themes", 
            child: <SelectTheme 
                        deleteTheme={deleteTheme}
                        createNewTheme={createNewTheme} 
                        onSetBgForActiveTheme={notifyBgImage}
                        onCancel={closeDrawer} 
                        setActiveTheme={setActiveTheme} 
                        editTheme={editTheme}/> 
        }
    ])
    /// MTAB

    return (
        <AuthComponent>
        <Layout>
            {/* { successPopUp && <CreateFormSuccessDialog 
                    template={template} 
                    open={successPopUp} 
                    message={"Success"} 
                    companyname={profile.companyName}
                    handleClose={handleSuccessModalClose}/> } */}
            <div className={commonClasses.root} style={{ color: Colors.lightDarkText }}>
                <Header title="Create Form" avatarIcon=""/>
                <div className={commonClasses.outerSection}>
                    <div className={commonClasses.innerSection}>
                        {   ( 
                                (alert && alert.length > 0) && alert[0].msg.startsWith("Template")
                            ) 
                        && 
                            <Toast open={(alert && alert.length > 0)} type='success' message={alert[0].msg}/> 
                        }

                        <MDialog 
                            bgColor={Colors.background}
                            // className={classes.mDialog} 
                            open={sharePopUp} 
                            handleClose={closeSharePopUp}
                            content={
                                <div className={classes.mDialog}>

                                    {/* <Typography style={{ width: 'inherit'}}>
                                        {template.link}
                                    </Typography> */}
                                    <ShareContent 
                                        source={"createForm"}
                                        title={"Pick Share Method"}
                                        template={template} 
                                        handleShare={handleShare} 
                                        showQR={showQR} 
                                        setShowQR={setShowQR}
                                        setShowLink={setShowLink}
                                        showLink={showLink}/>
                                </div>
                            } 
                        />
                        <div className={commonClasses.header}>
                            <Typography variant="h6"> <b>Create a new Form</b></Typography>
                        </div>

                        
                        <form className={commonClasses.form} >
                            <MTextField name="name" value={form.name} label="Name" errors = {errors} onChange={onChange}/>
                            <div>
                                <Typography variant="subtitle2" style={{margin: '0px 10px'}}>Form Type</Typography>
                                <Grid container>
                                    <Grid item xs>
                                        <div>
                                            <Checkbox name={constants.formType.survey} checked={formType.survey} color="primary" onChange={toggleFormChecked}/>
                                            <Typography style={{color: 'gray'}} variant="caption"> Survey </Typography>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div>
                                            <Checkbox name={constants.formType.review} checked={formType.review} color="primary" onChange={toggleFormChecked}/>
                                            <Typography style={{color: 'gray'}} variant="caption"> Review </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <TagsInput selectedTags={selectedTags}/>
                            <MTextField name="link" value={form.link} label="Link" errors = {errors} disabled={true} onChange={onChange}/>

                            <div className={commonClasses.optsPrev}>

                                <Typography style={{color: 'gray', cursor: 'pointer' }} variant="caption" onClick={toggleAdvancedOptions}> {"show theme options"} </Typography>
                                {/* <Typography style={{color: 'gray', cursor: 'pointer'}} variant="caption" > {"show template options"} </Typography> */}

                                {showAdvancedOptions && <AdvancedOptions 
                                    open={showAdvancedOptions} 
                                    anchor="right" toggleOpen={toggleAdvancedOptions} 
                                    child={
                                        <MTab value={tabValue} childData={childData.current} handleChange={handleTabChange} handleChangeIndex ={handleTabChangeIndex}/>
                                    }
                                />}

                                <Tooltip title="Preview responder form"> 
                                    <VisibilityIcon onClick={previewResponderForm}/>
                                </Tooltip>

                               {showPreview === true && <PreviewForm 
                                    bgImage={urlForPreview}
                                    open={showPreview} 
                                    handleClose={closeResponderForm} 
                                    questions={questions}  
                                    theme={activeTheme}
                                    goodbyeText={goodbyeText}
                                    welcomeText={welcomeText}/>}
                            </div>
                            {
                                <GreetingTextField
                                    tooltipText={"Show Welcome Message"}
                                    value={ welcomeText && welcomeText.text}
                                    name={"welcome"}
                                    onSetShow={onShowDisplay}
                                    onChange={onChangeGreetingText}
                                    show={welcomeText && welcomeText.show}
                                />
                            }

                            {questions && Object.values(questions).map((question, index) => (
                                <QuestionArea 
                                     key={'key_' + index}
                                     name={getKeyByValue(questions, question)}
                                     value={question.text} 
                                     onChange={onChangeQuestion} 
                                     questionKey={getKeyByValue(questions, question)}
                                     answers={question.answers}
                                     setAnswerTypeForQuestion={setAnswerTypeForQuestion}
                                     addAnswer={addAnswer}
                                     answerType={question.answerType}
                                     onChangeAnswer={onChangeAnswer}
                                     removeAnswer={removeAnswer}
                                     onRemove={() => removeQuestion(getKeyByValue(questions, question))} />
                            ))}

                            {
                                <GreetingTextField
                                    tooltipText={"Show Thank you Message"}
                                    value={goodbyeText && goodbyeText.text}
                                    name={"goodbye"}
                                    onSetShow={onShowDisplay}
                                    onChange={onChangeGreetingText}
                                    show={goodbyeText && goodbyeText.show}
                                />
                            }

                            <div style={{ marginLeft: '10px', display: "inline-flex", alignItems: "center" }}> 
                                <IconButton color="primary" onClick={() => addQuestion()}>
                                    <IoIcons.IoAddCircleSharp/>
                                </IconButton>
                                <Typography>Add a new question </Typography>
                            </div>

                            

                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '40px 0px' }}>
                                <Hidden smDown={true}>
                                    <div>
                                        <Typography style={{color: 'gray'}}> Powered by ListIn </Typography>
                                    </div>
                                </Hidden>
                                
                                <div >
                                    <MButton marginY={'8px'} text="Cancel" type="button" width="130px" marginX="10px" buttonStyle="outline"/>
                                    <MButton marginY={'8px'} disable={disableSaveAndShareButton} text="Share" type="button" onClick={e => onSubmit(e)} name= "share" width="130px" marginX="10px" />
                                    <MButton marginY={'8px'} disable={disableSaveAndShareButton} text="Save" type="button" onClick={e => onSubmit(e)} name="save" width="130px" marginX="10px"/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
        </AuthComponent>
    )
}

CreateNewForm.propTypes = {
    saveTemplate: PropTypes.func.isRequired,
    getUserThemes: PropTypes.func.isRequired,
    setActiveTheme: PropTypes.func.isRequired,
    deleteTheme: PropTypes.func.isRequired,

    isAuthenticated:  PropTypes.bool,
    themes: PropTypes.arrayOf(PropTypes.instanceOf(MTheme)),
    activeTheme: PropTypes.instanceOf(MTheme),
    template: PropTypes.object,
    templates: PropTypes.arrayOf(PropTypes.instanceOf(Template)),
    error: PropTypes.object,
    loading: PropTypes.bool,
    alert: PropTypes.array,
    user: PropTypes.object,
    profile: PropTypes.instanceOf(Profile),
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    themes: state.theme.themes,
    activeTheme: state.theme.theme,
    template: state.template.template,
    templates: state.template.templates,
    error: state.profile.error,
    loading: state.profile.loading,
    alert: state.alert,
    user: state.auth.user,
    profile: state.profile.data,
});

export default connect(mapStateToProps, { saveTemplate, getUserThemes, setActiveTheme, deleteTheme })(CreateNewForm)