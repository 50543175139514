import {
    SAVE_NOTIFICATION,
    UPDATE_NOTIFICATION_SEEN,
    GET_NOTIFICATION,
    UPDATE_NOTIFICATION_SEEN_ERROR,
    GET_NOTIFICATION_ERROR,
    SAVE_NOTIFICATION_ERROR,
    NOTIFICATION_LOADING
} from '../types';

import Notification from '../../models/Notification'

import { uuidv4 } from '../../utils/helpers'

import { FirebaseServiceFactory, fsCollection } from '../../utils/FirebaseService'
import { toggleLoading } from '../reduxUtils';



export const saveNotification = (response, template, profileId) => async (dispatch, getState) => {
    // const id = uuidv4()

    try {

        toggleLoading(true, NOTIFICATION_LOADING)

        const instance = FirebaseServiceFactory.getInstance()

        const timestamp = instance.serverTimeStamp

        let notification; 

        const dataToSave = new Notification({

            responseId: response.id, 
            seen: false,

            bitmoji: response.bitmoji,
            email: response.email,

            templateId: template.id,
            content: `New response for template - ${template.name}`, 

            profileId : profileId,
            dateCreated: timestamp
        })

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            notification = await instance.saveOne(fsCollection.NOTIFICATION, dataToSave.toFirestore())

            if (notification){
                dispatch({
                    type: SAVE_NOTIFICATION,
                    payload: { notification }
                });
            }else{
                throw new Error("Notification failed to saved", Object.entries(notification) )
            }
        }

        // setTimeout(() => dispatch({ type: REMOVE_NOTIFICATION, payload: id }), timeout); //default 1-hour
    }catch(e){
        console.log(e)
        dispatch({
            type: SAVE_NOTIFICATION_ERROR,
            payload: e
        });
    }
}

export const pushNotificationToGlobalState = (notifications) =>  async dispatch => {
    try{
        dispatch({
            type: GET_NOTIFICATION,
            payload: [ null, notifications]
        });
    }catch(e){
        console.log(e)
        dispatch({
            type: GET_NOTIFICATION_ERROR,
            payload: e
        });
    }
}


export const enableNotificationListener = () => async (dispatch, getState) => {
    try{

        toggleLoading(true, NOTIFICATION_LOADING)

        const profile = getState().profile.data;

        const instance = FirebaseServiceFactory.getInstance()

        const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        if (auth){
            let [ unsubscribe, data ] = await instance.getNotification(fsCollection.NOTIFICATION, { profileId: profile.id })

            dispatch({
                type: GET_NOTIFICATION,
                payload: [ unsubscribe, data ]
            });
        }

    }catch(e){
        console.log(e)
        dispatch({
            type: GET_NOTIFICATION_ERROR,
            payload: e
        });
    }
}


export const removeNotification = (id) => async (dispatch, getState) => {
    try{

        toggleLoading(true, NOTIFICATION_LOADING)

        const notifications = getState().notification.data

        let notificationToUpdate = notifications.find((notif) => notif.id === id)

        const instance = FirebaseServiceFactory.getInstance()

        // const auth = instance.AUTH.currentUser ?? await instance.getCurrentUser()

        let updateSuccess = await instance.updateById(fsCollection.NOTIFICATION, notificationToUpdate.id, { seen: true })

        if (updateSuccess){
            notificationToUpdate.seen = updateSuccess

            const returnNotifications = notifications.filter((notif) => notif.id !== id)

            dispatch({
                type: UPDATE_NOTIFICATION_SEEN,
                payload: [...returnNotifications] //[...returnNotifications, notificationToUpdate ]
            });
        }

    }catch(e){
        console.log(e)
        dispatch({
            type: UPDATE_NOTIFICATION_SEEN_ERROR,
            payload: e
        });
    }
}