import React, { useState, useCallback } from 'react'


import MTextField from '../../../components/MTextField'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { deepOrange, deepPurple } from '@material-ui/core/colors';

// import { Redirect } from 'react-router-dom'

import MColorPicker from '../../../components/MColorPicker'
import { Switch, Typography } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { saveTheme } from '../../../redux/actions/themeActions'

import ThemeOptions from './ThemeOptions'
import MButton from '../../../components/MButton'
import Toast from '../../../components/Toast'

import constants, { defaultThemeOptions } from '../../../constants/constants'
import PropTypes from 'prop-types'
import MTheme from '../../../models/MTheme'


const useStyles = makeStyles(theme => ({
    root:{
       
    },
    pillContainer: {
        display: 'flex', 
        justifyContent: 'space-around',
        width: '100%'
    },
    pills:{

    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    optionText: {
        
    },
    buttonArea:{
        marginTop: '30px'
    }
}))



const maxLengthThemeName = 50

const CustomizeTheme = ({ onCancel, onSetBgForActiveTheme }) => {

    // console.log(theme)

    const defaultTheme = useSelector(state => state.theme.themes).filter(item => item.isDefault === true)[0]  
    const activeTheme = useSelector(state => state.theme.theme)  

    const alert = useSelector(state => state.alert)

    const theme  = activeTheme ? activeTheme : defaultTheme
    const [themeName, setThemeName] = useState( (theme && theme.name) ? theme.name : '' )
    const [themeNameCount, setThemeNameCount] = useState( (theme && theme.name) ? theme.name.length : 0 )
    const [themeError, setThemeError] = useState({

    })

    const dispatch = useDispatch()

    const userId = useSelector(state => state.auth.user.uid)

    const [themeColors, setThemeColors] = useState((theme && theme.colors) ? theme.colors : 
        {
            Questions: ' #FF0000',
            Answers: ' #0000FF',
            Background: ' #FFFFFF',
            Buttons: ' #000000',
        }
    )

    const { mSwitch, lookupdata, accordion } = constants.ThemeOptionTypes

    const setUp = useCallback(() => {
        let returnVal = defaultThemeOptions
        if (theme && theme.options.length > 0){
            for (let opts of theme.options){
                for (let def of returnVal) {
                    if (opts.name === def.name){
                        def.value = opts.value
                        if (opts.url){
                            def.url = opts.url
                            //getting bg url from firestore
                            onSetBgForActiveTheme(opts.url)
                        }
                        break;
                    }
                }
            }
        }
        return returnVal
    }, [theme, onSetBgForActiveTheme])

    const [options, setOptions] = useState((theme && theme.options) ? setUp() : defaultThemeOptions)

    const classes = useStyles()

    const onColorChange = useCallback((color, label) => {
        setThemeColors({
            ...themeColors,
            [label]: color
        })
    }, [themeColors, setThemeColors])

    const notifySwitchChange = useCallback((opts) => {

        let newOptions = options
        
        for (let val of newOptions ){
            if (val.name === opts.name){
                val.value = opts.value
            }
        }
        setOptions(newOptions)

    }, [options])

    const onThemeNameChange = useCallback((e) => {
        e.preventDefault();

        const val = e.target.value

        setThemeName(val)

        if (val.length === 0 || val === ""){
            setThemeNameCount(0)
        }else if (val.length === 1){
            setThemeNameCount(1)
        }else{
            setThemeNameCount(val.length)
        }

        setThemeError(null)

    }, [setThemeName, setThemeNameCount, setThemeError])

    const onDeleteThemeName = (e) => {
        e.preventDefault()
        const key = e.keyCode || e.charCode;

        if (key === 8 || key === 46){
            setThemeNameCount(themeName.length - 1)
        }
    }

    const handleRadioChange = useCallback((e) => {
        setOptions(options.map((item, index) => {
            if (item.name === e.target.name){
                item.value = e.target.value
                return item
            }
            return item
        }))
    }, [options])

    const notifyFileChange = useCallback((target) => {
        setOptions(options.map((item, index) => {

            if (target){
                if (item.value === target.name){
                    if (target.files[0]){
                        item.url = target.files[0]
                        onSetBgForActiveTheme(URL.createObjectURL(target.files[0]))
                    }
                }
            }else{
                item.url = ''
            }
            return item
        }))
    },[options, onSetBgForActiveTheme])

    // console.table(options)

    const onSave = useCallback((e)=> {

        const themeObj = {
            name: themeName,
            colors: themeColors,
            options,
            owner: userId,
        }

        dispatch(saveTheme(themeObj))

    }, [dispatch, userId, themeName, themeColors, options]);

    return (
        <div>
             {
                (
                    (alert && alert.length > 0) && alert[0].msg.startsWith("Theme") 
                ) 
                && 
                    <Toast open={(alert && alert.length > 0)} color="white" type='success' message={alert[0].msg}/> 
            }
            <MTextField 
                name={"formName"} 
                value={themeName} 
                fullwidth={true} 
                maxLength={maxLengthThemeName} 
                label={themeNameCount + '/' + maxLengthThemeName} 
                errors={themeError} 
                onChange={(e) => onThemeNameChange(e)}
                // onKeyDown={(e) => onDeleteThemeName(e)}
            />
            <div className={classes.pillContainer}>
                <MColorPicker label="Questions" colorHex={themeColors.Questions} onChange={onColorChange}/>
                <MColorPicker label="Answers" colorHex={themeColors.Answers} onChange={onColorChange}/>
                <MColorPicker label="Background" colorHex={themeColors.Background} onChange={onColorChange}/>
                <MColorPicker label="Buttons" colorHex={themeColors.Buttons} onChange={onColorChange}/>
            </div>
            <Divider/>
            <Divider/>
            
            {options && options.filter((item) => item.displayType === mSwitch || item.displayType === accordion).map((item, index) => (
                <div key={'options_'+ item.name + '_' + index}>
                    <ThemeOptions option={item}
                        notifyFileChange={notifyFileChange} 
                        notifySwitchChange={notifySwitchChange}
                        handleRadioChange={handleRadioChange} />
                    <Divider/>
                    <Divider/>
                </div>
            ))}

            <div className={classes.buttonArea}>
                <MButton text="Save & Apply" type="button" onClick={e => onSave(e)} name= "saveandapply" width="130px" marginX="10px" />
                <MButton text="Cancel" type="button" onClick={e => onCancel(e)} name= "cancel" width="100px" marginX="10px"  buttonStyle="outline"/>
            </div>
        </div>
    )
}

CustomizeTheme.propTypes = {
    onCancel: PropTypes.func.isRequired,
    mThemeColors: PropTypes.object,
    theme: PropTypes.instanceOf(MTheme)
}

export default CustomizeTheme