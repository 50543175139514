const Footer = () => {
    return (
     <section  className='footer flex justify-center items-center bg-[#29C785] pt-4 pb-4 text-white'>
         <div className='footer'>
             <a href="#">Listin</a>
             <span>&copy; 2022 a product of Findit LLC &trade;</span>
         </div>
     </section>
    )
}
  
  export default Footer